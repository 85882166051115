import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import Casino, { context } from '@dialinvest/react-casino';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { toggleModalIn } from '../../../redux/actions/signInModalActions';
import { setUserLoggedIn, setUserData } from '../../../redux/actions/userActions';
import MandatoryField from '../../Layout/Fields/MandatoryField';
import store from '../../../containers/App/store';
import {
  amlCheck,
  clipHTMLBody,
  createCookie,
  getPlayerInfo, handleBrowserCredentials,
  isBodyClipped,
  unClipHTMLBody,
} from '../../Helpers';
import PasswordField from '../../Layout/Fields/PasswordField';
import FooterMessage from '../FooterMessage';
import { REGISTERED_COOKIE_NAME } from '../../../utils/constants';
import { soliticsLoginSuccess } from '../../../containers/Solitics';
import { surveyConfig } from '../../Page/SourceOfWealth/surveyConfig';

export class Signin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorMessage: '',
      loading: false,
      lastResponseStatus: null,
    };
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);
  };

  componentDidUpdate(prevProps) {
    const { props, state } = this;

    if (props.errorStatus !== prevProps.errorStatus) {
      this.setState({
        ...state,
        lastResponseStatus: props.errorStatus,
      });
    }

    if (state.lastResponseStatus === 'FAIL_VERIFY') {
      this.renderResendPasswordConfirmation();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    handleBrowserCredentials(this.state);
    this.checkLoginValues();
  };

  toggleModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { props, state } = this;
    this.setState({
      ...state,
      errorMessage: '',
      lastResponseStatus: null,
    });

    if (props.location.pathname.includes('/game/')) {
      this.redirectOnGamePage();
    }

    props.onToggleModalIn();
    if (props.modalType !== 'TIME_OUT') clipHTMLBody();
  };

  closeModal = (e) => {
    e.preventDefault();
    const { props, state } = this;
    this.setState({
      ...state,
      errorMessage: '',
      lastResponseStatus: null,
    });

    if (props.location.pathname.includes('/game/')) {
      this.redirectOnGamePage();
    } else if (props.modalType === 'no_permission') {
      props.history.push('/');
    }

    props.onToggleModalIn();
    if (props.modalType !== 'TIME_OUT') clipHTMLBody();

    setTimeout(() => {
      unClipHTMLBody();
    }, 500);
  };

  callJoin = (e) => {
    e.preventDefault();
    const { props } = this;
    props.onToggleModalIn();
    props.onToggleModal();
  };

  escFunction = (event) => {
    const { props } = this;
    if (event.keyCode === 27 && props.isOpen) {
      this.closeModal(event);
    }
  };

  redirectOnGamePage = () => {
    const { props } = this;

    if (!props.location.pathname.includes('/game/') || !props.location.search.includes('?mode=real')) return;

    if (props.location.state?.supportsDemoPlay) {
      props.history.push(props.location.pathname);
    } else {
      props.history.goBack();
    }
  };

  onEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.checkLoginValues();
    }
  };

  isStatusFailQuickOpen = response => response.data?.status === 'FAIL_QUICK_OPEN_STATUS';
  isStatusQuickReg = response => response.data?.registrationStatus === 'QUICK_REG';

  checkSignupFail = (result) => {
    const { props, state } = this;
    let signupState;

    if (this.isStatusFailQuickOpen(result)) signupState = 2;
    if (this.isStatusQuickReg(result)) signupState = 3;

    if (!signupState) return false;

    if (signupState) {
      props.onToggleModalIn();
      props.onToggleModal(signupState, result.data.sessionKey, state.username, state.password);
      this.setState({
        loading: false,
        username: '',
        password: '',
      });

      return true;
    }
  }

  showError(result) {
    const { props } = this;
    if (result.message() === null) {
      this.setState({
        errorMessage: `${result.errors()[0].error} : ${result.errors()[0].field}`,
        lastResponseStatus: result.data.status,
      });
    } else if (this.isStatusFailQuickOpen(result)) {
      this.setState({
        errorMessage: props.t('errors:FAIL_QUICK_OPEN_STATUS'),
        lastResponseStatus: result.data.status,
      });
    } else {
      this.setState({
        errorMessage: result.data.message,
        lastResponseStatus: result.data.status,
      });
    }
  }

  async checkLoginValues() {
    const { props, state } = this;
    const { storeUserData, updateUser, cookies } = props;

    this.setState({ loading: true });

    const result = await new Casino.models.Signin(state.username, state.password).perform();
    const { termsPolicy, email } = result.data;

    if (this.checkSignupFail(result)) return this.setState({ loading: false });

    if (result.success()) {
      soliticsLoginSuccess(result.data.partyId, email);
      createCookie(REGISTERED_COOKIE_NAME, true, 525600);
      props.cookies.remove('signupState');

      const cookieDetails = cookies.get(process.env.REACT_APP_API_COOKIE_NAME);
      const playerInfo = await getPlayerInfo();

      const sourceOfWealth = playerInfo
        ?.data?.extraInfo?.find(info => info.key === surveyConfig.KEY);

      const sessionKey = playerInfo?.data?.sessionKey;
      const username = playerInfo?.data?.userId;
      const partyId = playerInfo?.data?.partyId;

      const userObj = {
        oldUserId: cookieDetails?.oldUserId,
        currency: cookieDetails?.currency,
        registrationStatus: cookieDetails?.registrationStatus,
        language: cookieDetails?.language,
        email: playerInfo?.data?.email,
        sourceOfWealth: sourceOfWealth?.value,
        sessionKey,
        ...termsPolicy,
        username,
        partyId,
        kycStatus: playerInfo?.data?.kycStatus,
      };

      updateUser(userObj);
      storeUserData(userObj);

      await amlCheck(playerInfo.data);
      props.onToggleModalIn();

      if (isBodyClipped()) clipHTMLBody();
      if (props.location?.state?.from === 'cashier_deposit') {
        const action = {
          type: 'TOGGLE_MODAL_CASHIER',
        };
        action.cashierType = 'deposit';
        action.providerType = props.providerType;
        store.dispatch(action);
        clipHTMLBody();
      } else {
        props.history.push(`${props.location.pathname}${props.location.search}`);
      }
      this.setState({ username: '', password: '' });
      props.setUserLoggedInAs(true);
    } else {
      props.setUserLoggedInAs(false);
      this.showError(result);
    }
    return this.setState({ loading: false });
  }

  handleUserNameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  renderPasswordResetRequest = (e) => {
    const { props } = this;
    this.toggleModal(e);
    props.toggleModalResetRequest();
    clipHTMLBody();
  };

  renderResendPasswordConfirmation = (e) => {
    const { username, password } = this.state;
    const { props } = this;
    this.toggleModal(e);
    props.onToggleModal(7, null, username, password);
    clipHTMLBody();
  };

  render() {
    const { props, state } = this;
    const { isOpen, t, cookies } = props;
    const { errorMessage } = state;
    const focusUsernameInputField = (input) => {
      if (input) {
        if (isOpen && state.username === '' && state.password === '') {
          setTimeout(() => { input.focus(); }, 100);
        }
      }
    };

    let message = '';

    if (
      typeof errorMessage !== 'undefined'
      && errorMessage !== ''
      && errorMessage !== null
    ) {
      message = (
        <div className="notification is-danger content">
          {errorMessage}
        </div>
      );
    } else if (
      typeof props.errorMessage !== 'undefined'
      && props.errorMessage !== ''
      && props.errorMessage !== null
    ) {
      message = (
        <div className="notification is-danger content">
          {props.errorMessage}
        </div>
      );
    }

    let modalHeader;
    let legend;

    if (props.modalType === 'TIME_OUT') {
      modalHeader = <h2 className="is-size-3 is-size-5-mobile has-text-dark has-text-centered">{t('account:session_expired')}</h2>;
      legend = <legend className="m-legend is-size-4 is-size-5-mobile">{t('account:sign_in_to_continue')}</legend>;
    } else {
      modalHeader = (
        <h2 className="is-size-3 is-size-5-mobile has-text-white">
          {t('account:sign_in_to_play')}
          {' '}
          <small className="is-size-4 is-size-6-mobile">
            {t('account:or')}
          </small>
          {' '}
          <a href="/#" id="join_open" onClick={e => this.callJoin(e)}>{t('account:join_us')}</a>
        </h2>
      );
      legend = <legend className="m-legend is-size-4 is-size-5-mobile">{t('account:enter_your_sign_in_details')}</legend>;
    }

    return (
      <form
        action="#"
        onSubmit={this.handleSubmit}
      >
        <div id="modal-signin" className={`modal m-modal-base modal-fx-superScaled m-modal-fixed ${isOpen ? 'is-active' : 'is-inactive'}`}>
          <div className="modal-background" />
          <div className="modal-card modal-content">
            <header className="modal-card-head">
              <div className="modal-card-title">
                {modalHeader}
              </div>
              <button
                type="button"
                id="delete"
                className="delete is-large"
                aria-label="close"
                onClick={e => this.closeModal(e)}
              />
            </header>

            <section className="modal-card-body">
              <fieldset className="m-fieldset">
                {legend}
                {message}
                <MandatoryField />

                <div className="columns is-multiline">
                  <div className="column is-half-tablet field">
                    <label htmlFor="user_name" className="label">{t('fields:email_address')}</label>
                    <div className="control has-icons-left">
                      <input
                        id="modal_username"
                        className={`input is-medium ${errorMessage ? 'is-danger' : ''}`}
                        name="registration[user_name]"
                        required="required"
                        type="text"
                        value={state.username}
                        onChange={e => this.handleUserNameChange(e)}
                        ref={focusUsernameInputField}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-user" />
                      </span>
                      <p>&nbsp;</p>
                    </div>
                  </div>

                  <div className="column is-half-tablet field">
                    <label htmlFor="password" className="label">{t('fields:password')}</label>
                    <div className="control has-icons-left has-icons-right custom-password-field">
                      <PasswordField
                        id="modal_password"
                        className={`input is-medium ${errorMessage ? 'is-danger' : ''}`}
                        name="registration[password]"
                        required="required"
                        type="password"
                        value={state.password}
                        onChange={e => this.handlePasswordChange(e)}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-key" />
                      </span>
                    </div>
                    <p>&nbsp;</p>
                    <p className="info is-success">
                      <a
                        href="/#"
                        id="password-reset"
                        onClick={e => this.renderPasswordResetRequest(e)}
                      >
                        {t('common:forgot_your_password')}
                      </a>
                    </p>
                    {(cookies.get('signupState') === 'personalDetails' || state.lastResponseStatus === 'FAIL_VERIFY')
                      && (
                        <p className="info is-success">
                          <a
                            href="/#"
                            id="resend-request"
                            onClick={e => this.renderResendPasswordConfirmation(e)}
                          >

                            {t('common:enter_verification_code')}
                          </a>
                        </p>
                      )}
                  </div>
                </div>
              </fieldset>
            </section>

            <footer className="modal-card-foot">
              <div className="columns is-centered is-full-width">
                <div className="column is-4-tablet is-hidden-mobile">
                  <button
                    type="button"
                    id="close"
                    className="button button-modal-close has-background-light is-medium is-full-width"
                    onClick={e => this.closeModal(e)}
                  >
                    <i className="icon-close" />
                    &nbsp;&nbsp;
                    {t('buttons:close')}
                    &nbsp;&nbsp;
                  </button>
                </div>
                <div className="column is-4-tablet has-text-centered">
                  <button
                    type="submit"
                    id="signin"
                    className={`button is-success is-medium is-full-width ${state.loading ? 'is-loading' : ''}`}
                  >
                    <span>{t('buttons:sign_in')}</span>
                    <span className="icon is-small">
                      <i className="fas fa-arrow-right" />
                    </span>
                  </button>
                </div>
              </div>
            </footer>
            <FooterMessage />
          </div>
        </div>
      </form>
    );
  }
}

Signin.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
  onToggleModalIn: PropTypes.func.isRequired,
  setUserLoggedInAs: PropTypes.func.isRequired,
  modalType: PropTypes.string,
  providerType: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  errorStatus: PropTypes.string,
  toggleModalResetRequest: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  cookies: PropTypes.instanceOf(Object).isRequired,
  updateUser: PropTypes.func.isRequired,
  storeUserData: PropTypes.func.isRequired,
};

Signin.defaultProps = {
  modalType: '',
  errorMessage: '',
  errorStatus: null,
  providerType: '',
};

export const mapStateToProps = state => ({
  isOpen: state.signInModal?.isOpen,
  errorMessage: state.signInModal?.errorMessage,
  errorStatus: state.signInModal?.errorStatus,
  modalType: state.signInModal?.modalType,
  sourcePage: state.signInModal?.sourcePage,
  providerType: state.signInModal?.providerType,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModal: (step = 1, sessionKey = null, email = null, password = null, from = 'signin') => dispatch({
    type: 'TOGGLE_MODAL', step, sessionKey, email, password, from,
  }),
  onToggleModalIn: errorMessage => dispatch(toggleModalIn(errorMessage)),
  toggleModalResetRequest: () => dispatch({ type: 'TOGGLE_MODAL_RESET_REQUEST' }),
  setUserLoggedInAs: newState => dispatch(setUserLoggedIn(newState)),
  storeUserData: payload => dispatch(setUserData(payload)),
});

export default compose(
  context.withUserHOC,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withCookies,
)(Signin);
