import i18next from 'i18next';
import moment from 'moment';

const addressPattern = /^[A-Za-zÀ-ÖØ-öø-ÿĀ-žȘșȚț]+$/u;
const postcodePattern = /^[A-Za-z0-9 ]+$/u;
const calendarPattern = /^\d{4}-\d{2}-\d{2}$/;
const maxDateForCalendar = moment()
  .subtract(process.env.REACT_APP_AGE_RESTRICTION, 'years')
  .format('YYYY-MM-DD');
const minLength = 1;
const maxLength = 50;

const validateMinMax = (value) =>
  value.length < minLength || value.length > maxLength;

const validate = (values) => {
  const errors = {};
  if (values.postalCode) {
    if (!postcodePattern.test(values.postalCode)) {
      errors.postalCode = i18next.t('errors:post_code');
    } else if (values.postalCode.length < 1 || values.postalCode.length > 10) {
      errors.postalCode = i18next.t('errors:INVALID_LENGTH');
    }
  } else {
    errors.postalCode = i18next.t('errors:required');
  }

  if (values.city) {
    if (!addressPattern.test(values.city)) {
      errors.city = i18next.t('errors:city');
    } else if (validateMinMax(values.city)) {
      errors.city = i18next.t('errors:INVALID_LENGTH');
    }
  } else {
    errors.city = i18next.t('errors:required');
  }

  if (values.address) {
    if (validateMinMax(values.address)) {
      errors.address = i18next.t('errors:INVALID_LENGTH');
    }
  } else {
    errors.address = i18next.t('errors:required');
  }

  if (values.birthDate) {
    const date = values.birthDate.target;
    if (!calendarPattern.test(date?.value)) {
      errors.birthDate = i18next.t('errors:required_must_be_older_than_18');
    } else if (!moment(date?.value).isBefore(maxDateForCalendar)) {
      errors.birthDate = i18next.t('errors:required');
    }
  } else {
    errors.birthDate = i18next.t('errors:required');
  }

  return errors;
};

export default validate;
