/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSignupContext } from './context';
import { toggleModal, Modals } from '../ModalManager';
import SignupState from './SignupState';

const conditionStep = step => (
  step === SignupState.consent
  || step === SignupState.termsConditions
  || step === SignupState.cookiePolicy
  || step === SignupState.privacyPolicy
);

export const headerConditionText = (t, step) => {
  const textHeadersArray = {
    [SignupState.consent]: t('signup:agree_to_following'),
    [SignupState.termsConditions]: t('signup:terms_conditions'),
    [SignupState.privacyPolicy]: t('signup:privacy_policy'),
    [SignupState.cookiePolicy]: t('signup:cookie_policy'),
  };

  return textHeadersArray[step];
};

const SignupHeader = () => {
  const { t } = useTranslation();
  const onLogin = () => {
    toggleModal(Modals.Signup);
    setTimeout(() => toggleModal(Modals.Signin), 500);
  };

  const { signupState } = useSignupContext();

  return (
    <header className="modal-card-head">
      <div className="content">
        <h2 className={`has-text-primary is-size-5-mobile is-size-3-tablet ${signupState.consent ? 'mt-4' : ''}`}>
          {conditionStep(signupState.step) && (
            headerConditionText(t, signupState.step)
          )}
        </h2>
        {!conditionStep(signupState.step) && signupState.step === SignupState.first && (
          <p>
            {t('signup:have_account')}
            <a
              id="onLogin"
              onClick={onLogin}
              href="#"
              className="is-underline"
            >
              {t('signup:log_in_here')}
            </a>
          </p>
        )}
        <button
          id="openSignupModalTop"
          type="button"
          className="button-modal-close modal-close is-large is-primary"
          onClick={() => toggleModal(Modals.Signup)}
          aria-label="close"
        />
      </div>
      {!conditionStep(signupState.step) && (
        <div className="m-steps-v2 columns is-mobile">
          <div className="column is-4 step-item is-active">
            <span>
              <p className="m-step-number has-text-centered">1</p>
              <span className="m-step-title has-text-centered">
                {t('signup:account')}
                <p>
                  {signupState.step < SignupState.fourth && t('signup:create_account')}
                </p>
              </span>
            </span>
          </div>
          <div className={`column is-4 step-item ${
            (signupState.step > SignupState.third && signupState.step !== SignupState.consent)
            || signupState.step === SignupState.resendVerification ? 'is-active' : ''}`}
          >
            <span>
              <p className="m-step-number has-text-centered">2</p>
              <span className="m-step-title has-text-centered">
                {t('signup:verify')}
              </span>
            </span>
          </div>
          <div className={`column is-4 step-item ${
            signupState.step > 4
            && signupState.step !== SignupState.consent
            && signupState.step !== SignupState.resendVerification ? 'is-active' : ''}`}
          >
            <span>
              <p className="m-step-number has-text-centered">3</p>
              <span className="m-step-title has-text-centered">
                {t('signup:bonus')}
              </span>
            </span>
          </div>
        </div>
      )}
      {signupState.step !== SignupState.consent && (
        <button id="openSignupModal" type="button" className="delete" onClick={() => toggleModal(Modals.Signup)} aria-label="close" />
      )}
    </header>
  );
};

export default SignupHeader;
