import {
  TOGGLE_MODAL,
} from '../actions/signUpModalActions';

const initialState = {
  isOpen: false,
  step: 1,
  sessionKey: null,
  email: null,
  password: null,
  from: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        isOpen: !state.isOpen,
        step: action.step || initialState.step,
        sessionKey: action.sessionKey || initialState.sessionKey,
        email: action.email || initialState.email,
        password: action.password || initialState.password,
        from: action.from || initialState.from,
      };

    default:
      return state;
  }
}
