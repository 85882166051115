import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PasswordField from '../../Layout/Fields/PasswordField';
import DateSelects from '../../Layout/Fields/DateSelects';
import LanguageSelect from '../../Layout/Fields/LanguageSelect';

export const InputComponent = ({
  input,
  type,
  meta,
  errorMsg,
}) => {
  const {
    dirty, error, asyncValidating, valid, touched,
  } = meta;
  return (
    <>
      <input
        className={`input is-medium ${((errorMsg || (error && dirty)) ? 'is-danger' : '') || (dirty && valid ? 'is-success' : '')}`}
        type={type}
        {...input}
        autoComplete="off"
        autoCorrect="off"
      />
      {asyncValidating && (
        <span className="icon is-small is-right">
          <i className="fas fa-spinner fa-spin" />
        </span>
      )}
      {((error && dirty) || (touched && !valid)) && <p className="help is-danger">{error}</p>}
    </>
  );
};

InputComponent.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  valueField: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.shape({
    visited: PropTypes.bool,
    dirty: PropTypes.bool,
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.string,
  }),
  errorMsg: PropTypes.string,
};

InputComponent.defaultProps = {
  label: '',
  type: '',
  valueField: '',
  input: {},
  meta: PropTypes.shape({
    visited: false,
    dirty: false,
    touched: false,
    asyncValidating: false,
    valid: false,
    error: '',
  }),
  errorMsg: null,
};

export const InputPasswordComponent = ({
  input,
  type,
  meta,
}) => {
  const {
    dirty, error, valid, touched,
  } = meta;

  return (
    <>
      <PasswordField
        className={`input is-medium ${(error && dirty ? 'is-danger' : '') || (dirty && valid ? 'is-success' : '')}`}
        type={type}
        {...input}
        autoComplete="new-password"
      />
      {((error && dirty) || (touched && !valid)) && <p className="help is-danger">{error}</p>}
    </>
  );
};

InputPasswordComponent.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    valid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

InputPasswordComponent.defaultProps = {
  label: '',
  type: '',
  input: {},
  meta: PropTypes.shape({
    dirty: false,
    valid: false,
    touched: false,
    error: '',
  }),
};

export const DateSelectsComponent = ({
  input,
  meta,
}) => {
  const { valid, dirty, error } = meta;
  const { t } = useTranslation();
  return (
    <DateSelects
      valid={valid}
      touched={dirty}
      {...input}
      value={input?.value?.target?.value}
      validation={{
        required: true,
      }}
      title="birthDate"
      description={t('fields:dob')}
      errorDescription={error}
      daysTitle={t('fields:day')}
      monthTitle={t('fields:month')}
      yearTitle={t('fields:year')}
    />
  );
};

DateSelectsComponent.propTypes = {
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    valid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

DateSelectsComponent.defaultProps = {
  input: {},
  meta: PropTypes.shape({
    dirty: false,
    touched: false,
    valid: false,
    error: '',
  }),
};

export const PhoneNumberComponent = ({
  input,
  type,
  fieldValue,
  meta,
}) => {
  const {
    visited, dirty, error, asyncValidating, valid,
  } = meta;
  return (
    <>
      <input
        className={`input is-medium ${(visited && dirty && error ? 'is-danger' : '') || (visited && dirty && valid ? 'is-success' : '')}`}
        type={type}
        {...input}
        value={fieldValue}
      />
      {asyncValidating && (
        <span className="icon is-small is-right">
          <i className="fas fa-spinner fa-spin" />
        </span>
      )}
      {visited && dirty && error && <p className="help is-danger">{error}</p>}
    </>
  );
};

PhoneNumberComponent.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  fieldValue: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.shape({
    visited: PropTypes.bool,
    dirty: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

PhoneNumberComponent.defaultProps = {
  label: '',
  type: '',
  fieldValue: '',
  input: {},
  meta: PropTypes.shape({
    visited: false,
    dirty: false,
    asyncValidating: false,
    valid: false,
    error: '',
  }),
};

export const LanguageSelectorComponent = (props) => {
  const { meta } = props;
  const { visited, dirty, error } = meta;

  return (
    <>
      <LanguageSelect {...props} />
      {visited && dirty && error && <p className="help is-danger">{error}</p>}
    </>
  );
};

LanguageSelectorComponent.propTypes = {
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.string,
    visited: PropTypes.bool,
  }),
};

LanguageSelectorComponent.defaultProps = {
  meta: PropTypes.shape({
    visited: false,
    dirty: false,
    valid: false,
    error: '',
  }),
};
