import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { stripHtml, truncate, getPathname } from '../../Helpers';
import Image from '../../Layout/Image';

export const PlaceholderThumbnail = () => (
  <div className="column is-12-mobile is-6-tablet is-3-desktop">
    <figure className="m-grid-item placeholder-image" />
  </div>
);

const ArticleThumbnail = ({ data, needDay, type }) => {
  const getDay = () => (`0${new Date(data.valid_from).getDate()}`).slice(-2);

  const thumbnail = (data.thumbnail) ? data.thumbnail.meta.download_url : 'https://placehold.it/392x277';
  const excerpt = data.excerpt ? stripHtml(data.excerpt) : truncate(stripHtml(data.body), 20);

  return (
    <NavLink to={getPathname(data.meta.html_url)} className="column is-12-mobile is-6-tablet is-3-desktop">
      <figure className="m-grid-item">
        {needDay
        && (
        <span className="m-grid-item-calendar-day">
          {getDay()}
        </span>
        )}
        <Image src={thumbnail} width="392" />
        <figcaption className="m-grid-item-caption is-size-6-desktop is-size-5-fullhd">
          { excerpt }
        </figcaption>
      </figure>
      {type === 'vip'
      && (
        <span className="m-grid-item-icon">
          <i className="icon-VIP" />
        </span>
      )}
    </NavLink>
  );
};

ArticleThumbnail.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  needDay: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

ArticleThumbnail.defaultProps = {
  needDay: false,
};

export default ArticleThumbnail;
