import React from 'react';
import PropTypes from 'prop-types';
import BasePage from '../Base';
import PageTitle from '../PageTitle';

const TextPage = ({ data }) => {
  const bannerStyle = {};

  if (data.banner) {
    bannerStyle.backgroundImage = `url( ${data.banner.meta.download_url} )`;
  }

  return (
    <BasePage data={data}>
      <div className="has-background-dark-grey main-content-parent">
        { data.meta.show_header && data.banner
          ? (
            <div className="carousel m-carousel m-carousel-short" style={bannerStyle} />
          )
          : (
            <div className="yellow-bottom" />
          )}
        <section className="section m-page-wrapper">
          <div className="container is-fluid">
            <div className="columns is-centered is-mobile">
              <div className="column is-12-mobile is-12-tablet is-9-widescreen m-page-width-wrapper">
                <div className="content typography">
                  <PageTitle title={data.title} show={data.meta.show_title} />
                  <div className="content typography has-link-underline" dangerouslySetInnerHTML={{ __html: data.body }} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </BasePage>
  );
};

TextPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      show_title: PropTypes.bool,
      show_header: PropTypes.bool,
    }),
  }),
};

TextPage.defaultProps = {
  data: {
    meta: {
      show_title: true,
      show_header: true,
    },
  },
};

export default TextPage;
