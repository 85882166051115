/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Casino from '@dialinvest/react-casino';
import { getPathname, showAsPopUp } from '../../Helpers';
import BackgroundImage from '../../Layout/BackgroundImage';

const BucketWidgetItem = ({ data }) => {
  const buttonDefault = (
    <button
      type="button"
      className="button is-outlined is-primary is-size-4"
      ref={
        (el) => {
          if (el) {
            el.style.setProperty('color', data.button_color, 'important');
            el.style.setProperty('background-color', data.button_background_color, 'important');
            el.style.setProperty('border-color', data.button_background_color, 'important');
          }
        }
      }
    >
      {data.button_label}
    </button>
  );

  const [widgetButton, setWidgetButton] = useState(buttonDefault);

  const fetchWidgetLink = async (id) => {
    const result = await new Casino.FetchContent(`pages/${id}`).perform();
    const button = (
      <NavLink
        to={result.data.meta ? getPathname(result.data.meta.html_url) : ''}
        className="button is-outlined is-primary is-size-4"
        ref={
          (el) => {
            if (el) {
              el.style.setProperty('color', data.button_color, 'important');
              el.style.setProperty('background-color', data.button_background_color, 'important');
              el.style.setProperty('border-color', data.button_background_color, 'important');
            }
          }
        }
      >
        {data.button_label}
      </NavLink>
    );
    setWidgetButton(button);
  };

  const setExternalButton = () => {
    let button;
    if (data.has_pop_up) {
      button = (
        <button
          type="button"
          onClick={() => showAsPopUp(data.button_external_link)}
          className="button is-outlined is-primary is-size-4"
          ref={
            (el) => {
              if (el) {
                el.style.setProperty('color', data.button_color, 'important');
                el.style.setProperty('background-color', data.button_background_color, 'important');
                el.style.setProperty('border-color', data.button_background_color, 'important');
              }
            }
          }
        >
          {data.button_label}
        </button>
      );
    } else {
      button = (
        <a
          target="_blank"
          href={data.button_external_link}
          rel="nofollow noopener noreferrer"
          className="button is-outlined is-primary is-size-4"
          ref={
            (el) => {
              if (el) {
                el.style.setProperty('color', data.button_color, 'important');
                el.style.setProperty('background-color', data.button_background_color, 'important');
                el.style.setProperty('border-color', data.button_background_color, 'important');
              }
            }
          }
        >
          {data.button_label}
        </a>
      );
    }
    setWidgetButton(button);
  };

  useEffect(() => {
    if (data.button_target) {
      fetchWidgetLink(data.button_target);
    } else if (data.button_external_link) {
      setExternalButton();
    }
  }, [data]);

  return (
    <div className="column is-12-mobile is-12-tablet is-9-widescreen m-support-item">
      <article className="media">
        <figure className="media-left">
          <BackgroundImage src="/images/support/bg.jpg" elementClass="m-support-item-image-holder">
            <img src={data.image.url} alt="" />
          </BackgroundImage>
        </figure>
        <div className="media-content">
          <div className="content">
            <h2
              className="m-support-item-title has-text-primary is-size-2"
              ref={
                (el) => {
                  if (el) {
                    el.style.setProperty('color', data.title_color, 'important');
                  }
                }
              }
            >
              {data.title}
            </h2>
            <p>{data.description}</p>
            {widgetButton}
          </div>
        </div>
      </article>
    </div>
  );
};

BucketWidgetItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default BucketWidgetItem;
