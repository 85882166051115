import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { goToAnchor, configureAnchors } from 'react-scrollable-anchor';
import moment from 'moment';
import Casino from '@dialinvest/react-casino';
import BasePage from '../Base';
import 'flickity-fade';
import TournamentsPageHeader from './TournamentsPageHeader';
import TournamentsPageHome from './TournamentsPageHome';
import TournamentsPageCalendar from './TournamentsPageCalendar';
import TournamentsPagePast from './TournamentsPagePast';
import { clipHTMLBody, checkSessionCookie } from '../../Helpers';

export class TournamentsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tournaments: [],
      games: [],
      menuID: '1',
      active: '',
      cookieValues: '',
    };
  }

  componentDidMount() {
    this.fetchThisAllArticles();
    this.fetchGames();
  }

  fetchThisAllArticles = async () => {
    const { menuID } = this.state;
    const today = moment().format('YYYY-MM-DD HH:mm:ss');
    let url;

    switch (menuID) {
      case '2':
        url = `tournaments/?starts_at_gte=${today}&ends_at_gte=${today}`;
        break;
      case '3':
        url = `tournaments/?ends_at_lte=${today}`;
        break;
      default:
        url = `tournaments/?starts_at_lte=${today}&ends_at_gte=${today}`;
        break;
    }
    await this.fetchArticles(url, 'tournaments');
  };

  fetchArticles = async (url, stateName) => {
    if (checkSessionCookie()) {
      await this.setState({
        cookieValues: JSON.parse(new Casino.Cookie().get(process.env.REACT_APP_API_COOKIE_NAME)),
      });
    }
    const result = await new Casino.FetchContent(url).perform();
    await this.setState({
      [stateName]: result.items(),
      [`${stateName}Fetched`]: true,
    });
  };

  fetchGames = async () => {
    const url = 'games/?limit=2000';
    const result = await new Casino.FetchContent(url).perform();

    this.setState({
      games: result.items(),
    });
  };

  handleSignup = async (event) => {
    event.preventDefault();
    const { onToggleModalIn } = this.props;
    onToggleModalIn();
    clipHTMLBody();
  };

  handleMenuClick = async (event) => {
    event.preventDefault();
    this.setState({
      menuID: event.target.id,
      tournaments: [],
    }, () => {
      this.fetchThisAllArticles();
      configureAnchors({ offset: 1 });
      goToAnchor('loyaltyAnchor', false);
    });
  };

  showMenu = (event) => {
    event.preventDefault();

    event.currentTarget.classList.toggle('is-active');
    const navBurger = document.querySelector('.m-tournament-nav-button .navbar-burger');
    const navbarMenu = document.querySelector('.navbar-menu.m-tournament-nav-menu');
    navBurger.classList.toggle('is-active');
    navbarMenu.classList.toggle('is-active');
  };

  contentSelector = () => {
    const { t } = this.props;
    const {
      cookieValues,
      menuID,
      active,
      tournaments,
      games,
    } = this.state;

    switch (menuID) {
      case '2':
        return (
          <TournamentsPageCalendar
            cookieValues={cookieValues}
            active={active}
            items={tournaments}
            games={games}
            onSignupAction={this.handleSignup}
            menuID={menuID}
            t={t}
          />
        );
      case '3':
        return (
          <TournamentsPagePast
            cookieValues={cookieValues}
            active={active}
            items={tournaments}
            games={games}
            onSignupAction={this.handleSignup}
            menuID={menuID}
            t={t}
          />
        );
      default:
        return (
          <TournamentsPageHome
            cookieValues={cookieValues}
            active={active}
            items={tournaments}
            games={games}
            onSignupAction={this.handleSignup}
            menuID={menuID}
            t={t}
          />
        );
    }
  };

  render() {
    const { t, i18n, data } = this.props;

    return (
      <div className="m-tournament">
        <div className="container m-tournament-layout">
          <BasePage data={data}>
            <TournamentsPageHeader
              data={data}
              handleMenuClick={this.handleMenuClick}
              showMenu={this.showMenu}
              t={t}
              i18n={i18n}
            />
            {this.contentSelector()}
          </BasePage>
        </div>
      </div>
    );
  }
}

TournamentsPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    description: PropTypes.string.isRequired,
    background_image: PropTypes.instanceOf(Object),
    foreground_image: PropTypes.instanceOf(Object),
    meta: PropTypes.instanceOf(Object),
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
  onToggleModalIn: PropTypes.func.isRequired,
};

TournamentsPage.defaultProps = {
  data: {},
};

export const mapStateToProps = state => ({
  userLoggedIn: state.userState.userLoggedIn,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModalIn: () => dispatch({ type: 'TOGGLE_MODAL_IN' }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withRouter,
)(TournamentsPage);
