import React from 'react';
import PropTypes from 'prop-types';

const PromotionWrapper = ({ children }) => (
  <>
    { children }
  </>
);

PromotionWrapper.propTypes = {
  children: PropTypes.instanceOf(Object),
};

PromotionWrapper.defaultProps = {
  children: [],
};

export default PromotionWrapper;
