import React from 'react';
import PropTypes from 'prop-types';

const CustomButton = (
  {
    htmlClass,
    title,
    onClick,
    linkTo,
    children,
  },
) => (
  <a href={linkTo} className={`button ${htmlClass}`} onClick={onClick}>
    <div className="media">
      <div className="media-content">
        {title}
      </div>
    </div>
    {children}
  </a>
);

CustomButton.propTypes = {
  htmlClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  linkTo: PropTypes.string,
  children: PropTypes.node,
};

CustomButton.defaultProps = {
  linkTo: '/#',
  children: null,
};

export default CustomButton;
