import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BasePage from '../Base';
import LandingPage from '../LandingPage/LandingPage';
import {
  isUserLoggedIn,
  playerAlreadyRegistered,
} from '../../Helpers';
import LoggedInLandingPage from '../LandingPage/LoggedInLandingPage';
import useGames from '../../../hooks/games/useGames';
import useActiveGames from '../../../hooks/games/useActiveGames';

const HomePage = ({ data }) => {
  const { searched } = useGames();
  const { activeCategory, activeProvider } = useActiveGames();
  const selectedCategory = activeCategory?.id || activeProvider?.id;

  return (
    <div className="home-page-wrapper">
      <BasePage data={data}>
        {!isUserLoggedIn()
          && !playerAlreadyRegistered()
          && data.carousel
          && !searched
          && !selectedCategory
          && <LandingPage data={data} />}
        {(isUserLoggedIn()
          || playerAlreadyRegistered()
          || selectedCategory
          || searched)
          && <LoggedInLandingPage data={data} />}
      </BasePage>
    </div>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    carousel: PropTypes.instanceOf(Object).isRequired,
    featured_promotion: PropTypes.instanceOf(Object).isRequired,
  }),
};

HomePage.defaultProps = {
  data: {},
};

export const mapStateToProps = state => ({
  userLoggedIn: state.userState.userLoggedIn,
});

export default compose(
  connect(mapStateToProps),
)(HomePage);
