import React from 'react';
import PropTypes from 'prop-types';
import { isUserLoggedIn } from '../../Helpers';

// eslint-disable-next-line import/prefer-default-export
export const CreateSelectItems = ({ handleMenuClick, t }) => {
  const menuData = [
    { value: t('common:tournament_current'), id: '1', login: '0' },
    { value: t('common:tournament_calendar'), id: '2', login: '0' },
    { value: t('common:tournament_past'), id: '3', login: '0' },
  ];

  return (
    // eslint-disable-next-line no-unused-vars
    menuData.map((e, key) => (
      <a
        className={`menu${e.id} m-top-nav-item ${!isUserLoggedIn() && e.login === '1' ? ' is-hidden' : ''}`}
        href="#loyaltyAnchor"
        id={e.id}
        onClick={handleMenuClick}
      >
        {e.value}
      </a>
    ))
  );
};

CreateSelectItems.propTypes = {
  t: PropTypes.func.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
};
