/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Casino from '@dialinvest/react-casino';

// eslint-disable-next-line import/prefer-default-export
export const SignupThirdStep = ({
  email, username, onVerification, requestNewCode, verificationType = 'email',
}) => {
  let input;
  const { t } = useTranslation();
  const refFirst = React.createRef();
  const refSecond = React.createRef();
  const refThird = React.createRef();
  const refFourth = React.createRef();
  const [state, setState] = useState({
    first: '',
    second: '',
    third: '',
    fourth: '',
    email_address: email,
    error: null,
    errorFromApi: false,
    success: null,
    loading: false,
    verification: false,
    verificationType,
  });

  const formatCode = () => `${state.first}${state.second}${state.third}${state.fourth}`;

  useEffect(() => {
    // elemRefs = [];
    const checkCode = () => {
      const code = formatCode();
      const isnum = /^\d+$/.test(code);
      const emailAddress = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email_address);
      if (code.length === 4 && isnum === true && emailAddress === true) {
        /* istanbul ignore next */
        setState(prev => ({
          ...prev,
          verification: true,
          errorFromApi: false,
          error: null,
        }));
      } else {
        setState(prev => ({
          ...prev,
          verification: false,
          errorFromApi: false,
          error: '',
        }));
      }
    };
    checkCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.first, state.second, state.third, state.fourth, state.email_address]);

  const handleEmailChange = (e) => {
    const { name, value } = e.target;

    setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const autoTab = (e, name) => {
    const BACKSPACE_KEY = 8;

    if (name === 'first' && e.keyCode !== BACKSPACE_KEY) {
      refSecond.current.focus();
    } else if (name === 'second' && e.keyCode !== BACKSPACE_KEY) {
      refThird.current.focus();
    } else if (name === 'second' && e.keyCode === BACKSPACE_KEY && state.second === '') {
      refFirst.current.focus();
    } else if (name === 'third' && e.keyCode !== BACKSPACE_KEY) {
      refFourth.current.focus();
    } else if (name === 'third' && e.keyCode === BACKSPACE_KEY && state.third === '') {
      refSecond.current.focus();
    } else if (name === 'fourth' && e.keyCode === BACKSPACE_KEY && state.fourth === '') {
      refThird.current.focus();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    autoTab(e, name);

    setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitResendCode = async (type) => {
    setState(prev => ({
      ...prev,
      success: null,
      error: null,
      errorFromApi: false,
      loading: true,
    }));
    const response = await new Casino.models.ResendVerificationCode(username, type).perform();
    if (response.success()) {
      const message = (type === 'mobile') ? t('common:code_sent_sms') : response.message();
      setState(prev => ({
        ...prev,
        success: message,
        loading: false,
      }));
    } else {
      setState(prev => ({
        ...prev,
        error: response.message(),
        errorFromApi: true,
        loading: false,
      }));
    }
  };

  const submitResendCodeByType = (e, type) => {
    e.preventDefault();

    if (username.trim() === '') {
      requestNewCode(e, type);
    } else {
      submitResendCode(type);
    }

    setState(prev => ({
      ...prev,
      verificationType: type,
    }));
  };

  const verify = async () => {
    const emailAddress = state.email_address;
    setState(prev => ({
      ...prev,
      success: null,
      error: null,
      errorFromApi: false,
      loading: true,
    }));

    const params = {
      confirmationCode: formatCode(),
      email: emailAddress,
    };

    const response = await new Casino.models.SignupConfirmationCode(params).perform();

    if (response.success()) {
      setState(prev => ({
        ...prev,
        success: response.message(),
        loading: false,
      }));
      onVerification();
    } else {
      setState(prev => ({
        ...prev,
        error: response.message(),
        errorFromApi: true,
        loading: false,
      }));
    }
  };

  if (email === null || email === undefined) {
    input = (
      <div className="columns is-mobile">
        <div className="column">
          <input
            className="input is-medium"
            id="email_address"
            name="email_address"
            icon="fa-envelope"
            required="required"
            type="text"
            placeholder={t('buttons:e_mail_address')}
            onChange={handleEmailChange}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="step-content is-active has-text-centered">
      <div className="content">
        {(state.verificationType === 'mobile') && (email === null || email === undefined) && (
          <p>{t('common:verify_account_title_sms_with_mail')}</p>
        )}
        {(state.verificationType === 'email') && (email === null || email === undefined) && (
          <p>{t('common:verify_account_title_email_with_mail')}</p>
        )}
        {(state.verificationType === 'mobile') && email !== null && email !== undefined && (
          <p>{t('common:verify_account_title_sms')}</p>
        )}
        {(state.verificationType === 'email') && email !== null && email !== undefined && (
          <p>{t('common:verify_account_title_email')}</p>
        )}
      </div>
      {state.error && (
        <div className={`notification content${state.errorFromApi ? ' is-danger' : ''}`}>
          { state.error}
        </div>
      )}
      {state.success && (
        <div className="notification is-success content">
          { state.success}
        </div>
      )}
      { input}
      <div id="js-verify-sms" className="columns is-mobile">
        <div className="column">
          <input name="first" placeholder="_" key="first" data-index="0" className="input has-text-centered" ref={refFirst} maxLength={1} minLength={1} type="text" pattern="[0-9]*" onKeyUp={handleChange} />
        </div>
        <div className="column">
          <input name="second" placeholder="_" key="second" data-index="1" className="input has-text-centered" ref={refSecond} maxLength={1} minLength={1} type="text" pattern="[0-9]*" onKeyUp={handleChange} />
        </div>
        <div className="column">
          <input name="third" placeholder="_" key="third" data-index="2" className="input has-text-centered" ref={refThird} maxLength={1} minLength={1} type="text" pattern="[0-9]*" onKeyUp={handleChange} />
        </div>
        <div className="column">
          <input name="fourth" placeholder="_" key="fourth" data-index="3" className="input has-text-centered" ref={refFourth} maxLength={1} minLength={1} type="text" pattern="[0-9]*" onKeyUp={handleChange} />
        </div>
      </div>
      <button type="button" id="verify" className={`button is-success is-medium ${state.loading ? 'is-loading' : ''}`} disabled={state.loading === true || state.verification === false} onClick={verify}>
        {t('buttons:verify')}
      </button>
      {!state.loading && (
        <div className="content">
          <br />
          <p>
            {t('common:resend_text_1')}
            <br />
            <a href="/#" id="resend" style={{ textDecoration: 'underline' }} onClick={e => submitResendCodeByType(e, 'email')}>
              EMAIL
            </a>
            {' '}
            {t('common:or')}
            {' '}
            <a href="/#" id="request-code" style={{ textDecoration: 'underline' }} onClick={e => submitResendCodeByType(e, 'mobile')}>
              SMS
            </a>
            .
            <br />
            <br />
          </p>
        </div>
      )}
    </div>
  );
};

SignupThirdStep.propTypes = {
  email: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  onVerification: PropTypes.func.isRequired,
  requestNewCode: PropTypes.func.isRequired,
  verificationType: PropTypes.string.isRequired,
};
