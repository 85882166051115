import React, { PureComponent } from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';

class SimpleBonusSlider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bonuses: [],
    };
    this.cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
  }

  componentDidMount() {
    if (this.cookieValues !== undefined) {
      this.initBonuses();
    }
  }

  initBonuses = () => {
    const { availableBonuses } = this.props;
    const bonuses = availableBonuses;

    this.setState({ bonuses });
  };

  render() {
    const { i18n, currentBonus } = this.props;
    const { bonuses } = this.state;

    const isoLanguage = i18n.language.split('-')[0];

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div
        ref={this.bonusSliderContainer}
        id="bonusSliderContainer"
        className={`m-bonus-slider m-bonus-slider-simple has-text-centered 
          ${(bonuses.length > 0) ? 'is-active' : 'is-inactive'}`}
      >
        {bonuses.length > 0 && (
          <>
            <div>
              <h2 className="m-bonus-slider-simple__title">{ currentBonus?.bonusPlanName }</h2>
              <p className="m-bonus-slider-simple__sub-title">{ currentBonus?.description[isoLanguage]?.subTitle }</p>
            </div>
            <div>
              {currentBonus?.description[isoLanguage]?.Description.length > 0
              && (
                <p style={{ fontWeight: '100', paddingTop: '10px', paddingBottom: '10px' }}>
                  { currentBonus?.description[isoLanguage]?.Description }
                </p>
              )}
              <p className="promo-code" style={{ fontWeight: '800', paddingLeft: '0.5rem' }}>
                PROMO CODE:
                <span>
                  { currentBonus?.bonusPlanId }
                </span>
              </p>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default SimpleBonusSlider;

SimpleBonusSlider.propTypes = {
  availableBonuses: PropTypes.instanceOf(Array).isRequired,
  currentBonus: PropTypes.instanceOf(Object).isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
};
