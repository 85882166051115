import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Casino from '@dialinvest/react-casino';
import BasePage from '../Base';
import EmailPageContent from './EmailPageContent';
import { clipHTMLBody } from '../../Helpers';

class EmailPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      question: '',
      firsName: '',
      lastName: '',
    };
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleQuestionChange = (event) => {
    this.setState({ question: event.target.value });
  };

  handleFirstNameChange = (event) => {
    this.setState({ firsName: event.target.value });
  };

  handleLastNameChange = (event) => {
    this.setState({ lastName: event.target.value });
  };

  handleEmail = async (event) => {
    const { onEmailSentToggleModal, onErrorToggleModal, t } = this.props;
    const {
      email, question, firsName, lastName,
    } = this.state;
    const substring = 'Email sent to';
    event.preventDefault();
    const result = await new Casino.SendMail('post', 'send-email/', firsName, lastName, question, email, t('common:support_request')).perform();
    if (result.data !== undefined && result.data.indexOf(substring) !== -1) {
      onEmailSentToggleModal();
      clipHTMLBody();
    } else {
      onErrorToggleModal();
      clipHTMLBody();
    }
  };

  render() {
    const { data } = this.props;

    return (
      <BasePage data={data}>
        {
          data.banner
          && (
            <div
              className="carousel m-carousel m-carousel-accounts"
              style={{
                backgroundImage: `url(${data.banner.meta.download_url})`,
              }}
            >
              <div className="container is-fluid" />
            </div>
          )
        }
        <EmailPageContent
          data={data}
          handleEmail={e => this.handleEmail(e)}
          handleEmailChange={this.handleEmailChange}
          handleQuestionChange={this.handleQuestionChange}
          handleFirstNameChange={this.handleFirstNameChange}
          handleLastNameChange={this.handleLastNameChange}
        />
      </BasePage>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  onEmailSentToggleModal: () => dispatch({ type: 'TOGGLE_MODAL_EMAIL_SENT' }),
  onErrorToggleModal: () => dispatch({ type: 'TOGGLE_MODAL_ERROR' }),
});

EmailPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sub_title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
  }),
  onEmailSentToggleModal: PropTypes.func.isRequired,
  onErrorToggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

EmailPage.defaultProps = {
  data: {},
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withTranslation(),
)(EmailPage);
