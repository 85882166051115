import i18next from 'i18next';
import Casino from '@dialinvest/react-casino';
import * as parsePhone from 'libphonenumber-js';

const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿĀ-žȘșȚț]+$/u;

const minLength = 1;
const maxLength = 50;

const validateMinMax = value => value.length < minLength || value.length > maxLength;

const validate = (values) => {
  const errors = {};

  if (values.lastName) {
    if (!namePattern.test(values.lastName)) {
      errors.lastName = i18next.t('errors:last_name');
    } else if (validateMinMax(values.lastName)) {
      errors.lastName = i18next.t('errors:INVALID_LENGTH');
    }
  } else {
    errors.lastName = i18next.t('errors:required');
  }

  if (values.firstName) {
    if (!namePattern.test(values.firstName)) {
      errors.firstName = i18next.t('errors:first_name');
    } else if (validateMinMax(values.firstName)) {
      errors.firstName = i18next.t('errors:INVALID_LENGTH');
    }
  } else {
    errors.firstName = i18next.t('errors:required');
  }

  if (values.mobile?.length < 4) errors.mobile = i18next.t('errors:required');

  return errors;
};

export const asyncValidate = async (values) => {
  const mobilePhoneNumber = values.mobile.replace('+', '');
  const params = {
    field: 'mobilePhone',
    value: mobilePhoneNumber,
  };

  if (values.mobile) {
    const phoneNumber = parsePhone.parsePhoneNumberFromString(values.mobile);
    if (!phoneNumber || !phoneNumber.isValid()) {
      // eslint-disable-next-line no-throw-literal
      throw { mobile: i18next.t('errors:mobile') };
    }

    const result = await new Casino.models.CasinoBase().request('get', 'checkCredential', params);

    if (result.data.fieldExist) {
      // eslint-disable-next-line no-throw-literal
      throw { mobile: i18next.t('errors:DUPLICATE') };
    }
  } else {
    // eslint-disable-next-line no-throw-literal
    throw { mobile: i18next.t('errors:required') };
  }
};

export default validate;
