export const SET_USER_STATE = 'SET_USER_STATE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SET_USER_SEARCH = 'SET_USER_SEARCH';
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';
export const SET_USER_FAVORITE_GAMES = 'SET_USER_FAVORITE_GAMES';

export function setUserLoggedIn(newState) {
  return {
    type: SET_USER_STATE,
    newState,
  };
}

export function setUserSearch(newState) {
  return {
    type: SET_USER_SEARCH,
    newState,
  };
}

export function clearUserSearch() {
  return {
    type: CLEAR_USER_SEARCH,
  };
}

export function setUserFavoriteGames(payload) {
  return {
    type: SET_USER_FAVORITE_GAMES,
    payload,
  };
}

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload,
  };
}

export function clearUserData() {
  return {
    type: CLEAR_USER_DATA,
  };
}
