import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { toggleModalIn } from '../../../redux/actions/signInModalActions';
import { clipHTMLBody } from '../../Helpers';
import validate from '../../../containers/utitilies/validator_pwd_change';
import { RenderPasswordField } from '../../Layout/Fields/renderPasswordFields';

const SubmitButton = ({
  pristine,
  invalid,
  loading,
  submitPasswordChange,
  text,
}) => (
  <button
    disabled={pristine || invalid}
    className={`button is-success is-medium is-full-width ${loading ? 'is-loading' : ''}`}
    onClick={submitPasswordChange}
    type="button"
  >
    <span>{text}</span>
    <span className="icon is-small">
      <i className="fas fa-arrow-right" />
    </span>
  </button>
);

SubmitButton.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitPasswordChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export const PasswordResetModal = ({
  isOpen,
  message,
  success,
  submitPasswordChange,
  handleNewPasswordChange,
  handleConfirmNewPasswordChange,
  toggleModal,
  pristine,
  invalid,
  loading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loginRedirectCallback = () => {
    toggleModal();
    dispatch(toggleModalIn());
    clipHTMLBody();
  };

  return (
    <div id="modal-password-change" className={`modal modal-fx-superScaled ${isOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card modal-content">
        <header className="modal-card-head">
          <div className="modal-card-title">
            <h2 className="is-size-3 is-size-4-mobile has-text-white has-text-centered">
              {t('common:password_reset_header')}
            </h2>
          </div>
          <button
            type="button"
            id="delete"
            className="delete"
            aria-label="close"
            onClick={toggleModal}
          />
        </header>
        <section className="modal-card-body">
          <fieldset className="m-fieldset">
            {message && (
              <div className={`notification ${success ? 'is-success' : 'is-danger'} content`}>
                {message}
              </div>
            )}
            {!success
            && (
              <legend className="m-legend is-size-6 is-size-6-mobile">
                {t('common:password_reset_text')}
              </legend>
            )}
            {!success
            && (
              <div className="level is-mobile has-text-primary has-text-left">
                <div className="level-left is-inline-block is-full-width">
                  <span className="icon is-medium is-vertical-middle">
                    <span className="fa-stack">
                      <i className="fas fa-circle fa-stack-2x" />
                      <i className="fas fa-info fa-stack-1x fa-inverse" />
                    </span>
                  </span>
                    &nbsp;
                  <span>
                    { t('fields:all_fields_mandatory') }
                  </span>
                </div>
              </div>
            )}
            {!success
            && (
              <div className="columns is-multiline">
                <div className="column is-half-tablet field m-account-auto-margin">
                  <label htmlFor="new_password" className="label">{ t('fields:new_password') }</label>
                  <div className="control has-icons-left">
                    <Field
                      className="input is-medium"
                      id="new_password"
                      component={RenderPasswordField}
                      onChange={handleNewPasswordChange}
                      name="new_password"
                      required="required"
                      type="password"
                      icon="fa-lock"
                    />
                  </div>
                </div>

                <div className="column is-half-tablet field m-account-auto-margin">
                  <label htmlFor="confirm_new_password" className="label">{ t('fields:confirm_new_password') }</label>
                  <div className="control has-icons-left">
                    <Field
                      className="input is-medium"
                      id="confirm_new_password"
                      component={RenderPasswordField}
                      onChange={handleConfirmNewPasswordChange}
                      name="confirm_new_password"
                      required="required"
                      type="password"
                      icon="fa-lock"
                    />
                  </div>
                </div>

                <div className="column is-half-tablet field m-account-auto-margin is-hidden-desktop">
                  <SubmitButton
                    invalid={invalid}
                    pristine={pristine}
                    submitPasswordChange={submitPasswordChange}
                    loading={loading}
                    text={t('buttons:changePassword')}
                  />
                </div>

              </div>
            )}
          </fieldset>

        </section>
        <footer className="modal-card-foot is-hidden-touch">
          <div className="columns is-centered is-full-width">
            <div className="column is-4-tablet is-hidden-mobile">
              <button
                className="button button-modal-close has-background-light is-medium is-full-width"
                onClick={toggleModal}
                type="button"
              >
                <i className="icon-close" />
                  &nbsp;&nbsp;
                {t('buttons:close').toUpperCase()}
                  &nbsp;&nbsp;
              </button>
            </div>
            <div className="column is-5-tablet has-text-centered">
              {success
                && (
                <button
                  id="redirect-login-button"
                  className={`button is-success is-medium is-full-width ${loading ? 'is-loading' : ''}`}
                  onClick={loginRedirectCallback}
                  type="button"
                >
                  <span>{t('buttons:log_in')}</span>
                  <span className="icon is-small">
                    <i className="fas fa-arrow-right" />
                  </span>
                </button>
                )}
              {!success
                && (
                <SubmitButton
                  invalid={invalid}
                  pristine={pristine}
                  submitPasswordChange={submitPasswordChange}
                  loading={loading}
                  text={t('buttons:changePassword')}
                />
                )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

PasswordResetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  submitPasswordChange: PropTypes.func.isRequired,
  handleNewPasswordChange: PropTypes.func.isRequired,
  handleConfirmNewPasswordChange: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'resetForm',
  validate,
})(PasswordResetModal);
