import React from 'react';
import { useTranslation } from 'react-i18next';

const RecaptchaText = () => {
  const { t } = useTranslation('common');
  return (
    <small className="m-recaptcha has-text-white is-size-7 px-3 has-text-centered">
      {t('protected_by_recaptcha')}
      &nbsp;
      <a href="https://policies.google.com/privacy" className="has-text-white" target="_blank" rel="nofollow noopener noreferrer">{t('privacy_policy')}</a>
      &nbsp;
      {t('and')}
      &nbsp;
      <a href="https://policies.google.com/terms" className="has-text-white" target="_blank" rel="nofollow noopener noreferrer">{t('terms_of_service')}</a>
      &nbsp;
      {`${t('apply')}.`}
    </small>
  );
};

export default RecaptchaText;
