import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { gsap } from 'gsap';
import { useOnLoadImages } from '../../../hooks/useOnLoadImages';
import { clipHTMLBody } from '../../Helpers';

const LandingPageHeroBanner = (props) => {
  const handleSignup = (e) => {
    const { onToggleModal } = props;
    e.preventDefault();
    onToggleModal();
    clipHTMLBody();
  };

  const {
    t, spinsAmount, bonusAmount, heroImage,
  } = props;
  const preloadImages = useRef(null);
  const imagesLoaded = useOnLoadImages(preloadImages);

  useEffect(() => {
    const counterAnimation = () => {
      gsap.set('#bonusAmount', {
        innerText: 0,
      });

      gsap.set('#spinsAmount', {
        innerText: 0,
      });

      gsap.to('#bonusAmount', {
        innerText: bonusAmount,
        duration: 3,
        snap: {
          innerText: 1,
        },
      });

      gsap.to('#spinsAmount', {
        innerText: spinsAmount,
        duration: 3,
        snap: {
          innerText: 1,
        },
      });
    };
    if (imagesLoaded) {
      counterAnimation();
    }
  }, [imagesLoaded, bonusAmount, spinsAmount]);

  return (
    <>
      {!imagesLoaded && (
        <>
          <div className="is-hidden" ref={preloadImages}>
            <img src={heroImage} alt="" />
            <img src="/images/curves-mobile.webp" alt="" />
            <img src="/images/curves.webp" alt="" />
            <img src="/images/curves-02.webp" alt="" />
          </div>
          <div className="container is-fluid p-0">
            <div className="m-landing-page-hero-banner">
              <div className="m-landing-page-hero-banner__content">
                <div className="loader" />
              </div>
            </div>
          </div>
        </>
      )}
      {imagesLoaded && (
      <div className="container is-fluid p-0">
        <div className="m-landing-page-hero-banner" style={{ backgroundImage: `url(${heroImage})` }}>
          <div className="m-landing-page-hero-banner__content">
            <h2 className="m-landing-page-hero-banner__title has-text-centered has-text-white">
              <span className="m-landing-page-hero-banner__bonus">
                <span className="m-landing-page-hero-banner__small">$/€</span>
                <span id="bonusAmount" className="m-landing-page-hero-banner__bonus-amount">
                  {bonusAmount}
                </span>
              </span>
              <span className="m-landing-page-hero-banner__spins">
                <span className="m-landing-page-hero-banner__spins-amount">
                  +
                  <span id="spinsAmount">
                    {spinsAmount}
                  </span>
                </span>
                <span className="m-landing-page-hero-banner__spins-text is-uppercase" dangerouslySetInnerHTML={{ __html: t('common:free_spins_html') }} />
              </span>
            </h2>
            <button
              type="button"
              data-modal-target="modal-signup"
              id="modal-signup-button"
              onClick={e => handleSignup(e)}
              className="button is-primary is-rounded"
            >
              { t('buttons:sign_up') }
            </button>
            <div className="content is-uppercase is-small-link" dangerouslySetInnerHTML={{ __html: t('common:bonus_terms_apply') }} />

            <div className="m-landing-page-section-curve-mobile is-hidden-desktop">
              <img src="/images/curves-mobile.png" alt="curve" />
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};


LandingPageHeroBanner.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  heroImage: PropTypes.func.isRequired,
  spinsAmount: PropTypes.number.isRequired,
  bonusAmount: PropTypes.number.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  onToggleModal: () => dispatch({ type: 'TOGGLE_MODAL' }),
});

export default compose(
  connect(null, mapDispatchToProps),
)(LandingPageHeroBanner);
