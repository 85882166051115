import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePlayer from '../usePlayer';
import useGames from './useGames';
import { isFavoriteCategory } from '../../redux/actions/gamesActions/GamesActionHelpers';
import { updateGamesAndCategories } from '../../redux/actions/gamesActions/categoriesAndProviderActions';
import useQuery from '../useQuery';
import { updateFavoriteGames } from '../../redux/actions/gamesActions';
import useActiveGames from './useActiveGames';

let updated = false;

function useGamesUpdater() {
  const { player } = usePlayer();
  const {
    categories, providers, gamesByCategories, gamesByProviders,
  } = useGames();
  const { activeCategory, activeProvider } = useActiveGames();
  const dispatch = useDispatch();
  const { query } = useQuery();
  const searchText = query.get('search');
  const userLoggedIn = useSelector(state => state.userState.userLoggedIn);

  React.useEffect(() => {
    if (!userLoggedIn || !player) {
      updated = false;
      return;
    }

    const length = isFavoriteCategory(categories) ? 1 : 0;
    const gamesByCategoriesLength = Object.keys(gamesByCategories)?.length;
    const gamesByProvidersLength = Object.keys(gamesByProviders)?.length;

    const allCategoriesFetched = categories.items.length
      === gamesByCategoriesLength + length;

    if (!allCategoriesFetched && !searchText && !activeCategory?.id && !activeProvider?.id) return;

    const emailDomain = player?.email?.split('@')[1];

    if (!updated && (gamesByCategoriesLength || gamesByProvidersLength || searchText)) {
      dispatch(
        updateGamesAndCategories(
          player.sessionKey,
          emailDomain,
          providers.countryPreview,
        ),
      );
      dispatch(updateFavoriteGames(player.sessionKey));
      updated = true;
    }
  }, [
    dispatch,
    userLoggedIn,
    player,
    categories,
    gamesByCategories,
    gamesByProviders,
    providers.countryPreview,
    activeCategory,
    activeProvider,
    searchText,
  ]);

  return null;
}

export default useGamesUpdater;
