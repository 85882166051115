import React from 'react';
import PropTypes from 'prop-types';
import TournamentsWidgetList from './TournamentsWidgetList';

const TournamentsPageHome = ({
  cookieValues,
  active,
  items,
  games,
  onSignupAction,
  menuID,
  t,
}) => (
  <>
    <section className="section m-tournament-main">
      <div className="columns is-tablet">
        <div className="column is-12-mobile is-9-tablet">
          <div className="m-tournament-sub-header">
            <p
              className="m-tournaments-main-subtitle has-background-primary is-size-4 is-size-6-mobile has-text-weight-bold"
            >
              {t('common:tournament_current')}
            </p>
            <figure className="image">
              <img alt="Current Tournaments" src="/images/current_tournaments_header.jpg" />
            </figure>
          </div>

          <div className="content my-6 ml-4 is-size-5">
            <p>
              {t('common:tournament_description')}
            </p>
          </div>

          <TournamentsWidgetList cookieValues={cookieValues} active={active} items={items} games={games} onSignupAction={onSignupAction} menuID={menuID} />
        </div>

        <div className="column m-tournament-sidebar is-12-mobile is-3-tablet">
          <aside className="columns">
            <div className="column">
              <img alt="Current Tournaments" src="/images/tournament-A_prizes_776x1400.jpg" />
            </div>
            <div className="column">
              <img alt="Current Tournaments" src="/images/tournament-B_prizes_776x1400.jpg" />
            </div>
          </aside>
        </div>

      </div>
    </section>
  </>
);

TournamentsPageHome.propTypes = {
  cookieValues: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  active: PropTypes.string,
  items: PropTypes.instanceOf(Array),
  games: PropTypes.instanceOf(Array),
  onSignupAction: PropTypes.func.isRequired,
  menuID: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]).isRequired,
  t: PropTypes.func.isRequired,
};

TournamentsPageHome.defaultProps = {
  cookieValues: '',
  active: '',
  items: [],
  games: [],
};

export default TournamentsPageHome;
