/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, initialize } from 'redux-form';
import store from '../../../containers/App/store';
import { InputComponent } from './inputs';
import { useSignupContext } from './context';
import validate from '../../../containers/utitilies/validator_signup_third_step';
import SignupConsentStep from './SignupConsentStep';

const SignupThirdStep = (props) => {
  const { t } = useTranslation();
  const {
    setField,
    signupState,
  } = useSignupContext();

  useEffect(() => {
    const fields = {
      ...signupState.fields,
      address: signupState.fields.address,
      city: signupState.fields.city,
      postalCode: signupState.fields.postalCode,
    };
    store.dispatch(initialize('SignupThirdStepForm', fields));
  }, []);

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-8">
          <div className="field">
            <label className="label is-size-5">{t('signup:street_house')}</label>
            <div className="control">
              <Field
                className="input is-medium"
                type="text"
                name="address"
                onChange={setField}
                component={InputComponent}
              />
            </div>
          </div>
          <div className="field">
            <label className="label is-size-5">{t('signup:postal_code')}</label>
            <div className="control">
              <Field
                className="input is-medium"
                type="text"
                name="postalCode"
                onChange={setField}
                component={InputComponent}
              />
            </div>
          </div>
          <div className="field">
            <label className="label is-size-5">{t('signup:city')}</label>
            <div className="control">
              <Field
                className="input is-medium"
                type="text"
                name="city"
                value={signupState.fields.city}
                onChange={setField}
                component={InputComponent}
              />
            </div>
          </div>
        </div>
      </div>
      <SignupConsentStep signupForm {...props} />
    </>
  );
};

export default reduxForm({
  form: 'SignupThirdStepForm',
  validate,
})(SignupThirdStep);
