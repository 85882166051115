/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Casino from '@dialinvest/react-casino';
import { Cookies, useCookies } from 'react-cookie';
import { useSignupContext } from './context';
import {
  amlCheck,
  createCookie,
  getPlayerInfo,
} from '../../Helpers';
import { REGISTERED_COOKIE_NAME } from '../../../utils/constants';
import { updateUserConsents } from './signupApi';
import store from '../../../containers/App/store';
import { setUserLoggedIn, setUserData } from '../../../redux/actions/userActions';
import usePlayer from '../../../hooks/usePlayer';

const SignupFourthStep = () => {
  const { t } = useTranslation();
  const {
    signupState,
    setSignupState,
    login,
    resendVerificationCode,
  } = useSignupContext();

  const [state, setState] = useState({
    code: '',
    loading: false,
  });

  const [cookie, setCookie, removeCookie] = useCookies();
  const { player, playerActions: { updateUser } } = usePlayer();

  const smsButtonRef = useRef();
  const emailButtonRef = useRef();

  const autoLogin = async () => {
    const { consent } = signupState;

    login().then(async (response) => {
      const { sessionKey } = response.data;
      const session = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);

      await updateUserConsents(consent, sessionKey);

      const playerInfo = await getPlayerInfo();

      amlCheck(playerInfo.data);
      store.dispatch(setUserLoggedIn(true));
      store.dispatch(setUserData(session));

      updateUser({
        ...player,
        tac: session.tac,
        pp: session.pp,
        email: session.email,
        sessionKey: session.sessionKey,
        sourceOfWealth: '',
      });

      setSignupState({
        ...signupState,
        step: signupState.step + 1,
        sessionKey,
      });
    });
  };

  const onChange = (event) => {
    const { value, name } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const disableSMSButton = () => {
    smsButtonRef.current.classList.add('button-disabled-resend');
  };

  const disableEmailButton = () => {
    emailButtonRef.current.classList.add('button-disabled-resend');
  };

  const checkResendButtons = () => {
    if (cookie.smsSent === 'true') {
      disableSMSButton();
    }

    if (cookie.emailSent === 'true') {
      disableEmailButton();
    }
  };

  const removeResendCookies = () => {
    removeCookie('smsSent');
    removeCookie('emailSent');
  };

  const verify = async () => {
    setState(prev => ({
      ...prev,
      loading: true,
    }));

    const params = {
      confirmationCode: state.code,
      email: signupState.fields.email,
    };

    const response = await new Casino.models.SignupConfirmationCode(params).perform();

    if (response.success()) {
      autoLogin();
      createCookie(REGISTERED_COOKIE_NAME, true, 525600);
      setState(prev => ({
        ...prev,
        success: response.message(),
        loading: false,
      }));
      removeResendCookies();
    } else {
      setState(prev => ({
        ...prev,
        error: response.message(),
        loading: false,
      }));
      checkResendButtons();
    }
  };

  useEffect(() => {
    checkResendButtons();
  }, [cookie]);

  useEffect(() => {
    if (signupState.showExtraMessage) {
      setState(prev => ({
        ...prev,
        error: undefined,
        loading: false,
      }));
    }
  }, [signupState.showExtraMessage]);

  useEffect(() => {
    if (state.error || signupState.validationFail) {
      setSignupState(prevState => ({
        ...prevState,
        showExtraMessage: false,
      }));
    }
  }, [state.error, signupState.validationFail]);

  let statusClass = '';
  if (state.error) {
    statusClass = 'has-text-danger';
  } else if (state.code.length === 4) {
    statusClass = 'has-text-success';
  }

  const setResendCookieByType = (type) => {
    if (type === 'mobile') {
      setCookie('smsSent', true);
    } else {
      setCookie('emailSent', true);
    }
  };

  const resendCode = (e, type) => {
    resendVerificationCode(e, type);
    setResendCookieByType(type);
    checkResendButtons();
  };

  return (
    <>
      <div className="content">
        <p className="content has-text-centered is-size-6-mobile is-size-5">
          {t(`signup:verify_account_title_${signupState.verificationType}`)}
        </p>
      </div>
      <div className="columns is-centered verify">
        <div className="column is-8">
          {(state.error || signupState.validationFail) && !signupState.showExtraMessage && (
            <div id="errorMessage" className="notification content is-danger has-text-centered">
              {state.error}
              {signupState.validationFail && (
                <>
                  <p className="is-uppercase is-size-6 mb-2">
                    <strong>{signupState.validationFail.title}</strong>
                  </p>
                  <p className="mb-0">{signupState.validationFail.message}</p>
                  <p className="mb-0">{signupState.validationFail.contact}</p>
                </>
              )}
            </div>
          )}
          {signupState.showExtraMessage && !state.error && (
            <div id="errorMessage" className="notification content is-success">
              {t(`signup:resend_message_${signupState.verificationResendType}`)}
            </div>
          )}
          <div className="field">
            <label className="label is-size-5">{t('signup:verification_code')}</label>
            <div className="field columns is-mobile is-vcentered">
              <div className="column is-10-mobile is-11-tablet">
                <input
                  name="code"
                  id="inputCode"
                  onChange={onChange}
                  value={state.code}
                  maxLength={4}
                  minLength={4}
                  type="text"
                  disabled={state.loading}
                  pattern="[0-9]*"
                  className={`input is-large is-uppercase has-text-centered is-verification-input ${statusClass}`}
                />
              </div>
              <div className="column is-2-mobile is-1-tablet">
                <span className="icon is-large">
                  <span className="fa-lg">
                    <i className={`fas fa-check-circle fa-2x ${statusClass}`} />
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              id="verifyButton"
              disabled={state.code.length !== 4}
              className={`button is-centered has-text-primary is-size-5 ${state.loading ? 'is-loading' : ''}`}
              onClick={verify}
            >
              {t('signup:verify')}
            </button>
          </div>
        </div>
        {!signupState.validationFail && (
          <div className="column is-12 is-size-5 mt-5 has-text-grey">
            <p className="has-text-centered">
              {t('common:resend_text_1')}
              <br />
              <a
                href="/#"
                id="resend"
                className="has-text-white is-size-5"
                style={{ textDecoration: 'underline', fontWeight: 800 }}
                onClick={e => resendCode(e, 'email')}
                ref={emailButtonRef}
              >
                EMAIL
              </a>
              {' '}
              {t('common:or')}
              {' '}
              <a
                href="/#"
                id="request-code"
                className="has-text-white is-size-5"
                style={{ textDecoration: 'underline', fontWeight: 800 }}
                onClick={e => resendCode(e, 'mobile')}
                ref={smsButtonRef}
              >
                SMS
              </a>
              .
              <br />
              <small className="resend-info">
                (
                {t('common:verification_codes_only_sent_once')}
                )
              </small>
              <br />
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SignupFourthStep;
