import React from 'react';
import PropTypes from 'prop-types';
import TournamentWidget from './TournamentWidget';

const TournamentsWidgetList = ({
  cookieValues,
  active,
  items,
  games,
  onSignupAction,
  menuID,
}) => (
  <>
    {items.map(item => (
      <TournamentWidget
        key={item.id}
        cookieValues={cookieValues}
        active={active}
        id={item.id}
        name={item.name}
        description={item.description}
        price={item.price}
        start={item.starts_at}
        end={item.ends_at}
        players={item.players}
        games={item.games}
        allGames={games}
        onSignupAction={onSignupAction}
        menuID={menuID}
        omegaID={item.omega_id}
      />
    ))}
  </>
);

TournamentsWidgetList.propTypes = {
  cookieValues: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  active: PropTypes.string,
  items: PropTypes.instanceOf(Array),
  games: PropTypes.instanceOf(Array),
  onSignupAction: PropTypes.func.isRequired,
  menuID: PropTypes.func.isRequired,
};

TournamentsWidgetList.defaultProps = {
  cookieValues: '',
  active: '',
  items: [],
  games: [],
};

export default TournamentsWidgetList;
