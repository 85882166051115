/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import BasePage from '../Base';
import BackgroundImage from '../../Layout/BackgroundImage';
import GameThumbnailList from '../../Game/GameThumbnailList';
import useGames from '../../../hooks/games/useGames';
import useActiveGames from '../../../hooks/games/useActiveGames';

const BodySection = ({ children }) => (
  <section className="section filtered-game-section" style={{ paddingBottom: '10px' }}>
    <div className="container is-fluid">
      <div className="columns is-multiline is-centered">
        <div className="column is-8">
          <div className="content typography">
            { children }
          </div>
        </div>
      </div>
    </div>
  </section>
);

BodySection.propTypes = {
  children: PropTypes.node.isRequired,
};

const FilteredGamePageBody = ({
  data: {
    title,
    description,
    meta: {
      show_title,
    },
  },
  children,
}) => (
  <>
    { children }
    <BodySection>
      {show_title && <h1 className="has-text-centered">{ title }</h1>}
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </BodySection>
  </>
);

FilteredGamePageBody.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      show_title: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  children: PropTypes.node,
};

FilteredGamePageBody.defaultProps = {
  children: undefined,
};

export const FilteredGamePage = (props) => {
  const { t, data } = props;

  const {
    isLoading, gamesByCategories, gamesByProviders, countryPreview,
  } = useGames();
  const { activeCategory, activeProvider } = useActiveGames();

  if (isLoading) return null;

  const selectedGamesByCategory = activeCategory
    ? gamesByCategories[activeCategory?.id]
    : gamesByProviders[activeProvider?.id];

  return (
    <BasePage data={data}>
      {data.banner ? (
        <BackgroundImage
          src={data.banner.meta.download_url}
          elementClass="carousel m-carousel m-carousel-accounts"
        >
          <div className="container is-fluid" />
        </BackgroundImage>
      ) : (
        <hr className="gamesline" />
      )}
      <FilteredGamePageBody data={data}>
        {selectedGamesByCategory?.id && (
          <GameThumbnailList category={selectedGamesByCategory} />
        )}
        {!selectedGamesByCategory?.id && countryPreview && (
          <section className="row container is-fluid py-4">
            <h2
              className="is-size-4 has-text-weight-bold has-text-primary container has-text-centered mt-10-games"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('common:no_games_found_with_contact'),
              }}
            />
          </section>
        )}
      </FilteredGamePageBody>
    </BasePage>
  );
};

FilteredGamePage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation(), withRouter)(FilteredGamePage);
