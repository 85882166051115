/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Casino from '@dialinvest/react-casino';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSignupContext } from './context';
import SignupState from './SignupState';

const SignupTermsPolicy = ({ link }) => {
  const [state, setState] = useState({
    body: '',
    loading: false,
  });
  const {
    setSignupStep,
  } = useSignupContext();
  const { i18n, t } = useTranslation();

  const fetchData = async () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));
    const content = await new Casino.FetchPage(`${i18n.language}/${t(`common:${link}`)}/`).perform();
    if (content.found()) {
      const { body } = content.data;

      setState(prevState => ({
        ...prevState,
        loading: false,
        body,
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="columns is-centered">
        {state.loading && (
          <img src={`${process.env.PUBLIC_URL}/images/preloader.png`} alt="Spinnalot" />
        )}
        {!state.loading && (
          <div
            className={`column is-8 content ${state.loading ? 'is-loading' : ''}`}
            dangerouslySetInnerHTML={{ __html: state.body }}
          />
        )}
      </div>
      <div className="buttons">
        <button
          id="AcceptButton"
          type="button"
          className="button is-centered has-text-primary is-size-5"
          onClick={() => setSignupStep(SignupState.consent)}
        >
          Accept
        </button>
      </div>
    </>
  );
};

SignupTermsPolicy.propTypes = {
  link: PropTypes.string,
};

SignupTermsPolicy.defaultProps = {
  link: 'term_conditions_link',
};

export default SignupTermsPolicy;
