import React from 'react';
import PropTypes from 'prop-types';

const CarouselItem = ({ url }) => (
  <div className="m-logo-carousel__logo">
    <img src={url} alt="" />
  </div>
);

CarouselItem.propTypes = {
  url: PropTypes.string.isRequired,
};

export default CarouselItem;
