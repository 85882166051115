import React from 'react';
import PropTypes from 'prop-types';

const PlayersWidget = ({ optedPlayers, currentPlayer }) => (
  <table className="table table is-fullwidth">
    <tbody>
      {optedPlayers?.map((player, i) => (
        <tr key={player.userid} className={`has-text-right ${player.userid === currentPlayer.toLowerCase() ? 'is-active' : ''}`}>
          <td className="m-user-rank">{i + 1}</td>
          <td className="m-user-name">{player.userid}</td>
          <td className="m-user-score">{player.points}</td>
        </tr>
        )
      )}
    </tbody>
  </table>
);

PlayersWidget.propTypes = {
  optedPlayers: PropTypes.instanceOf(Array),
  currentPlayer: PropTypes.string.isRequired,
};

PlayersWidget.defaultProps = {
  optedPlayers: [],
};

export default PlayersWidget;
