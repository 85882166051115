import React from 'react';
import PropTypes from 'prop-types';

const WidgetThumbnail = ({ thumbnail, alt }) => (
  <div className="level-left">
    <img width="88px" src={(thumbnail && thumbnail.meta !== undefined) ? thumbnail.meta.download_url : 'https://placehold.it/88x72'} alt={alt} />
  </div>
);


WidgetThumbnail.propTypes = {
  thumbnail: PropTypes.instanceOf(Object),
  alt: PropTypes.string.isRequired,
};

WidgetThumbnail.defaultProps = {
  thumbnail: [{
    meta: {
      download_url: '',
    },
  }],
};

export default WidgetThumbnail;
