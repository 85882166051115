import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { clipHTMLBody } from '../../Helpers';

class FeaturedContent extends PureComponent {
  constructor(props) {
    super(props);

    gsap.registerPlugin(ScrollTrigger);
  }

  componentDidMount() {
    this.coinAnimation();
  }

  handleSignup(event) {
    const { onToggleModal } = this.props;
    event.preventDefault();
    onToggleModal();
    clipHTMLBody();
  }

  coinAnimation = () => {
    gsap.set('.m-landing-page-features-coins', {
      y: -1400,
    });

    gsap.to('.m-landing-page-features-coins', {
      scrollTrigger: {
        trigger: '.m-landing-page-features-coins',
        scrub: true,
        start: 'top center',
        end: 'bottom top',
      },
      y: window.innerWidth < 450 ? -20 : 50,
      duration: 5,
      ease: 'Power0.easeNone',
    });
  }

  render() {
    const {
      secondContentTitle, secondContent, t, featuredImage,
    } = this.props;

    return (
      <div className="m-landing-page-features has-text-centered content section" style={{ backgroundImage: `url(${featuredImage})` }}>
        <div className="m-landing-page-features-coins">
          <img src="/images/coins.png" alt="coins" />
        </div>
        <h2 className="m-landing-page-features__title is-uppercase has-text-centered has-text-white has-text-weight-semibold mt-3">
          {secondContentTitle}
        </h2>
        <div className="has-text-centered mb-6">
          <p className="is-size-6 is-size-4-tablet is-size-3-desktop has-text-weight-semibold" dangerouslySetInnerHTML={{ __html: secondContent }} />
        </div>
        <button
          type="button"
          data-modal-target="modal-signup"
          id="modal-signup-button"
          className="button is-primary is-rounded is-size-4-tablet is-size-3-desktop"
          onClick={e => this.handleSignup(e)}
        >
          { t('buttons:sign_up') }
        </button>
      </div>
    );
  }
}

FeaturedContent.propTypes = {
  t: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  secondContentTitle: PropTypes.string.isRequired,
  secondContent: PropTypes.string.isRequired,
  featuredImage: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  onToggleModal: () => dispatch({ type: 'TOGGLE_MODAL' }),
});

export default compose(
  connect(null, mapDispatchToProps),
)(FeaturedContent);
