import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { getLocalTime } from '../../Helpers';
import { useTranslation } from 'react-i18next';

const TimeWidget = ({
  start,
  end,
  menuID,
  active,
  id,
}) => {
  const { i18n, t } = useTranslation();

  const calculateTimeLeft = () => {
    const now = new Date();
    let difference;
    if (menuID === '1') {
      active = id;
    }
    if (menuID === '2') {
      difference = +new Date(start) - +new Date(now);
    } else {
      difference = +new Date(end) - +new Date(now);
    }

    if (difference > 0) {
      return {
        [t('common:tournament_days')]: Math.floor(difference / (1000 * 60 * 60 * 24)),
        [t('common:tournament_hours')]: Math.floor((difference / (1000 * 60 * 60)) % 24),
        [t('common:tournament_minutes')]: Math.floor((difference / 1000 / 60) % 60),
        [t('common:tournament_seconds')]: Math.floor((difference / 1000) % 60),
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState({});
  const localStartTime = getLocalTime(start, i18n.language);
  const localEndTime = getLocalTime(end, i18n.language);
  const timerComponents = [];

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  if (timeLeft) {
    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span>
          {timeLeft[interval]}
          {' '}
          {interval}
          {' '}
        </span>
      );
    });
  }


  return (
    <>
      <div className="table-container">
        <table className="table m-0 has-text-weight-bold">
          <tr className="is-flex is-size-6-mobile is-size-6">
            <td>
              {menuID === '2'
                ? t('common:tournament_starts_on')
                : t('common:tournament_started')
              }
            </td>
            {menuID === '2'
              ? <td className="m-end-date">
                {timerComponents.length ? timerComponents : t('common:tournament_time_is_up')}
              </td>
              : <td className="m-start-date is-size-6-mobile is-size-6 has-text-right">
                {localStartTime}
              </td>
            }
          </tr>
          <tr className="is-flex is-size-6-mobile is-size-6">
            <td>
              {menuID === '3'
                ? t('common:tournament_ended')
                : t('common:tournament_ends_in')
              }
            </td>
            {menuID === '2' || menuID === '3'
              ? <td className="m-start-date is-size-6-mobile is-size-6 has-text-right">
                {localEndTime}
              </td>
              : <td className="m-end-date">
                {timerComponents.length ? timerComponents : ('common:tournament_time_is_up')}
              </td>
            }
          </tr>
        </table>
      </div>
    </>
  );
};

TimeWidget.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  menuID: PropTypes.string.isRequired,

};

export default TimeWidget;
