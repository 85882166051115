/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, initialize } from 'redux-form';
import { InputComponent, PhoneNumberComponent } from './inputs';
import store from '../../../containers/App/store';
import { useSignupContext } from './context';
import validate, { asyncValidate } from '../../../containers/utitilies/validator_signup_second_step';

const SignupSecondStep = (props) => {
  const { t } = useTranslation();

  const {
    setField, signupState, getDialCode, onClickNextStep, onClickPrevStep,
  } = useSignupContext();
  const { invalid, asyncValidating } = props;

  useEffect(() => {
    const { country } = signupState.fields;
    if (country) {
      const countryInfo = getDialCode(country);
      const mobile = countryInfo.length > 0 ? countryInfo[0].dial_code : '';

      setField({
        target: {
          name: 'mobile',
          value: signupState.fields.mobile || mobile,
        },
      });
    }
  }, [signupState.fields.country]);

  useEffect(() => {
    store.dispatch(initialize('SignupSecondStepForm', signupState.fields));
  }, []);

  const disabled = (
    (signupState.fields.gender)
    && !invalid && signupState.fields.mobile?.length > 4 && !asyncValidating
  );

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-8">
          <div className="field-body">
            <div className="field">
              <label className="label is-size-5">{t('signup:first_name')}</label>
              <div className="control">
                <Field
                  className="input is-medium"
                  type="text"
                  name="firstName"
                  onChange={setField}
                  component={InputComponent}
                />
              </div>
            </div>
            <div className="field">
              <label className="label is-size-5">{t('signup:last_name')}</label>
              <div className="control">
                <Field
                  className="input is-medium"
                  type="text"
                  name="lastName"
                  onChange={setField}
                  component={InputComponent}
                />
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label is-size-5">{t('signup:gender')}</label>
            <div className="control">
              <div className="m-input-conditional">
                <label className="radio">
                  <input
                    type="radio"
                    name="gender"
                    value="M"
                    checked={signupState.fields.gender === 'M'}
                    onChange={setField}
                  />
                  <span className="m-input-conditional-item" />
                  <span className="m-input-conditional-name">{t('signup:male')}</span>
                </label>
                <div className="m-input-conditional-or">
                  OR
                </div>
                <label className="radio">
                  <input
                    type="radio"
                    name="gender"
                    value="F"
                    checked={signupState.fields.gender === 'F'}
                    onChange={setField}
                  />
                  <span className="m-input-conditional-item" />
                  <span className="m-input-conditional-name">{t('signup:female')}</span>
                </label>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label is-size-5">{t('signup:country')}</label>
            <div className="control">
              <div className="select is-medium">
                <select name="country" id="registration_country" onChange={setField} required="required">
                  {signupState?.countriesItems.map(item => (
                    <option
                      key={item.value}
                      value={item.value}
                      selected={signupState.fields.country === item.value}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label is-size-5">{t('signup:phone_number')}</label>
            <div className="control has-icons-right">
              <Field
                className="input is-medium"
                type="tel"
                name="mobile"
                onChange={setField}
                fieldValue={signupState.fields.mobile}
                component={PhoneNumberComponent}
              />
            </div>
          </div>
          <div className="field">
            <label className="label is-size-5">{t('signup:currency')}</label>
            <div className="control">
              <div className="select is-medium">
                <select name="currency" id="registration_currency" onChange={setField} required="required">
                  {signupState?.currencyItems.map(item => (
                    <option
                      key={item.value}
                      value={item.value}
                      selected={signupState.fields.currency === item.value}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buttons">
        <button
          type="button"
          onClick={onClickPrevStep}
          className="button is-centered has-text-primary is-size-5"
        >
          {t('signup:previous')}
        </button>
        <button
          disabled={!disabled}
          type="button"
          onClick={onClickNextStep}
          className="button is-centered has-text-primary is-size-5"
        >
          {t('signup:continue')}
        </button>
      </div>
    </>
  );
};

SignupSecondStep.propTypes = {
  invalid: PropTypes.bool.isRequired,
  asyncValidating: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

SignupSecondStep.defaultProps = {
  asyncValidating: false || '',
};

export default reduxForm({
  form: 'SignupSecondStepForm',
  validate,
  asyncValidate,
  asyncChangeFields: ['mobile'],
})(SignupSecondStep);
