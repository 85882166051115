import React from 'react';


const Loader = () => (
		<div className="m-tournament-loader wrapper mb-4">
			<div className="item header">
				<div className="animated-background" />
				<div className="item header__body">
					<div className="animated-background" />
				</div>
			</div>
			<div className="item body">
				<div className="item body__title">
					<div className="animated-background" />
				</div>
				<div className="item body__wrapper">
					<div className="body__left">
						<div className="animated-background" />
					</div>
					<div className="body__right">
						<div className="animated-background" />
					</div>
				</div>
			</div>
		</div>
);

export default Loader;
