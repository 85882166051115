/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSignupContext } from './context';

const SignupConsentStep = (props) => {
  const {
    setSignupState,
    signupState,
    signUp,
    isLoading,
    onClickPrevStep,
  } = useSignupContext();
  const { t, i18n } = useTranslation('signup');

  const { valid: fieldsValid, signupForm } = props;

  const onChange = (event) => {
    const { name } = event.target;
    setSignupState(prevState => ({
      ...prevState,
      consent: {
        ...prevState.consent,
        [name]: {
          ...prevState.consent[name],
          value: !prevState.consent[name].value,
        },
      },
    }));
  };

  const valid = (
    signupState.consent.consentTerms.value
    && signupState.consent.consentPrivacy.value
    && fieldsValid
  );

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-8">
          {signupState.step === 3 && (
            <h2 className="has-text-primary has-text-centered is-size-5-mobile is-size-3-tablet mb-3">
              {t('signup:agree_to_following')}
            </h2>
          )}
          <div className="field m-consent-switch">
            <input
              id="consentTerms"
              type="checkbox"
              name="consentTerms"
              checked={signupState.consent.consentTerms.value}
              onChange={onChange}
              className="switch is-large is-rounded is-success"
            />
            <label data-on-text="ON" data-off-text="OFF" id="consentTermsLabel" htmlFor="consentTerms">
              <span className="is-size-6">
                {t('have_read_and_acepted')}
                <a
                  href={`/${i18n.language}${t('common:term_conditions_link')}`}
                  id="termsConditionsButton"
                  className="has-text-underline has-text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('terms_conditions')}
                </a>
                {t('confirm_18_old')}
              </span>
              <i className="fas fa-lock" />
            </label>
          </div>
          <div className="field m-consent-switch">
            <input
              id="consentPrivacy"
              type="checkbox"
              name="consentPrivacy"
              checked={signupState.consent.consentPrivacy.value}
              onChange={onChange}
              className="switch is-large is-rounded is-success"
            />
            <label data-on-text="ON" data-off-text="OFF" id="consentPrivacyLabel" htmlFor="consentPrivacy">
              <span className="is-size-6">
                {t('handling_personal_data')}
                <a
                  href={`/${i18n.language}${t('common:privacy_policy_link')}`}
                  id="privacyPolicyButton"
                  className="has-text-underline has-text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('privacy_policy')}
                </a>
                {t(' and ')}
                <a
                  href={`/${i18n.language}${t('common:cookie_policy_link')}`}
                  id="cookiePolicyButton"
                  className="has-text-underline has-text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('cookie_policy')}
                </a>
              </span>
              <i className="fas fa-lock" />
            </label>
          </div>
          <div className="field m-consent-switch">
            <input
              id="consentPromotionsEMAIL"
              type="checkbox"
              name="consentPromotionsEMAIL"
              checked={signupState.consent.consentPromotionsEMAIL.value}
              onChange={onChange}
              className="switch is-large is-rounded is-success"
            />
            <label data-on-text="ON" data-off-text="OFF" id="consentPromotionsEMAILLabel" htmlFor="consentPromotionsEMAIL">
              <span className="is-size-6">{t('receiving_bonus_offers')}</span>
              <i className="fas" />
            </label>
          </div>
        </div>
      </div>
      <div className="buttons">
        {signupForm && (
        <button
          type="button"
          onClick={onClickPrevStep}
          className="button is-centered has-text-primary is-size-5"
        >
          {t('signup:previous')}
        </button>
        )}
        <button
          type="button"
          id="setSignupStepButton"
          disabled={!valid}
          onClick={signUp}
          className={`button is-centered has-text-primary is-size-5 ${isLoading ? 'is-loading' : ''}`}
        >
          {t('i_confirm')}
        </button>
      </div>
    </>
  );
};

SignupConsentStep.propTypes = {
  signupForm: PropTypes.bool,
  valid: PropTypes.bool,
};

SignupConsentStep.defaultProps = {
  signupForm: null,
  valid: false,
};

export default SignupConsentStep;
