import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GameThumbnail from '../GameThumbnail';
import GameLoadMoreButton from '../GameLoadMoreButton';
import FeaturedGameThumbnails from './FeaturedGameThumbnails';
import useQuery from '../../../hooks/useQuery';
import { getDisplayName } from '../../../redux/actions/gamesActions/GamesActionHelpers';
import PromoBlock from '../../PromoBlock';

const GameThumbnailList = ({
  category, maxLimit, index, promoBlocks,
}) => {
  const { t } = useTranslation();
  const { query } = useQuery();
  const searchText = query.get('search');

  const {
    id: categoryId,
    games,
    name,
    displayGamesCount,
    maxLimit: categoryMaxLimit,
    count,
    totalCount,
    isProvider,
    loadAllGames,
    nextUrl,
    type,
    display_name,
  } = category;

  const gameThumbnails = games?.slice(0, maxLimit)
    .map(item => (
      <GameThumbnail
        key={item.id}
        {...item}
      />
    ));

  if (!category.games?.length) {
    return (
      <SectionWrapper>
        <h2 className="is-size-3 has-text-weight-bold has-text-primary has-text-centered container mt-10-games">
          {t('common:no_games_found')}
        </h2>
        <FeaturedGameThumbnails />
      </SectionWrapper>
    );
  }

  return (
    <>
      <SectionWrapper>
        <h2
          className={`is-size-3 has-text-weight-bold has-text-primary is-uppercase container has-text-centered ${
            searchText ? 'mt-10-games' : ''
          }`}
        >
          {display_name || getDisplayName(name)}
        </h2>
        <div
          id="game-grid"
          className="m-game-grid columns is-multiline is-mobile parent-grid"
        >
          {gameThumbnails}
        </div>
        <GameLoadMoreButton
          categoryId={categoryId}
          count={count}
          totalCount={totalCount}
          maxLimit={categoryMaxLimit}
          displayGamesCount={displayGamesCount}
          isProvider={isProvider}
          loadAllGames={loadAllGames}
          nextUrl={nextUrl}
          type={type}
        />
      </SectionWrapper>
      {promoBlocks && promoBlocks[index]?.id && (
        <PromoBlock item={promoBlocks[index]} />
      )}
    </>
  );
};

GameThumbnailList.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    games: PropTypes.instanceOf(Array),
    name: PropTypes.string,
    loadAllGames: PropTypes.bool,
    displayGamesCount: PropTypes.number,
    moreGamesToDisplay: PropTypes.number,
    count: PropTypes.number,
    totalCount: PropTypes.number,
    isProvider: PropTypes.bool,
    nextUrl: PropTypes.string,
    maxLimit: PropTypes.number,
    categoryName: PropTypes.string,
    type: PropTypes.string,
  }),
  maxLimit: PropTypes.number,
  index: PropTypes.number,
  promoBlocks: PropTypes.instanceOf(Object),
};

GameThumbnailList.defaultProps = {
  category: {
    id: undefined,
    games: undefined,
    name: undefined,
    loadAllGames: undefined,
    displayGamesCount: undefined,
    moreGamesToDisplay: undefined,
    count: undefined,
    totalCount: undefined,
    isProvider: undefined,
    nextUrl: undefined,
    maxLimit: undefined,
    type: undefined,
  },
  maxLimit: undefined,
  index: undefined,
  promoBlocks: undefined,
};

export const SectionWrapper = ({ children }) => (
  <section className="row container is-fluid m-game-grid__container">
    {children}
  </section>
);

SectionWrapper.propTypes = {
  children: React.Component,
};

SectionWrapper.defaultProps = {
  children: undefined,
};

export default GameThumbnailList;
