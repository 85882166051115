import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const GamesWidget = ({
  id,
  allGames,
}) => {
  const { t, i18n } = useTranslation();
  const image = 'http://placehold.it/450x300';

  const filteredGames = allGames.filter(game => game.id === id);
  const slug = filteredGames[0] !== undefined ? filteredGames[0].slug : '';
  const supportsDemoPlay = filteredGames[0] !== undefined ? filteredGames[0].supports_demo_play : false;
  const srcImage = filteredGames[0] !== undefined ? filteredGames[0].thumbnail.url : image;
  const status = filteredGames[0] !== undefined ? filteredGames[0].status : false;
  const tags = filteredGames[0] !== undefined ? filteredGames[0].tags : '';

  const isNew = tags.includes('new');

  const createUrl = demoPlay => ({
    pathname: `/${i18n.language}/game/${slug}/`,
    search: !demoPlay ? '?mode=real' : '',
    state: {
      supportsDemoPlay,
    },
  });

  const GameNavLink = () => (
    <>
      <Link key={`${slug}real`} to={createUrl(false)} className="button is-success">
        <i className="icon-play-real" />
        { t('buttons:play_for_real') }
      </Link>
      {supportsDemoPlay
      && (
        <Link key={`${slug}free`} to={createUrl(true)} className="button is-info">
          <i className="icon-play-fun" />
          { t('buttons:play_for_free') }
        </Link>
      )}
    </>
  );

  const GameMaintenance = () => (
    <div className="button is-danger is-maintenance">
      <i className="icon-maintenance is-size-3" />
      <br />
      <p>{ t('buttons:under_maintenance') }</p>
    </div>
  );

  return (
    <>
      <div>
        <img src={srcImage} alt="" />
        {isNew
        && <div className="m-game-grid-item__label">{ t('common:new') }</div>}
        <div className="m-game-grid-item__shadow" />
        <div className="m-game-grid-buttons is-flex">
          {status === 'enabled' ? GameNavLink() : GameMaintenance()}
        </div>
      </div>
    </>
  )
};

GamesWidget.propTypes = {
  allGames: PropTypes.instanceOf(Array),
  id: PropTypes.number,
};

GamesWidget.defaultProps = {
  allGames: [],
  id: null,
};

export default GamesWidget;
