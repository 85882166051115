import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  formatLocalizedDate,
  getPathname,
  stripHtml,
  truncate,
} from '../../Helpers';
import WidgetThumbnail from '../WidgetThumbnail';

const BlogWidgetItem = ({ data }) => {
  const excerpt = data.excerpt ? stripHtml(data.excerpt) : truncate(stripHtml(data.body), 20);
  let titleClass = 'is-size-6';

  if (excerpt.length < 31 && excerpt.length > 22) titleClass = 'is-size-5';
  if (excerpt.length < 23) titleClass = 'is-size-4';

  return (
    <div className="m-promo-calendar-widget-item column is-12-mobile is-6-tablet is-12-desktop">
      <NavLink title={data.title} to={getPathname(data.meta.html_url)} className="level is-mobile m-promo-widget-item">
        <WidgetThumbnail alt={truncate(data.title, 20)} thumbnail={data.thumbnail} />
        <div className="level-item">
          <div className="m-promo-widget-item-text has-text-left">
            <div className="is-size-7">
              <div className="m-promo-widget-item-meta level is-12-mobile">
                <div className="level-left item-title has-text-white">
                  {data.title}
                </div>
                <div className="level-right item-date has-text-white">
                  {formatLocalizedDate(data.meta.first_published_at, 'D MMM [\']YY')}
                </div>
              </div>
            </div>
            <h3 className={`has-text-white m-promo-widget-title ${titleClass}`}>
              {excerpt}
            </h3>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

BlogWidgetItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default BlogWidgetItem;
