import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import { unClipHTMLBody } from '../../Helpers';
import { toggleModal } from '../../../redux/actions/signUpModalActions';
import { toggleModalGeneralError } from '../../../redux/actions/generalErrorModalActions';

export class ErrorModal extends PureComponent {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);
  };

  closeModal = () => {
    const { props } = this;

    if (props.isOpenSignUp) props.onToggleModal();
    if (props.signUpFrom === 'signin') {
      props.history.push('/');
    }

    props.toggleModalError();
    unClipHTMLBody();
  };

  toggleModal = (e) => {
    e.preventDefault();
    this.closeModal();
  };

  escFunction = (event) => {
    const { props } = this;

    if (event.keyCode === 27 && props.isOpen) {
      this.closeModal();
    }
  };

  render() {
    const { t, isOpen, message } = this.props;

    return (
      <div id="modal-error" className={`modal m-modal-base modal-fx-superScaled ${isOpen ? 'is-active' : 'is-inactive'}`}>
        <div className="modal-background" />
        <div className="modal-card modal-content">
          <header className="modal-card-head">
            <div className="modal-card-title">
              <h2 className="is-size-3 is-size-5-mobile has-text-white has-text-centered has-text-weight-semibold">
                {t('common:error_title')}
              </h2>
            </div>
            <button
              id="delete"
              className="delete"
              aria-label="close"
              type="button"
              onClick={e => this.toggleModal(e)}
            />
          </header>
          <section className="modal-card-body">
            <div className="notification is-danger has-text-white">
              <div className="media">
                <div className="media-content has-text-centered">
                  <i className="fas fa-exclamation-circle fa-3x" />
                  <br />
                  <strong className="has-text-white is-size-4">
                    <p dangerouslySetInnerHTML={{ __html: message || t('common:error_mail_body') }} />
                  </strong>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <div className="columns is-centered is-full-width">
              <div className="column is-4-tablet is-hidden-mobile">
                <button
                  id="close"
                  type="button"
                  className="button button-modal-close has-background-light is-medium is-full-width"
                  onClick={e => this.toggleModal(e)}
                >
                  <i className="icon-close" />
                  &nbsp;&nbsp;
                  {t('buttons:close')}
                  &nbsp;&nbsp;
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

ErrorModal.propTypes = {
  toggleModalError: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenSignUp: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  message: PropTypes.string,
  signUpFrom: PropTypes.string,
};

ErrorModal.defaultProps = {
  message: null,
  signUpFrom: null,
};

export const mapStateToProps = state => ({
  isOpen: state.generalErrorModal?.isOpen,
  isOpenSignUp: state.signUpModal?.isOpen,
  signUpFrom: state.signUpModal?.from,
  message: state.generalErrorModal?.message,
});

export const mapDispatchToProps = dispatch => ({
  toggleModalError: () => dispatch(toggleModalGeneralError()),
  onToggleModal: () => dispatch(toggleModal()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(ErrorModal);
