/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Casino from '@dialinvest/react-casino';
import {
  apiCall,
  formatWhammies,
  getSession,
  getQueryParam,
} from '../../Helpers';

const AccountLoyaltyPointsWrapper = ({ children, disableGeneralError }) => {
  const [currentLoyaltyPoint, setCurrentLoyaltyPoint] = useState('0');
  const refresh = useSelector(state => state.balance.refresh);
  const [currentVIPLevel, setCurrentVIPLevel] = useState('');
  const [imageName, setImageName] = useState('');
  const [levelText, setLevelText] = useState('');
  const [totalPointsForNextLevel, setTotalPointsForNextLevel] = useState('0');
  const [accumulatedLoyaltyPoint, setAccumulatedLoyaltyPoint] = useState('0');
  const [remainingPointsForNextLevel, setRemainingPointsForNextLevel] = useState('0');
  const account = new Casino.models.Account();
  const isUserRestricted = !!getQueryParam('restricted', window.location.search);

  const fetchContent = async () => {
    const { sessionKey } = getSession();

    const parameters = {
      sessionKey,
    };

    const response = (!disableGeneralError)
      ? await apiCall(account.loyaltyPointsStatus(parameters))
      : await account.loyaltyPointsStatus(parameters);

    function setLevelValues(currentLv) {
      switch (currentLv) {
        case 'Astro_Apprentice':
          return { name: 'Astro Apprentice', imageName: 'icon-level-1.svg', levelText: 'account:level_1_text' };
        case 'Shooting_Star':
          return { name: 'Shooting Star', imageName: 'icon-level-2.svg', levelText: 'account:level_2_text' };
        case 'Moon_Mogul':
          return { name: 'Moon Mogul', imageName: 'icon-level-3.svg', levelText: 'account:level_3_text' };
        case 'Planet_Pioneer':
          return { name: 'Planet Pioneer', imageName: 'icon-level-4.svg', levelText: 'account:level_4_text' };
        case 'Galaxy_Governor':
          return { name: 'Galaxy Governor', imageName: 'icon-level-5.svg', levelText: 'account:level_5_text' };
        case 'Ruler_of_the_Universe':
          return { name: 'Ruler of the Universe', imageName: 'icon-level-6.svg', levelText: 'account:level_6_text' };
        default:
          return { name: 'Astro Apprentice', imageName: 'icon-level-1.svg', levelText: 'account:level_1_text' };
      }
    }

    if (response.success()) {
      const levelValues = setLevelValues(response.data.currentVIPLevel);
      setCurrentLoyaltyPoint(response.data.currentLoyaltyPoint);
      setCurrentVIPLevel(levelValues.name);
      setImageName(levelValues.imageName);
      setLevelText(levelValues.levelText);
      setTotalPointsForNextLevel(response.data.totalPointsForNextLevel);
      setAccumulatedLoyaltyPoint(response.data.accumulatedLoyaltyPoint);
      setRemainingPointsForNextLevel(response.data.remainingPointsForNextLevel);
    }
  };

  useEffect(() => {
    if (isUserRestricted) return;

    fetchContent();
    const interval = setInterval(() => { fetchContent(); }, 30000);
    return () => clearInterval(interval);
  }, [refresh]);

  if (isUserRestricted) {
    return null;
  }

  return children({
    currentLoyaltyPoint: formatWhammies(currentLoyaltyPoint),
    currentVIPLevel,
    imageName,
    levelText,
    totalPointsForNextLevel,
    accumulatedLoyaltyPoint: formatWhammies(accumulatedLoyaltyPoint),
    remainingPointsForNextLevel,
  });
};

AccountLoyaltyPointsWrapper.propTypes = {
  disableGeneralError: PropTypes.bool,
};

AccountLoyaltyPointsWrapper.defaultProps = {
  disableGeneralError: true,
};

export default AccountLoyaltyPointsWrapper;
