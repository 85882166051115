import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { handleClickOutside, callCashierModal } from '../../Helpers';

export const AccountMobileSubMenu = ({ renderedItems }) => {
  const [isActive, setIsActive] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    handleClickOutside(menuRef, () => setIsActive(false));
  }, [menuRef]);

  return (
    <>
      <div className="is-flex" ref={menuRef}>
        <button
          onClick={() => setIsActive(!isActive)}
          type="button"
          className={`m-game-nav-button is-flex-touch is-hidden-desktop ${isActive ? ' is-active' : ''}`}
          aria-label="game-menu-button"
          aria-expanded="false"
          data-menu-target="navbarAccountNav"
          id="navbarAccountMenuButton"
        >
          <div className="is-size-7-mobile is-center" aria-label="game-menu-label">Account Menu</div>
          <div className={`navbar-burger burger is-flex-touch is-static ${isActive ? ' is-active' : ''}`}>
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </button>
        <div
          className={`navbar-menu m-game-nav-menu  ${isActive ? ' is-active' : ''}`}
          id="navbarAccountNav"
        >
          {renderedItems}
        </div>
      </div>
    </>
  );
};

AccountMobileSubMenu.propTypes = {
  renderedItems: PropTypes.instanceOf(Array),
};

AccountMobileSubMenu.defaultProps = {
  renderedItems: [],
};

const AccountHeaderSection = ({ data, subPages }) => {
  let renderedItems;

  if (subPages) {
    let uniqueKey = 0;
    renderedItems = subPages.map((item) => {
      uniqueKey += 1;
      return (
        <NavLink
          key={uniqueKey}
          id={item.title}
          to={new URL(item.meta.html_url).pathname}
          className={`navbar-item ${data.id === item.id ? 'is-active' : ''} `}
          onClick={(item.meta.type === 'content.AccountDeposit') ? e => callCashierModal(e) : () => null}
        >
          {item.title}
        </NavLink>
      );
    });
  }

  return (
    <>
      {
        data.banner
        && (
          <div
            className="carousel m-carousel m-carousel-accounts"
            style={{
              backgroundImage: `url(${data.banner.meta.download_url})`,
            }}
          >
            <div className="container is-fluid" />
          </div>
        )
      }
      <div className="has-background-primary">
        <div className="m-game-nav m-account-nav container is-fluid">
          <div className="level is-mobile">
            <div className="level-left">
              <nav className="navbar" role="navigation" aria-label="game navigation">
                <div className="navbar-start">
                  <AccountMobileSubMenu renderedItems={renderedItems} />
                </div>
              </nav>
            </div>
            <div className="level-right is-flex" />
          </div>
        </div>
      </div>
    </>
  );
};

AccountHeaderSection.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  subPages: PropTypes.instanceOf(Array),
};

AccountHeaderSection.defaultProps = {
  subPages: [],
};

export default AccountHeaderSection;
