/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import Casino from '@dialinvest/react-casino';
import GamesWidget from './GamesWidget';
import PlayersWidget from './PlayersWidget';
import TimeWidget from './TimeWidget';
import {isUserLoggedIn, capitalize, getSession} from '../../Helpers';
import Loader from './Loader';

const TournamentWidget = ({
  active,
  id,
  name,
  description,
  price,
  start,
  end,
  players,
  games,
  allGames,
  onSignupAction,
  menuID,
  omegaID,
  cookieValues,
}) => {
  const { t } = useTranslation();

  const [currentSlide, setTCurrentSlide] = React.useState(0);
  const [currentPlayers, setCurrentPlayers] = React.useState(players);
  const [optedPlayers, setOptedPlayers] = React.useState( []);
  const [currentOptedPlayer, setCurrentOptedPlayer] = React.useState({});
  const [currentActive, setCurrentActive] = React.useState(active);
  const [playerName, setPlayerName] = React.useState(active);
  const [loading, setLoading] = React.useState(true);

  async function fetchTournament() {
    await getAllPlayers();
    await setCookieValue();
  }

  React.useEffect(() => {
    if (isUserLoggedIn()) {
      fetchTournament();
    }
    if (menuID === '1') {
      setCurrentActive(id);
    }
    setLoading(false);
  }, [id, menuID, omegaID]);

  async function getAllPlayers() {
    const { sessionKey } = getSession();
    const id = omegaID;

    const parameters = {
      sessionKey,
      id,
    };
    const result = await new Casino.models.Account().getTournamentRanking(parameters);
    getOptedPlayers(result.data.tournamentRankingList);
    setLoading(false);
  }

  async function setCookieValue() {
    if (document.cookie.indexOf(process.env.REACT_APP_API_COOKIE_NAME) >= 0) {
      cookieValues.username ? setPlayerName(capitalize(cookieValues.username.toLowerCase())) : setPlayerName('');
    }
  }

  function isOptIn() {
    return currentPlayers.filter(player => player.username === playerName);
  }

  function next() {
    if (games.length > 1) {
      setTCurrentSlide(
        currentSlide + 1,
      );
    }
  }

  function prev() {
    if (games.length > 1) {
      setTCurrentSlide(
        currentSlide - 1,
      );
    }
  }

  function updateCurrentSlide(index) {
    if (currentSlide !== index) {
      setTCurrentSlide(
        index,
      );
    }
  }

  function setActive(e, active) {
    e.preventDefault();
    (currentActive === active) ? setCurrentActive('') : setCurrentActive(active);
  }

  function optIn(id) {
    const parameters = {
      username: playerName,
      email: '',
      tournament: id,
    };
    new Casino.models.Tournament().players(parameters).then(async () => {
      const url = `tournaments/?name=${name}`;
      const result = await new Casino.FetchContent(url).perform();
      setCurrentPlayers(result.items()[0].players);
    });
  }

  async function getOptedPlayers(allPlayer) {
    const optedPlayersList = await allPlayer?.filter(async (player) => {
      await players.find((p) => {
        if (player.userid === cookieValues.username) setCurrentOptedPlayer(player);
        return player.userid === p.username.toLowerCase();
      });
    });
    setOptedPlayers(optedPlayersList);
  }

  return loading
    ? <Loader />
    : (
      <section className={`m-tournament-table mb-6 ${currentActive === id ? 'is-open' : 'is-closed'}`}>
        <div className="m-tournament-table-header">
          <div className="level m-tournament-header-title">
            <div className="level-left is-flex">
              <h2
                className="subtitle has-text-centered is-size-5 is-capitalized has-text-yellow has-text-weight-bold">
                {name}
              </h2>
            </div>
            <div className="level-right is-flex is-size-5 is-capitalized has-text-yellow">
              <h1 className="title has-text-centered mr-1 is-size-5 is-capitalized has-text-yellow">
                {price}
              </h1>
              <a
                id="reality_check"
                onClick={e => setActive(e, id)}
                aria-label="toggle"
                href="./#"
              >
                <h2>
                  <i className="level-right fas fa-chevron-up is-size-4 has-text-yellow" />
                </h2>
              </a>
            </div>
          </div>
          <div className="level m-tournament-header-dates">
            <div className="level-left">
              <TimeWidget
                start={start}
                end={end}
                menuID={menuID}
                id={id}
                active={active}
              />
            </div>
            <div className="level-right">
              <div className="m-0 has-text-weight-bold has-text-right" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        </div>

        <div className="m-tournament-table-body py-4">
          <div className="columns">
            <div className="column mx-6">
              <div className="level m-ranking-table-title is-flex">
                <div className="level-left">
                  <h3 className="subtitle is-size-6 has-text-weight-bold">{t('common:tournament_ranking')}</h3>
                </div>
                <div className="level-right m-0">
                </div>
              </div>

              <div className="m-ranking-table-body">
                <div className="m-ranking-table-wrapper">
                  <table className="table table is-fullwidth">
                    <tbody>
                    {isUserLoggedIn()
                      ? <PlayersWidget
                        optedPlayers={optedPlayers}
                        currentPlayer={playerName}
                      />
                      : <h3>{t('common:tournament_please_login')}</h3>
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="column mx-6">
              <div className="m-game-section-title is-flex mb-5">
                <h3 className="subtitle is-size-6 has-text-centered has-text-weight-bold">
                  {games.length}
                  {' '}
                  {games.length > 1 ? t('common:tournament_games_in') : t('common:tournament_game_in')}
                </h3>
              </div>

              <section className={`row m-game-section container is-fluid ${games.length <= 1 ? 'one-game-only' : ''}`}>
                <div className="m-game-section-container">

                  <div style={{ width: '100%' }}>
                    <Carousel
                      autoPlay
                      infiniteLoop
                      selectedItem={currentSlide}
                      onChange={updateCurrentSlide}
                      showArrows={false}
                      showIndicators={false}
                      showStatus={false}
                      interval={3000}
                      showThumbs={false}
                    >

                      {games.map(game => (
                        <GamesWidget
                          id={game}
                          allGames={allGames}
                        />
                      ))}
                    </Carousel>

                  </div>
                </div>

                <div className="m-game-navigation-left">
                  <img src="/images/tournaments_left_arrow.png" alt="left-navigation-arrow" onClick={prev} />
                </div>
                <div className="m-game-navigation-right">
                  <img src="/images/tournaments_right_arrow.png" alt="right-navigation-arrow" onClick={next} />
                </div>

              </section>

              {isUserLoggedIn() && isOptIn().length === 0 && (menuID === '1' || menuID === '2')
              && <button type="button" className="button is-dark-green" onClick={() => optIn(id)}>{t('common:tournament_opt_in_join')}</button>}
              {!isUserLoggedIn() && (menuID === '1' || menuID === '2')
              && <button type="button" className="button is-dark-green" onClick={onSignupAction}>{t('common:tournament_sign_up')}</button>}

              {isUserLoggedIn() && omegaID && currentOptedPlayer.userid && (
                <table className="table table is-fullwidth my-4 current-player">
                  <tbody>
                    <tr className="has-text-right is-active">
                      <td className="m-user-name">
                        {currentOptedPlayer.userid}
                      </td>
                      <td className="m-user-rank">
                        {t('common:tournament_ranking')}
                        &nbsp;
                        {currentOptedPlayer.ranking}
                      </td>
                      <td className="m-user-score">
                        {t('common:tournament_points')}
                        &nbsp;
                        {currentOptedPlayer.points}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

            </div>
          </div>
        </div>
      </section>
    );
};

TournamentWidget.propTypes = {
  cookieValues: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  active: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  players: PropTypes.instanceOf(Array),
  games: PropTypes.instanceOf(Array),
  allGames: PropTypes.instanceOf(Array),
  onSignupAction: PropTypes.func.isRequired,
  menuID: PropTypes.func.isRequired,
  omegaID: PropTypes.string,
};

TournamentWidget.defaultProps = {
  cookieValues: [],
  active: '',
  id: '',
  name: '',
  description: '',
  price: '',
  start: '',
  end: '',
  players: [],
  games: [],
  allGames: [],
  omegaID: '',
};

export default TournamentWidget;
