import React from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm, Field, initialize } from 'redux-form';
import { useSelector } from 'react-redux';
import validate from '../../../containers/utitilies/validator_resend_confirmation';
import { useSignupContext } from './context';
import { InputComponent } from './inputs';
import CustomButton from '../../Layout/CustomButton';
import store from '../../../containers/App/store';

const SignupResendVerificationCodeStep = () => {
  const { t } = useTranslation();
  const {
    setField, resendVerificationCode, setSignupState, isLoading, signupState,
  } = useSignupContext();

  const signupDetails = useSelector(state => state.signUpModal);

  React.useEffect(() => {
    store.dispatch(initialize('SignupResendVerificationStepStepForm', {
      email: signupDetails?.email,
    }));

    setSignupState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        password: signupDetails.password,
      },
    }));
  }, [signupDetails, setSignupState]);

  return (
    <div className="columns is-mobile is-centered mt-5">
      <div className="column is-8-tablet is-11-mobile">
        {signupState.error && !signupState.showExtraMessage && (
          <div id="errorMessage" className="notification content is-danger">
            {signupState.error.replace('username', 'email')}
          </div>
        )}
        <div className="field">
          <label htmlFor="email" className="label is-size-5">{t('signup:email_address')}</label>
          <div className="control has-icons-right">
            <Field
              className={`input is-medium ${signupState.error ? 'is-danger' : ''}`}
              type="search"
              id="email"
              name="email"
              onChange={setField}
              component={InputComponent}
              errorMsg={signupState.error}
            />
          </div>
          <p className="label pt-1"><small>{t('signup:sent_via_sms')}</small></p>
        </div>
        <CustomButton
          htmlClass={`resendCodeBtn is-success is-medium is-full-width py-4 ${isLoading ? 'is-loading' : ''}`}
          title={t('buttons:resendConfirmation')}
          onClick={e => resendVerificationCode(e, 'mobile')}
          linkTo="#"
        >
          <span className="icon is-small">
            <i className="fas fa-arrow-right" />
          </span>
        </CustomButton>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'SignupResendVerificationStepStepForm',
  validate,
})(SignupResendVerificationCodeStep);
