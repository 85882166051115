/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import RecaptchaText from '../../Layout/Fields/RecaptchaText';

const SignupFooter = () => {
  const { t } = useTranslation('signup');

  return (
    <>
      <footer className="modal-card-foot">
        <div className="columns is-mobile m-modal-footer-logos is-multiline">
          <div className="column">
            <i className="icon-logo-secure-SSL">
              <span className="is-sr-only">
                {t('SECURE_SSL')}
              </span>
            </i>
          </div>
          <div className="column">
            <i className="icon-logo-gambling-integrity">
              <span className="is-sr-only">
                {t('GAMBLING_INTEGRITY')}
              </span>
            </i>
          </div>
          <div className="column">
            <i className="icon-responsible-gaming">
              <span className="is-sr-only">
                18
              </span>
            </i>
          </div>
        </div>
      </footer>
      <div className="container has-text-centered py-2">
        <RecaptchaText />
      </div>
    </>
  );
};

export default SignupFooter;
