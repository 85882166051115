import React from 'react';
import GameBrowser from '../../Game/GameBrowser';

const SearchedGames = () => (
  <main className="main-content-parent main-content-parent-short">
    <section className="row container is-fluid m-game-grid__container games">
      <hr className="gamesline" />
      <GameBrowser />
    </section>
  </main>
);

export default SearchedGames;
