import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Casino from '@dialinvest/react-casino';
import { withTranslation } from 'react-i18next';
import { stripHtmlBody } from '../../Helpers';
import LandingPageHeroBanner from './LandingPageHeroBanner';
import LandingPageVideo from './LandingPageVideo';
import Title from './Title';
import TextArea from './TextArea';
import Carousel from './Carousel';
import GamesCarousel from './GamesCarousel';
import PaymentProvidersCarousel from './PaymentProvidersCarousel';
import FeaturedContent from './FeaturedContent';

class LandingPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      paymentProviders: [],
      gameProviders: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { paymentProviders } = await this.fetchSnippets();
    const gameProviders = await this.fetchProviders();

    this.setState(prevState => ({
      ...prevState,
      loading: false,
      paymentProviders,
      gameProviders,
    }));
  }

  fetchContent = async url => new Casino.FetchContent(url).perform();

  fetchSnippets = async () => {
    const { i18n } = this.props;
    const lang = i18n.language;
    const { data } = await new Casino.FetchSnippets(`footers/${lang}_main-footer`).perform();
    let paymentProviders;

    if (!data.icons || data.error) {
      paymentProviders = [];
      return { paymentProviders, data };
    }

    paymentProviders = data.icons.length > 0 ? data.icons.split(',') : [];

    return { paymentProviders, data };
  };

  fetchProviders = async () => {
    const { data: { items } } = await this.fetchContent('games/providers?status=enabled');
    return items;
  };

  render() {
    const { data, t } = this.props;
    const { loading, gameProviders, paymentProviders } = this.state;

    const {
      mainTitle, subTitle, firstContentTitle, firstContent, bonusAmount, spinsAmount, ...content
    } = stripHtmlBody(data.body);

    return (
      <div className="landing-page-wrapper">
        <LandingPageHeroBanner
          heroImage={data.carousel[0]?.desktop_image?.url}
          spinsAmount={spinsAmount}
          bonusAmount={bonusAmount}
          t={t}
        />
        {!loading && data.carousel && (
          <Carousel logos={gameProviders} />
        )}
        {loading && (
          <>
            <div className="loader__holder">
              <Carousel />
              <div className="loader" />
            </div>
          </>
        )}
        <LandingPageVideo videoURL={data.carousel[0]?.desktop_video?.url} />
        <TextArea
          title={mainTitle}
          contentTitle={firstContentTitle}
          body={firstContent}
          t={t}
        />
        <GamesCarousel />
        <Title title={subTitle} />
        {!loading && data.carousel && (
          <PaymentProvidersCarousel snippets={paymentProviders} />
        )}
        <FeaturedContent
          {...content}
          featuredImage={data.carousel[0]?.desktop_images[0]?.url}
          t={t}
        />
      </div>
    );
  }
}

LandingPage.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.string.isRequired,
    carousel: PropTypes.instanceOf(Object).isRequired,
  }),
  i18n: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

LandingPage.defaultProps = {
  data: {},
};

export default compose(
  withTranslation(),
)(LandingPage);
