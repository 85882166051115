/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toggleModal, Modals } from '../ModalManager';
import { callSignInModal } from '../../Helpers';

const SignupFifthStep = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onFinish = () => {
    toggleModal(Modals.Signup);
    toggleModal(Modals.CashierDeposit);
    history.push('/');
  };

  const onClickLimit = (e) => {
    e.preventDefault();
    if (process.env.REACT_APP_REGISTRATION_AUTO_LOGIN !== 'true') callSignInModal(e);
    toggleModal(Modals.Signup);
    history.push('/account/my-limits');
  };

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-8 has-text-centered">
          <img width="200" src={`${process.env.PUBLIC_URL}/images/treasure-box.png`} alt="Spinnalot" />
        </div>
      </div>
      <div className="buttons is-flex-direction-column">
        <button
          type="button"
          id="onFinish"
          onClick={onFinish}
          className="button is-centered has-text-primary is-size-5"
        >
          {t('signup:deposit')}
        </button>
        <a
          className="is-success is-medium is-uppercase"
          id="limit"
          href="/#"
          onClick={onClickLimit}
        >
          {t('buttons:set_your_limits')}
        </a>
      </div>
    </>
  );
};

export default SignupFifthStep;
