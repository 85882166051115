import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toggleModalError } from '../../../redux/actions/errorModalActions';

export class ConnectionErrorPopUp extends PureComponent {
  render() {
    const {
      isOpen,
      message,
      onToggleModalError,
      connectionError,
      calledFunction,
    } = this.props;

    return (
      <div data-dismiss-interval className={`notification connection-pop-up${!connectionError ? ' no-error' : ''}${!isOpen ? ' is-hidden' : ''}`}>
        <button type="button" className="delete" onClick={() => onToggleModalError(connectionError, calledFunction)} />
        <div className="media">
          <div className="media-left">
            <i className="fas fa-wifi fa-lg" />
            {connectionError && <i className="fas fa-slash fa-lg" />}
          </div>
          <div className="media-content">
            <h3>{message}</h3>
          </div>
        </div>
      </div>
    );
  }
}

ConnectionErrorPopUp.propTypes = {
  onToggleModalError: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  connectionError: PropTypes.bool.isRequired,
  message: PropTypes.string,
  calledFunction: PropTypes.string.isRequired,
};

ConnectionErrorPopUp.defaultProps = {
  message: null,
};

export const mapStateToProps = state => ({
  isOpen: state.errorModal?.isOpen,
  message: state.errorModal?.message,
  connectionError: state.errorModal?.connectionError,
  calledFunction: state.errorModal?.calledFunction,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModalError: (connectionError, calledFunction) => dispatch(toggleModalError(null, false, connectionError, calledFunction)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ConnectionErrorPopUp);
