/* eslint-disable no-use-before-define */
import React from 'react';
import useGames from '../../../hooks/games/useGames';
import GameThumbnailList from '../GameThumbnailList';
import useActiveGames from '../../../hooks/games/useActiveGames';
import useQuery from '../../../hooks/useQuery';

function FilteredGames() {
  const { gamesByCategories, gamesByIds } = useGames();
  const { activeCategory, activeProvider, activeStudio } = useActiveGames();
  const { query } = useQuery();
  const searchText = query.get('search');

  const games = Object.values(gamesByIds);

  const activeGames = gamesByCategories?.[activeCategory?.id];

  const filteredGames = games
    ?.filter(game => searchFilter(game, searchText))
    ?.filter(game => providerFilter(game, activeProvider?.id || activeStudio?.id))
    ?.filter(game => categoryFilter(game, activeCategory?.id))
    ?.sort((a, b) => a?.position - b?.position);

  const filteredCategory = {
    ...activeGames,
    games: filteredGames,
    count: filteredGames?.length,
  };

  return (
    <>
      {games?.length && (
        <GameThumbnailList
          category={filteredCategory}
          maxLimit={filteredCategory?.displayGamesCount}
        />
      )}
    </>
  );
}

export default FilteredGames;

export const searchFilter = (game, search) => {
  if (!search) return true;

  const text = search?.toLowerCase();

  return (
    game.name?.toLowerCase().includes(text)
    || game.slug?.toLowerCase().includes(text)
    || game.reference?.toLowerCase().includes(text)
  );
};

export const providerFilter = (game, providerId) => {
  if (!providerId) return true;
  return game.provider.id === providerId;
};

export const categoryFilter = (game, categoryId) => {
  if (!categoryId) return true;
  return game.categories_by_id.includes(categoryId);
};
