/* eslint-disable no-param-reassign */
import Casino from '@dialinvest/react-casino';
import MD5 from 'crypto-js/md5';

export const dateFormatWithT = date => (date?.includes('T') ? date.split('T')[0] : date);

export const quickSignupCompletion = async (playerData, callback) => {
  const playerDataFields = {
    firstName: playerData.firstName,
    lastName: playerData.lastName,
    gender: playerData.gender,
    birthDate: dateFormatWithT(playerData.birthDate),
    address: playerData.address,
    country: playerData.country,
    postalCode: playerData.postalCode,
    city: playerData.city,
    mobile: playerData.mobile,
    currency: playerData.currency,
    securityQuestion: 'What were the last 4 digits of your childhood telephone number?',
    securityAnswer: '1234',
  };

  const { sessionKey } = playerData;
  const signup = new Casino.models.Signup();
  const response = await signup.quickSignupCompletion({
    sessionKey,
    ...playerDataFields,
  });

  callback(response.data);
};

export const generateHashedUserName = (email) => {
  const hashCode = MD5(`${email}`);
  return `SPI-${hashCode}`.substring(0, 14);
};

export const userNameGenerator = email => generateHashedUserName(email);

export const sendUserConsents = (item, account, userConsents, sessionKey) => {
  const consentData = userConsents.find(
    consent => consent.consent.key === item.consentKey,
  );
  if (!consentData) return;
  let params = {};
  if (item.value) {
    params = { sessionKey, consentedVersions: consentData.consent.consentVersionId };
  } else {
    params = { sessionKey, unConsentedVersions: consentData.consent.consentVersionId };
  }
  account.userConsentsSave(params);
};

export const updateUserConsents = async (consent, sessionKey) => {
  const account = new Casino.models.Account();

  const result = await account.userConsents({
    sessionKey,
  });
  if (result.data.userConsents) {
    Object.entries(consent).map(([, item]) => (
      sendUserConsents(item, account, result.data.userConsents, sessionKey)
    ));
  }
};
