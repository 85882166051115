/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import GameBrowser from '../../Game/GameBrowser';
import BasePage from '../Base';
import BackgroundImage from '../../Layout/BackgroundImage';

const GamesPage = ({ data }) => (
  <div className="games-page-wrapper">
    <BasePage data={data}>
      {
        data.banner
          ? (
            <BackgroundImage src={data.banner.meta.download_url} elementClass="carousel m-carousel m-carousel-accounts">
              <div className="container is-fluid" />
            </BackgroundImage>
          ) : (
            <hr className="gamesline" />
          )
      }
      <GameBrowser />
    </BasePage>
  </div>
);

GamesPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
  }),
};

GamesPage.defaultProps = {
  data: {},
};

export default GamesPage;
