import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { context } from '@dialinvest/react-casino';
import SignupDispatcher from './Signup/SignupDispatcher';
import Signin from './Signin';
import Cashier from './Cashier';
import SupportPanel from './SupportPanel';
import CookieAcceptance from './CookieAcceptance';
import ResendVerificationCode from './ResendVerificationCode';
import PasswordResetRequest from './PasswordResetRequest';
import PasswordReset from './PasswordReset';
import Transactions from './Transactions';
import VipApply from './VipApply';
import EmailSent from './EmailSent';
import { needHeaderFooter } from '../Helpers';
import ConnectionErrorPopUp from './ConnectionErrorPopUp';
import LoyaltyShop from './LoyaltyShop';
import ErrorModal from './ErrorModal';
import ProviderSearch from './ProvidersSearch';

const ModalWrapper = ({ children }) => {
  const { PageContext } = context;
  const { pageType } = useContext(PageContext);

  return (
    <>
      {children}
      <SignupDispatcher />
      {pageType !== 'InternalPage' && <CookieAcceptance />}
      <ResendVerificationCode />
      <PasswordResetRequest />
      <PasswordReset />
      <Signin />
      <Transactions />
      {needHeaderFooter(pageType) && <SupportPanel />}
      <Cashier />
      <VipApply />
      <EmailSent />
      <ConnectionErrorPopUp />
      <LoyaltyShop />
      <ErrorModal />
      <ProviderSearch />
    </>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node,
};

ModalWrapper.defaultProps = {
  children: undefined,
};

export default ModalWrapper;
