import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

class PaymentProvidersCarousel extends PureComponent {
  constructor(props) {
    super(props);

    this.paymentProvidersLogoCarousel = React.createRef();
  }

  componentDidMount() {
    this.paymentCarouselsInit();
  }

  paymentCarouselsInit = () => {
    this.paymentCarousels();
  }

  /* istanbul ignore next */
  paymentCarousels = () => {
    const paymentcarousel = this.paymentProvidersLogoCarousel.current.children[0].childNodes;
    if (!paymentcarousel) return;

    gsap.set('.m-payment-carousel__logos', { xPercent: -50, yPercent: -50, opacity: 1 });

    const boxWidth = 280;
    const totalWidth = boxWidth * paymentcarousel.length; // * n of boxes
    const time = 0.5;
    const box = this.paymentProvidersLogoCarousel.current.children[0].childNodes;
    const dirFromLeft = `+=${boxWidth}`;

    const mod = gsap.utils.wrap(0, totalWidth);

    gsap.set(box, {
      x(i) {
        return i * boxWidth;
      },
    });

    gsap.timeline()
      .to(box, {
        x: dirFromLeft,
        modifiers: {
          x: x => `${mod(parseFloat(x))}px`,
        },
        duration: time,
        ease: 'back',
        repeat: -1,
        repeatRefresh: true,
        repeatDelay: 3,
      });
  }

  getPaymentProvidersToDisplay = () => {
    const { snippets } = this.props;

    if (!snippets.length || snippets === 'undefined') return [];

    return snippets;
  }

  renderPaymentProviders = () => (
    this.getPaymentProvidersToDisplay()
      .map(paymentProvider => (
        ((paymentProvider !== 'FF-Gaming-Group') && (paymentProvider !== 'gambling-integrity') && (paymentProvider !== '18')) && (
          <div key={paymentProvider} className="m-payment-carousel__logo">
            {(
              ((paymentProvider !== 'ecopayz') && (paymentProvider !== 'ezeewallet')) && (
                <i className={`icon-logo-${paymentProvider}`}>
                  <span className="is-sr-only">{paymentProvider.toUpperCase()}</span>
                </i>
              )
            )}
            {(paymentProvider === 'ecopayz' && (
            <i className={`icon-logo-${paymentProvider}`}>
              <span className="is-sr-only">{paymentProvider.toUpperCase()}</span>
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span className="path5" />
              <span className="path6" />
              <span className="path7" />
              <span className="path8" />
            </i>
            ))}
            {(paymentProvider === 'ezeewallet' && (
              <i className={`icon-logo-${paymentProvider}`}>
                <span className="is-sr-only">{paymentProvider.toUpperCase()}</span>
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </i>
            ))}
          </div>
        )
      ))
  )

  render() {
    return (
      <div className="m-payment-carousel" ref={this.paymentProvidersLogoCarousel}>
        <div id="paymentcarousel" className="m-payment-carousel__logos">
          {this.renderPaymentProviders()}
        </div>
        <div className="m-payment-carousel__curve" />
      </div>
    );
  }
}

PaymentProvidersCarousel.propTypes = {
  snippets: PropTypes.instanceOf(Array).isRequired,
};

export default PaymentProvidersCarousel;
