/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

export const SkeletonNew = ({ width, height, borderRadius, padding }) => {
  return (
    <div className="skeleton-loading"/>
  );
};

SkeletonNew.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
};

SkeletonNew.defaultProps = {
  width: '270px',
  height: '185px',
  borderRadius: '8px',
};
