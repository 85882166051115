import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ArticleHero from './ArticleHero';
import ArticleHeader from './ArticleHeader';
import ArticleBonusPlan from './ArticleBonusPlan';
import { getPathname } from '../../Helpers';

const ArticleTiers = ({ data }) => (
  <div>
    {data.spinnalot_tiers.map((tier, index) => (
      <ArticleTier key={index} tier={tier} />
    ))}
  </div>
);

const ArticleTier = ({ tier }) => (
  <section className="section m-promo-section m-promo-article-background" style={{ backgroundImage: `url(${tier.background_image.url})` }}>
    <article className="container is-fluid m-promo-article-layout">
      <ArticleHeader title={tier.title} excerpt={tier.excerpt} titleimage={tier.title_image.url} show={tier.show_title} />
      <div className="columns is-centered is-multiline is-mobile m-promo-columns">
        <div className="column content is-12-mobile is-10-tablet is-6-desktop is-5-widescreen">
          { tier.title !== 'Mystery Spins' && (
          <div id="article-details" className="m-promo-blog-article content" dangerouslySetInnerHTML={{ __html: tier.body }} />
          )}
          { tier.title === 'Mystery Spins'
          && (
            <>
              <ArticleBonusPlan textBody={tier.body} />
            </>
          )}
          { tier.button_label !== '' && tier.button_bonus_code === ''
          && (
            <div className="m-promo-button has-text-centered">
              <NavLink
                className="button is-outlined is-primary is-large has-text-white"
                replace
                to={getPathname(
                  ((tier.button_target && (tier.button_target.html_url))),
                )}
              >
                { tier.button_label }
              </NavLink>
            </div>
          )}
          { tier.terms_and_conditions !== ''
          && (
            <div className="m-promo-small-print has-text-grey has-text-centered content">
              <h4 className="title is-size-5-mobile is-size-4-tablet has-text-white">Promotion Terms &amp; Conditions</h4>
              <div className="has-text-left">
                <p dangerouslySetInnerHTML={{ __html: tier.terms_and_conditions }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </article>
  </section>
);

const ArticlePageDetails = ({ data }) => (
  <>
    <section className="section m-promo-section m-promo-article-background">
      { data.meta.show_header === true
      && (
        <ArticleHero
          bgimage={data.header_image}
          titleimage={data.banner}
          headervideo={data.header_video}
          body={data.body}
          excerpt={data.excerpt}
        />
      )}
      <ArticleTiers data={data} />
    </section>
  </>
);

ArticlePageDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

ArticleTiers.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

ArticleTier.propTypes = {
  tier: PropTypes.instanceOf(Object).isRequired,
};

export default ArticlePageDetails;
