import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import BlogWidgetItem from './PromotionWidgetItem';
import WidgetLink from '../WidgetLink';
import Skeleton from '../../Layout/Skeleton';

const PromotionWidget = ({ items, linkTo }) => {
  const { t } = useTranslation('common');

  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

  let todayLabelGenerated = false;
  let tomorrowLabelGenerated = false;
  let comingLabelGenerated = false;

  let index = 0;
  const widgetItems = items.map((item) => {
    let label = null;

    if (today === item.valid_from && !todayLabelGenerated) {
      label = t('today_for_you');
      todayLabelGenerated = true;
    } else if (tomorrow === item.valid_from && !tomorrowLabelGenerated) {
      label = t('tomorrow_for_you');
      tomorrowLabelGenerated = true;
    } else if (!comingLabelGenerated && ![today, tomorrow].includes(item.valid_from)) {
      label = t('coming_soon');
      comingLabelGenerated = true;
    }
    index += 1;

    return <BlogWidgetItem key={index} data={item} label={label} />;
  });

  let widgetBody;

  if (widgetItems.length > 0) {
    widgetBody = (
      <>
        {widgetItems}
        <div className="card-content column is-12">
          <WidgetLink href={linkTo} text={`${t('all_upcoming_promotions')} `} />
        </div>
      </>
    );
  } else {
    widgetBody = (
      <Skeleton style={{
        height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      />
    );
  }

  return (
    <div className="column m-promo-columns-column-aside is-hidden-touch">
      <aside className="m-promo-columns-aside">
        <p className="m-promo-widget-tag has-text-dark">{t('promotions_calendar')}</p>
        <div className="m-promo-calendar-widget has-background-primary columns is-multiline is-mobile">
          {widgetBody}
        </div>
      </aside>
    </div>
  );
};

PromotionWidget.propTypes = {
  items: PropTypes.instanceOf(Array),
  linkTo: PropTypes.string,
};

PromotionWidget.defaultProps = {
  items: [],
  linkTo: '/#',
};

export default PromotionWidget;
