import React from 'react';
import PropTypes from 'prop-types';
import GameThumbnailList, { SectionWrapper } from '../GameThumbnailList';
import useGames from '../../../hooks/games/useGames';
import usePromoBlocks from '../../../hooks/promo/usePromoBlocks';
import { SkeletonNew } from './SkeletonNew';

function GamesByCategories({ categories }) {
  const { gamesByCategories, categories: categoriesStore } = useGames();
  const promoBlocks = usePromoBlocks();

  if (categoriesStore.isLoading) {
    return (
      <SectionWrapper>
        <div
          id="loader-grid"
          className="m-game-grid columns is-multiline is-mobile parent-grid"
        >
          {new Array(10).fill(true).map((_, i) => (
            <SkeletonNew padding="0.2rem" key={i} />
          ))}
        </div>
      </SectionWrapper>
    );
  }

  return (
    <>
      {Object.values(categories)
        ?.sort((a, b) => a.position - b.position)
        ?.map((item, i) => (
          <React.Fragment key={item?.id}>
            {!!gamesByCategories[item?.id]?.games?.length ? (
              <GameThumbnailList
                key={item.id}
                category={item}
                maxLimit={item.displayGamesCount}
                index={i}
                promoBlocks={promoBlocks}
              />
            ) : (
              <h1 style={{ color: 'yellow' }}>....loading</h1>
            )}
          </React.Fragment>
        ))}
    </>
  );
}

export default GamesByCategories;

GamesByCategories.propTypes = {
  categories: PropTypes.instanceOf(Object).isRequired,
};
