import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper';

const CashierInstance = (
  sessionKey,
  partyId,
  method = 'deposit',
  onSuccess,
  doneLoading,
  paymentMethodSelect,
  newProviderWindow,
  cancelled,
  onLoadError,
  navigate,
  providerType,
  locale,
  merchantId,
  currencyAmount,
  currencyPredefinedAmounts,
) => new _PaymentIQCashier('#cashier-deposit',
  {
    merchantId,
    userId: partyId,
    sessionId: sessionKey,
    environment: process.env.REACT_APP_PAYMENTIQ_ENVIRONMENT,
    containerHeight: '100%',
    containerWidth: '100%',
    accountDelete: true,
    amount: currencyAmount,
    providerType,
    font: 'google, Lato',
    autoOpenFirstPaymentMethod: false,
    method,
    bonusCode: '',
    layout: 'vertical',
    locale,
    logoSize: '',
    logoUrl: '',
    predefinedAmounts: currencyPredefinedAmounts,
    receiptAmountDisplayStyle: 'ISO-3',
    saveAccountOption: true,
    scrollToOffset: false,
    showAccounts: 'inline',
    showBonusCode: false,
    showFooter: false,
    showHeader: false,
    allowMobilePopup: true,
    allowCancelPendingWithdrawal: true,
    storeAccount: true,
    initLoadErrorTimeout: 10000,
    theme: {
      input: {
        color: '#1e1e1e',
        borderRadius: '4px',
      },
      labels: {
        color: '#1e1e1e',
      },
      headings: {
        color: '#1e1e1e',
      },
      loader: {
        color: '#79B400',
      },
      buttons: {
        color: '#79B400',
      },
      cardbackground: {
        color: '#ffffff',
      },
    },
    attributes: {
      paymentiq_crypto: process.env.REACT_APP_PAYMENTIQ_CRYPTO,
    },
  },
  (api) => {
    /* istanbul ignore next */
    // eslint-disable-next-line no-unused-expressions
    api.on({
      /* istanbul ignore next */
      success: data => onSuccess(data),
      /* istanbul ignore next */
      doneLoading: data => doneLoading(data),
      paymentMethodSelect: data => paymentMethodSelect(data),
      newProviderWindow: data => newProviderWindow(data),
      onLoadError: data => onLoadError(data),
      navigate: data => navigate(data),
      cancelled: data => cancelled(data),
      // eslint-disable-next-line no-sequences
    }),
    api.css(`
      :root {
        --main-color: #29b355
      }
      #cashier h5 {
        color: #5a5a5a!important;
      }
      #cashier label {
        font-size: 16px!important;
        color: #1e1e1e;
        display: block;
        margin-bottom: 4px;
        font-weight: 500;
      }
      #cashier .dropdown-toggler {
        border: 1px solid;
        padding: 10px;
      }
      #cashier input {
        padding: 18px 10px 0 10px;
        padding-left: 10px!important;
        border-radius: 4px!important;
        height: 52px;
        background: #fafafa!important;
      }
      #cashier .list-payment-methods {
        padding: 10px 0!important;
      }
      #cashier .main-header-container h3 {
        font-size: 18px!important;
        font-weight: 600;
        margin-top: 0px !important;
      }
      @media (min-width: 767px) {
        .payment-method-details {
          padding: 10px 50px!important;
        }
      }
      #cashier .list-payment-methods .payment-method:hover {
        box-shadow: 0 5px 20px 0 rgba(0,0,0,.1);
      }
      #cashier .list-payment-methods .payment-method {
        transition: all .2s cubic-bezier(.25,.8,.25,1)!important;
        box-shadow: none;
        overflow: hidden;
        margin-bottom: 8px;
        border-radius: 7px;
        border: 1px solid #cfcfcf!important;
      }
      #cashier .list-payment-methods .payment-method.active {
        border-color: #a3a3a3!important;
      }
      #cashier .payment-method .payment-method-header {
        padding: 10px 15px!important;
        border-radius: 7px;
        background-color: #fff;
        border: none!important;
      }
      #cashier .submit-button {
        border-radius: 100px!important;
      }
      #cashier button:not(.predefinedvalue):disabled {
        background: #a3a3a3!important;
        opacity: 1;
      }
      #cashier .list-payment-methods {
        padding: 10px 10px !important;
      }
      #cashier .account-dropdown .dropdown-container {
        padding: 0;
      }
      #cashier .account-dropdown .dropdown-toggler {
        border: 0;
      }
      #cashier .dropdown-content {
        max-height: 160px!important;
      }
    `);
  });

export default CashierInstance;

export const resetCashierInstance = () => {
  window._PaymentIQCashierReset();
};
