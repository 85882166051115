/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';

const SignupFailedStep = () => {
  const { t } = useTranslation('common');

  return (
    <div className="content has-text-centered step-content is-active">
      <div className="notification">
        <h1>{t('registration_failed')}</h1>
        <div>{t('please_contact_support')}</div>
      </div>
    </div>
  );
};

export default SignupFailedStep;
