const SignupState = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  fifth: 5,
  consent: 6,
  resendVerification: 7,
  loading: 8,
  error: 9,
  termsConditions: 11,
  privacyPolicy: 12,
  cookiePolicy: 13,
};

export default SignupState;
