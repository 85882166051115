import React from 'react';
import GamesByCategories from './GamesByCategories';
import GamesByCategory from './GamesByCategory';
import useGames from '../../../hooks/games/useGames';
import FilteredGames from './FilteredGames';
import useActiveGames from '../../../hooks/games/useActiveGames';
import { FAVORITE_GAMES_CATEGORY_ID } from '../../../redux/actions/gamesActions/gamesAction';
import FavoriteGamesList from './FavoriteGamesList';
import FilteredGamesFromCms from './FilteredGamesFromCms';
import useQuery from '../../../hooks/useQuery';

function Games() {
  const {
    gamesByCategories, gamesByProviders,
  } = useGames();
  const { query } = useQuery();
  const searchText = query.get('search');
  const { activeCategory, activeProvider, activeStudio } = useActiveGames();

  if (activeCategory?.id === FAVORITE_GAMES_CATEGORY_ID) {
    return <FavoriteGamesList />;
  }

  if ((searchText?.length ?? 0) >= 3) {
    return <FilteredGamesFromCms />;
  }

  if (activeCategory?.id && (activeProvider?.id || activeStudio?.id)) {
    return <FilteredGames />;
  }

  if (activeCategory?.id && !activeProvider?.id && !activeStudio) {
    return <GamesByCategory category={gamesByCategories[activeCategory?.id]} />;
  }
  if (!activeCategory?.id && (activeProvider || activeStudio)) {
    const currentProvider = Object.values(gamesByProviders)?.find(
      (provider) => {
        if (provider?.type === 'studio') {
          return provider?.slug === activeStudio?.slug;
        }
        return provider?.slug === activeProvider?.slug;
      }
    ); return <GamesByCategory category={currentProvider} />;
  }

  return (
    <>
      <GamesByCategories categories={gamesByCategories} />
    </>
  );
}

export default Games;
