/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SignupState from './SignupState';
import { useSignupContext } from './context';
import SignupFirstStep from './SignupFirstStep';
import SignupSecondStep from './SignupSecondStep';
import SignupThirdStep from './SignupThirdStep';
import SignupFourthStep from './SignupFourthStep';
import SignupFifthStep from './SignupFifthStep';
import SignupFailedStep from './SignupFailedStep';
import SignupConsentStep from './SignupConsentStep';
import SignupTermsPolicy from './SignupTermsPolicy';
import SignupResendVerificationCodeStep from './SignupResendVerificationCodeStep';

const SignupSection = () => {
  const { signupState } = useSignupContext();
  const { step } = signupState;
  return (
    <section id="sign-up-steps-body" className="modal-card-body has-text-white">
      {step === SignupState.first && (
        <SignupFirstStep />
      )}
      {step === SignupState.second && (
        <SignupSecondStep />
      )}
      {step === SignupState.third && (
        <SignupThirdStep />
      )}
      {step === SignupState.fourth && (
        <SignupFourthStep />
      )}
      {step === SignupState.fifth && (
        <SignupFifthStep />
      )}
      {step === SignupState.resendVerification && (
        <SignupResendVerificationCodeStep />
      )}
      {step === SignupState.consent && (
        <SignupConsentStep />
      )}
      {step === SignupState.termsConditions && (
        <SignupTermsPolicy link="term_conditions_link" />
      )}
      {step === SignupState.privacyPolicy && (
        <SignupTermsPolicy link="privacy_policy_link" />
      )}
      {step === SignupState.cookiePolicy && (
        <SignupTermsPolicy link="cookie_policy_link" />
      )}
      {step === SignupState.error && (
        <SignupFailedStep />
      )}
    </section>
  );
};

export default SignupSection;
