import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import handleClick, { launchLiveChat } from '../../Helpers';

export class SupportPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.handleClick = handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  getLiveChat = () => {
    launchLiveChat();
  }

  toggleSupport = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  render() {
    const { isActive } = this.state;
    const { t } = this.props;

    return (
      <div
        ref={(node) => { this.node = node; }}
        className={`m-support-tab-container container is-hidden-touch is-fluid ${isActive ? 'is-active' : ''}`}
      >
        <div id="supportTab" className={`m-support-tab is-size-5 ${isActive ? 'is-active' : ''}`}>
          <button
            type="button"
            data-menu-target="supportTab"
            id="supportButton"
            className="navbar-item"
            onClick={this.toggleSupport}
          >
            {t('support')}
            &nbsp;
            <i className="icon-support" />
          </button>
          <ul className="m-support-tab-content has-background-primary is-size-6">
            <li>
              <button type="button" className="live-chat" onClick={this.getLiveChat}>
                <span className="icon m-support-tab-icon">
                  <i className="icon-live-chat" />
                </span>
                {t('live_chat')}
              </button>
            </li>
            <li>
              <NavLink to="/email" onClick={this.toggleSupport}>
                <span className="m-support-tab-icon">
                  <i className="icon-email" />
                </span>
                {t('email')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/faq" onClick={this.toggleSupport}>
                <span className="m-support-tab-icon">
                  <i className="icon-question" />
                </span>
                {t('faq')}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

SupportPanel.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('common'),
)(SupportPanel);
