/* eslint-disable import/prefer-default-export */
import store from '../../containers/App/store';
import { clipHTMLBody, unClipHTMLBody } from '../Helpers';

export const Modals = {
  CashierDeposit: 'cashier_deposit',
  CashierWithdrawal: 'cashier_withdrawal',
  PasswordReset: 'password_reset',
  PasswordResetRequest: 'password_reset_request',
  ResendCerificationCode: 'resend_verification_code',
  Signin: 'signin',
  Signup: 'signup',
  Transactions: 'transactions',
  Providers: 'providers',
};

export const modalMap = {
  cashier_deposit: {
    action: { type: 'TOGGLE_MODAL_CASHIER', cashierType: 'deposit' },
    reducer: 'cashierModal',
  },
  cashier_withdrawal: {
    action: { type: 'TOGGLE_MODAL_CASHIER', cashierType: 'withdrawal' },
    reducer: 'cashierModal',
  },
  password_reset: {
    action: { type: 'TOGGLE_MODAL_RESET' },
    reducer: 'passwordResetModal',
  },
  password_reset_request: {
    action: { type: 'TOGGLE_MODAL_RESET_REQUEST' },
    reducer: 'passwordResetRequestModal',
  },
  resend_verification_code: {
    action: { type: 'TOGGLE_MODAL_RESEND' },
    reducer: 'resendVerificationCodeModal',
  },
  signin: {
    action: { type: 'TOGGLE_MODAL_IN' },
    reducer: 'signInModal',
  },
  signup: {
    action: { type: 'TOGGLE_MODAL' },
    reducer: 'signUpModal',
  },
  transactions: {
    action: { type: 'TOGGLE_MODAL_TRANSACTIONS' },
    reducer: 'transactionModal',
  },
  providers: {
    action: { type: 'TOGGLE_MODAL_PROVIDERS' },
    reducer: 'providersModal',
  },
};

export const openModal = (modalType) => {
  const modalConfiguration = modalMap[modalType];
  if (modalConfiguration.action) {
    store.dispatch(modalConfiguration.action);
    unClipHTMLBody();
    clipHTMLBody();
  }
};

export const toggleModal = (modalType) => {
  const modalConfiguration = modalMap[modalType];
  if (modalConfiguration.action) {
    store.dispatch(modalConfiguration.action);
    clipHTMLBody();
  }
};
