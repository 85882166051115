import i18next from 'i18next';

const validate = (values) => {
  const errors = {};
  const { email } = values;
  const value = email;

  const field = Object.keys(values).find(key => values[key] === value);

  if (!value) {
    errors[field] = i18next.t('errors:required');
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)) {
    errors[field] = i18next.t('errors:email_format');
  }

  return errors;
};

export default validate;
