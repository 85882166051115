import React, { PureComponent } from 'react';
import { gsap } from 'gsap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const CAROUSEL_CONFIG = {
  displayNr: 10,
  gameCategoryId: 28,
};

class GamesCarousel extends PureComponent {
  constructor() {
    super();

    this.state = {
      loading: true,
    };

    this.gameLogosCarousel = React.createRef();
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  componentDidUpdate() {
    const { loading } = this.state;

    if (!loading) this.thumbnailCarouselInit();
  }

  thumbnailCarouselInit = () => {
    this.thumbnailCarouselAnimation();
  }

  /* istanbul ignore next */
  thumbnailCarouselAnimation = () => {
    const thumbnailcarousel = this.gameLogosCarousel.current?.children[0].childNodes;

    if (!thumbnailcarousel) return;

    gsap.set('.m-thumbnail-carousel__thumbnails', { xPercent: -50, yPercent: -50 });

    const boxWidth = 368;
    const totalWidth = boxWidth * thumbnailcarousel.length; // * n of boxes + diff textBox
    const time = 60;
    const box = this.gameLogosCarousel.current.children[0].childNodes;
    const dirFromLeft = `-=${totalWidth}`;

    const mod = gsap.utils.wrap(-boxWidth, (totalWidth + boxWidth) - boxWidth * 2);

    gsap.set(box, {
      x(i) {
        return i * boxWidth;
      },
    });

    gsap.timeline()
      .to(box, {
        x: dirFromLeft,
        modifiers: {
          x: x => `${mod(parseFloat(x))}px`,
        },
        duration: time,
        ease: 'none',
        repeat: -1,
      });
  }

  getGamesToDisplay = () => {
    const { gamesByCategories } = this.props;
    const gamesToShow = Object.values(gamesByCategories)?.find(
      category => category.id === CAROUSEL_CONFIG.gameCategoryId,
    )?.games;

    if (gamesToShow?.length) {
      return gamesToShow.slice(0, CAROUSEL_CONFIG.displayNr);
    }

    return [];
  }

  renderGames = () => (
    this.getGamesToDisplay()
      .map(game => (
        <div key={game.id} className="m-thumbnail-carousel__thumbnail">
          <img src={game.thumbnail.url} alt={game.slug} />
        </div>
      ))
  );

  render() {
    return (
      <div className="m-thumbnail-carousel" ref={this.gameLogosCarousel}>
        <div id="thumbnailcarousel" className="m-thumbnail-carousel__thumbnails">
          {this.renderGames()}
        </div>
        <div className="m-landing-page-section-curve-mobile is-hidden-desktop">
          <img src="/images/curves-mobile.png" alt="curve" />
        </div>
      </div>
    );
  }
}

GamesCarousel.propTypes = {
  gamesByCategories: PropTypes.instanceOf(Object),
};

GamesCarousel.defaultProps = {
  gamesByCategories: undefined,
};

export const mapStateToProps = state => ({
  gamesByCategories: state.games.gamesByCategories,
});

export default compose(
  connect(mapStateToProps),
)(GamesCarousel);
