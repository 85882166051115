/* eslint-disable import/prefer-default-export */
import Casino from '@dialinvest/react-casino';

export const AddTrackingValue = (playerObj) => {
  if (document.cookie.indexOf(process.env.REACT_APP_EGASS_COOKIE_NAME) >= 0) {
    // eslint-disable-next-line max-len
    playerObj.userTrackingCodes = new Casino.Cookie().get(process.env.REACT_APP_EGASS_COOKIE_NAME);
  }
  return playerObj;
};
