import {
  SET_USER_STATE,
  SET_USER_SEARCH,
  CLEAR_USER_SEARCH,
  SET_USER_FAVORITE_GAMES,
  SET_USER_DATA,
  CLEAR_USER_DATA,
} from '../actions/userActions';

const initialState = {
  userLoggedIn: false,
  searchedGames: '',
  favoriteGames: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_STATE:
      return {
        ...state,
        userLoggedIn: action.newState,
      };
    case SET_USER_SEARCH:
      return {
        ...state,
        searchedGames: action.newState,
      };
    case CLEAR_USER_SEARCH:
      return {
        ...state,
        searchedGames: '',
      };
    case SET_USER_FAVORITE_GAMES:
      return {
        ...state,
        favoriteGames: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
