import React from 'react';
import Casino from '@dialinvest/react-casino';

function usePromoBlocks() {
  const [promoBlocks, setPromoBlocks] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await new Casino
          .FetchContent('game-promotions/').perform();
        const promoBlockItems = response?.data?.items;

        if (promoBlockItems?.length) {
          setPromoBlocks(promoBlockItems);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return promoBlocks;
}

export default usePromoBlocks;
