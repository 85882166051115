import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { clipHTMLBody, clipHTMLBodyForModal } from '../../Helpers';
import ErrorNotification from '../Notifications/error';
import Signup from '.';
import { toggleModalGeneralError } from '../../../redux/actions/generalErrorModalActions';

class SignupDispatcher extends PureComponent {
  REQUEST_SUCCESS = 'SUCCESS';

  constructor(props) {
    super(props);
    this.signup = new Casino.models.Signup();
    this.state = {
      errorMessage: false,
      showModal: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    this.signup.preSignupCheck().then((result) => {
      if (result.data.status === this.REQUEST_SUCCESS) {
        this.setState({ showModal: true, errorMessage: false });
      } else {
        this.setState({ errorMessage: true, showModal: false });
      }
    }).catch(() => this.setState({ showModal: true, errorMessage: false }));
  }

  escFunction = (event) => {
    const { isOpen, onToggleModal } = this.props;
    if (event.keyCode === 27 && isOpen) {
      onToggleModal();
      clipHTMLBody();
    }
  };

  closeButton = () => {
    const { onToggleModal } = this.props;
    onToggleModal();
    clipHTMLBody();
  };

  renderItem() {
    const { errorMessage, showModal } = this.state;
    const {
      step, sessionKey, email, password, language, birthDate, isOpen,
    } = this.props;

    let renderedItem = '';
    renderedItem = (
      <Signup
        isActive={showModal && isOpen}
        step={step}
        sessionKey={sessionKey}
        email={email}
        password={password}
        birthDate={birthDate}
        language={language}
      />
    );

    if (errorMessage === true && isOpen) {
      const { t } = this.props;
      renderedItem = (
        <ErrorNotification
          onClick={this.closeButton}
          title={t('geo_ip_failed_title')}
          subtitle={t('geo_ip_failed_subtitle')}
          description={t('geo_ip_failed_description')}
        />
      );
    }
    return renderedItem;
  }

  render() {
    const {
      t,
      onToggleComingLiveSoonModal,
      isOpen,
      onToggleModal,
      from,
    } = this.props;

    if (process.env.REACT_APP_REG_COMING_SOON === 'true' && isOpen) {
      onToggleModal(from);
      clipHTMLBodyForModal();
      onToggleComingLiveSoonModal(t('common:going_live_soon'));
      return null;
    }

    return this.renderItem();
  }
}

SignupDispatcher.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  step: PropTypes.number,
  sessionKey: PropTypes.string,
  onToggleModal: PropTypes.func.isRequired,
  onToggleComingLiveSoonModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  language: PropTypes.string,
  birthDate: PropTypes.string,
  from: PropTypes.string,
};

SignupDispatcher.defaultProps = {
  from: null,
  sessionKey: null,
  email: null,
  password: null,
  language: null,
  birthDate: null,
  step: undefined,
};

export const mapStateToProps = state => ({
  isOpen: state.signUpModal?.isOpen,
  step: state.signUpModal?.step,
  sessionKey: state.signUpModal?.sessionKey,
  email: state.signUpModal?.email,
  from: state.signUpModal?.from,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModal: (from = null) => dispatch({ type: 'TOGGLE_MODAL', from }),
  onToggleComingLiveSoonModal: message => dispatch(toggleModalGeneralError(message)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('errors'),
)(SignupDispatcher);
