/* eslint-disable */

export const initMatamo = () => {
    const isProd = process.env.REACT_APP_API_BASE_URL?.includes('prod') || false

    if(isProd) {
        var _mtm = window._mtm = window._mtm || [];
            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src='https://matomo.operator.network/js/container_bk3MRKkD.js'; s.parentNode.insertBefore(g,s);
            return
    }
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://qa-matomo.operator.network/js/container_gxGsIP8r.js'; s.parentNode.insertBefore(g,s);
    return
}

