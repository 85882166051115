import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getUserCodedBonus,
  redeemBonus,
  clipHTMLBody,
  isPhone,
  getBankingHistory,
} from "../../Helpers";
import { toggleModalIn } from "../../../redux/actions/signInModalActions";
import store from "../../../containers/App/store";
import FeaturedGameThumbnails from "../../Game/GameThumbnailList/FeaturedGameThumbnails";

const ArticleBonusPlan = ({ textBody }) => {
  const [bonus, setBonus] = useState({});
  const [loading, setLoading] = useState({
    success: false,
    inProgress: false,
    errorMessage: "",
  });
  const [redeemLoading, setRedeemLoadingBonus] = useState({
    success: false,
    inProgress: false,
    errorMessage: "",
  });
  const { t, i18n } = useTranslation("common");
  const userLoggedIn = useSelector((state) => state.userState.userLoggedIn);

  const redeemBonusGotoGame = async () => {
    setRedeemLoadingBonus({ inProgress: true, success: false });

    try {
      const activateBonus = await redeemBonus(bonus.bonusCode);
      console.log("activateBonus", activateBonus, bonus.bonusCode);
      if (!activateBonus) {
        setRedeemLoadingBonus({
          inProgress: false,
          success: false,
          errorMessage: t("common:no_bonus_avaiable"),
        });
        return;
      }
      setRedeemLoadingBonus({ inProgress: false, success: true });
      setTimeout(() => {
        const slugs = isPhone() ? bonus.mobileGameSlug : bonus.desktopGameSlug;
        const slug =
          slugs === "" || slugs === undefined ? bonus.desktopGameSlug : slugs;
        window.location.href = `/${i18n.language}/game/${slug}/?mode=real`;
      }, 1000);
      return;
    } catch (error) {
      setRedeemLoadingBonus({
        inProgress: false,
        success: false,
        errorMessage: t("common:no_bonus_avaiable"),
      });
    }
  };

  const openLoginModal = () => {
    clipHTMLBody();
    store.dispatch(toggleModalIn());
  };

  /* istanbul ignore next */
  useEffect(() => {
    const getBonus = async () => {
      try {
        const allCodedBonuses = await getUserCodedBonus();
        const codedBonuses =
          allCodedBonuses?.bonuses?.data?.optInBonusPlans.filter((item) =>
            item.bonusPlanName.includes("Mystery Spins")
          );
        if (!allCodedBonuses || !codedBonuses?.length) {
          setLoading({
            inProgress: false,
            success: false,
            errorMessage: t("common:no_bonus_avaiable"),
          });
          console.error("Error: no coded bonus available");
          return;
        }

        const bonusJson = (() => {
          try {
            const raw = JSON.parse(codedBonuses[0].description);
            return {
              desktop: `${raw[0]?.desktopGameSlug}`,
              mobile: `${raw[0]?.mobileGameSlug}`,
            };
          } catch (error) {
            return null;
          }
        })();

        if (!bonusJson) {
          console.error("Error: bonusJson is null");
          setLoading({
            inProgress: false,
            success: false,
            errorMessage: t("common:no_bonus_avaiable"),
          });
        }
        setBonus({
          bonusCode: `fs${codedBonuses[0].bonusPlanId}`,
          desktopGameSlug: bonusJson.desktop,
          mobileGameSlug: bonusJson.mobile,
        });
        setLoading({ inProgress: false, success: true });
      } catch (error) {
        console.error("Error", error);
        setLoading({
          inProgress: false,
          success: false,
          errorMessage: t("common:no_bonus_avaiable"),
        });
      }
    };

    const getDepositHistory = async () => {
      setLoading({ inProgress: true });
      const startDate = moment().subtract(90, "days").format("YYYY-MM-DD");
      const endDate = moment().format("YYYY-MM-DD");
      try {
        const transactionHistory = await getBankingHistory(startDate, endDate);
        const depositHistory =
          transactionHistory?.histories?.data?.histories?.filter(
            (item) => item.type === "DEPOSIT" && item.status === "COMPLETED"
          );
        if (depositHistory.length > 0) {
          getBonus();
        } else {
          console.error("Error: No Deposit History");
          setLoading({
            inProgress: false,
            success: false,
            errorMessage: t("common:mystery_spins_no_deposit_history"),
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    };

    if (userLoggedIn) {
      getDepositHistory();
    }
  }, [userLoggedIn, t]);

  return (
    <>
      <div className="has-text-centered">
        {userLoggedIn && !loading.success && loading.errorMessage && (
          <>
            {loading.errorMessage ===
              t("common:mystery_spins_no_deposit_history") && (
              <>
                <h4
                  dangerouslySetInnerHTML={{
                    __html: t("common:mystery_spins_no_deposit_history"),
                  }}
                />
                <Link
                  to="/cashier"
                  class="button is-dark-green mt-0 is-size-5-touch is-size-4-desktop has-text-white mb-3"
                >
                  {t("cashier")}
                </Link>
              </>
            )}
            {loading.errorMessage !==
              t("common:mystery_spins_no_deposit_history") && (
              <>
                <h4>{loading.errorMessage}</h4>
                <div className="m-featured-games-holder">
                  <FeaturedGameThumbnails />
                </div>
              </>
            )}
          </>
        )}
        {!userLoggedIn && (
          <>
            <div
              id="article-details"
              className="m-promo-blog-article content m-promo-spins"
              dangerouslySetInnerHTML={{ __html: textBody }}
            />
            <div
              id="article-bonus-login"
              className="m-promo-blog-article is-fullwidth"
            >
              <p className="has-text-centered">
                <button
                  className="button is-dark-green mt-0 is-size-5-touch is-size-4-desktop has-text-white mb-3"
                  type="button"
                  onClick={(event) => openLoginModal(event)}
                >
                  {t("login")}
                </button>
                <br />
                <span
                  className="is-size-4 is-inline-block mt-2 has-link"
                  dangerouslySetInnerHTML={{ __html: t("bonus_terms_apply") }}
                />
              </p>
            </div>
          </>
        )}
      </div>
      {userLoggedIn && loading.success && !redeemLoading.errorMessage && (
        <div
          id="article-bonus"
          className="m-promo-blog-article content has-text-centered mt-4"
        >
          <p className="has-text-centered">
            {t("hit_the_button_to_get_your_spins")}
          </p>
          <button
            className={`button is-dark-green mt-0 mb-3 is-size-5-touch is-size-4-desktop has-text-white ${
              redeemLoading.inProgress || redeemLoading.success
                ? "is-disabled"
                : ""
            }`}
            onClick={() => redeemBonusGotoGame()}
            type="button"
          >
            {(redeemLoading.success || redeemLoading.inProgress) && (
              <>{t("loading_mystery_spins")}</>
            )}
            {!redeemLoading.success && !redeemLoading.inProgress && (
              <>{t("play_now")}</>
            )}
          </button>
          <br />
          <span
            className="is-size-4 is-inline-block mt-2 has-link"
            dangerouslySetInnerHTML={{ __html: t("bonus_terms_apply") }}
          />
        </div>
      )}
      {userLoggedIn && redeemLoading.errorMessage && (
        <>
          <h4 className="has-text-centered">{redeemLoading.errorMessage}</h4>
        </>
      )}
    </>
  );
};

ArticleBonusPlan.propTypes = {
  textBody: PropTypes.string.isRequired,
};

export default ArticleBonusPlan;
