import React from 'react';
import { useSignupContext } from '../../../Modal/Signup/context';

const LanguageSelect = () => {
  const { getLanguage } = useSignupContext();

  const language = getLanguage();

  const options = language?.map(userLang => (
    <option
      className="language py-2"
      key={userLang.name}
      value={userLang.name}
    >
      {userLang.name}
    </option>
  ));

  return (
    <p className="control m-language-select">
      <span className="select">
        <select name="language" className="ref-select">
          {options}
        </select>
      </span>
    </p>
  );
};

export default LanguageSelect;
