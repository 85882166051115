import React from 'react';
import PropTypes from 'prop-types';

const LoyaltyPageHeader = ({
  data,
  onClick,
  t,
}) => (
  <>
    <div className="columns is-multiline is-mobile m-loyalty-top-area">
      <div className="column is-6-mobile is-6-tablet has-text-white">
        <img className="m-loyalty-logo" width="526" src={data.banner?.meta.download_url} alt="" />

        <div className="m-loyalty-v2-intro-text column is-11-tablet is-11-desktop is-9-widescreen">
          <p className="is-size-6-tablet is-size-5-widescreen">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: data.sections[0].description_logged_out }} />
          </p>
          <button
            type="button"
            className="button is-outlined is-primary is-large"
            onClick={onClick}
          >
            <p>{t('common:start_here_login')}</p>
          </button>
        </div>
      </div>
      <div className="column is-12-mobile is-6-tablet">
        <div className="m-loyalty-scale">
          <img width="979" src={data.background_image?.meta.download_url} alt="" />
        </div>
      </div>
    </div>
    <div className="columns is-centered m-loyalty-section m-loyalty-content-section">
      <div className="column is-8">
        <div className="content has-text-centered">
          <h2 className="is-size-2 is-size-4-mobile">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: data.sections[1]?.title }} />
          </h2>
          <p className="m-margin-top-gap is-size-6-tablet is-size-5-widescreen has-text-centered">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: data.sections[1]?.description_logged_out }} />
          </p>
        </div>
      </div>
    </div>
  </>
);

LoyaltyPageHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    description: PropTypes.string.isRequired,
    background_image: PropTypes.instanceOf(Object),
    foreground_image: PropTypes.instanceOf(Object),
    meta: PropTypes.instanceOf(Object),
    sections: PropTypes.instanceOf(Object),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default LoyaltyPageHeader;
