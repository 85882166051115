import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import PromotionWrapper from './PromotionWrapper';
import PromotionHeader from './PromotionHeader';
import ArticleMenu from '../Article/ArticleMenu';
import FeaturedArticle from '../Article/FeaturedArticle';
import ArticleThumbnailList, { PlaceholderThumbnailList } from '../Article/ArticleThumbnailList';
import BlogWidget from '../Blog/BlogWidget';
import BasePage from '../Base';
import { adjustRecurrences } from './promotionHelper';

class PromotionPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      articlesFetched: false,
      nextMonthArticles: [],
      nextMonthArticlesFetched: false,
    };
  }

  componentDidMount() {
    this.fetchThisMonthArticles();
    this.fetchNextMonthArticles();
  }

  fetchThisMonthArticles = async () => {
    const { data } = this.props;
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const endDate = moment().endOf('month').format('YYYY-MM-DD');

    const url = `pages/articles/?child_of=${data.id}&show_in_menus=true&fields=body,excerpt,valid_from,valid_to,thumbnail,title&valid_from__gte=${startDate}&valid_to__gte=${today}&valid_from__lte=${endDate}&show_in_calendar=true&recurrences=true&order=valid_from`;
    await this.fetchArticles(url, 'articles');
  };

  fetchNextMonthArticles = () => {
    const { data } = this.props;
    const startDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
    const endDate = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');

    const url = `pages/articles/?child_of=${data.id}&show_in_menus=true&fields=body,excerpt,valid_from,valid_to,thumbnail,title&valid_from__gte=${startDate}&valid_from__lte=${endDate}&show_in_calendar=true&recurrences=true&order=valid_from`;

    this.fetchArticles(url, 'nextMonthArticles');
  };

  fetchArticles = async (url, stateName) => {
    const result = await new Casino.FetchContent(url).perform();

    this.setState({
      [stateName]: adjustRecurrences(result.items()),
      [`${stateName}Fetched`]: true,
    });
  };

  render() {
    const {
      articles,
      articlesFetched,
      nextMonthArticles,
      nextMonthArticlesFetched,
    } = this.state;

    const { t, i18n, data } = this.props;

    let menuHandle = `${i18n.language}_promotion-menu`;
    let menuTitle = t('common:promotions');

    if (data.promotion_menu) {
      menuHandle = data.promotion_menu.handle;
      menuTitle = data.promotion_menu.title;
    }

    return (
      <BasePage data={data}>
        <PromotionWrapper>
          <PromotionHeader>
            {data.featured_promotion
              && <FeaturedArticle data={data.featured_promotion} title={data.title} showTitle={data.meta.show_title} />}
            {data.featured_blog
              && <BlogWidget id={data.featured_blog.id} linkTo={data.featured_blog.html_url} />}
          </PromotionHeader>
          <ArticleMenu handle={menuHandle} title={menuTitle} />
          {!nextMonthArticlesFetched && !articlesFetched && <PlaceholderThumbnailList />}
          <ArticleThumbnailList items={articles} needDay />
          {nextMonthArticles.length > 0
            && <ArticleThumbnailList items={nextMonthArticles} title={`${t('common:next_month')}:`} needDay />}
        </PromotionWrapper>
      </BasePage>
    );
  }
}

PromotionPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
};

export default withTranslation()(PromotionPage);
