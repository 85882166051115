import React from 'react';

const PageLoader = () => (
  <div className="pageloader is-active">
    <span className="title page-loader">
      <img src={`${process.env.PUBLIC_URL}/images/preloader.png`} alt="Spinnalot" />
    </span>
  </div>
);

export default PageLoader;
