import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import Pagination from '../../Pagination/index';
import currencyMark from '../../../containers/utitilies/currencies';
import { formatAmount } from '../../Helpers';

export class Transactions extends PureComponent {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.state = {
      parameterInfo: null,
    };
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);
  };

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;
    this.initState();
  }

  formatTransactionAmount = (amount) => {
    if (typeof amount === 'undefined') return '';
    return formatAmount(amount);
  };

  initState = () => {
    const { props } = this;
    const { parameterInfo } = props;

    if (parameterInfo !== undefined && parameterInfo !== null) {
      this.setState({
        parameterInfo: [{
          id: parameterInfo.ID || '',
          gameName: parameterInfo.gameName || '',
          platformCode: parameterInfo.PlatformCode || '',
          productCode: (parameterInfo.subProductCode !== '' && parameterInfo.subProductCode !== null && parameterInfo.subProductCode !== undefined) ? parameterInfo.subProductCode : parameterInfo.productCode,
          tranType: parameterInfo.Type || '',
          currency: parameterInfo.Currency || '',
          realAmount: this.formatTransactionAmount(parameterInfo.RealAmount),
          bonusAmount: this.formatTransactionAmount(parameterInfo.BonusAmount),
          realPostBalance: this.formatTransactionAmount(parameterInfo.RealPostBalance),
          bonusPostBalance: this.formatTransactionAmount(parameterInfo.BonusPostBalance),
        }],
      });
    }
    return null;
  };

  toggleModal = (e) => {
    e.preventDefault();
    const { props } = this;
    props.toggleModalTransactions();
  };

  escFunction = (event) => {
    const { props } = this;
    if (event.keyCode === 27 && props.isOpen) {
      // Do whatever when esc is pressed
      props.toggleModalTransactions();
    }
  };

  render() {
    const { props, state } = this;
    const { isOpen, t } = props;

    if (state.parameterInfo === null || state.parameterInfo === undefined) return null;

    const columns = ['ID', 'Game Provider', 'Game Name', 'Type', 'Currency', 'Real Amount', 'Bonus Amount', 'Released Balance', 'Balance Bonus'];
    const values = ['id', 'productCode?capitalize', 'gameName?capitalize', 'tranType?capitalize', 'currency?uppercase', 'realAmount:currency', 'bonusAmount:currency', 'realPostBalance:currency', 'bonusPostBalance:currency'];

    const transactionFilter = {
      data: state.parameterInfo,
      operator: '',
      column: '',
      value: '',
    };

    return (
      <div id="modal-transaction-info" className={`modal m-modal-base modal-fx-superScaled m-modal-fixed ${isOpen ? 'is-active' : 'is-inactive'}`}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <div className="modal-card-title">
              <h2 className="is-size-3 is-size-4-mobile has-text-centered">{t('account:game_details_history')}</h2>
            </div>
            <button type="button" className="delete" aria-label="close" onClick={e => this.toggleModal(e)} />
          </header>
          <section className="modal-card-body">
            <div className="content">
              <Pagination
                dataList={state.parameterInfo}
                columns={columns}
                values={values}
                perPage={1}
                arrayFilter={transactionFilter}
                links=""
                emptyMessage={t('account:empty_game_details_history')}
                gridType="modal"
                currency={currencyMark[state.parameterInfo[0].currency]}
              />
            </div>
          </section>
          <footer className="modal-card-foot">
            <div className="columns is-mobile is-centered steps-actions">

              <div className="column is-hidden-mobile has-text-centered">
                <button id="close" type="button" className="button button-modal-close has-background-light is-medium is-small-mobile " onClick={e => this.toggleModal(e)}>
                  <i className="icon-close" />
                  &nbsp;&nbsp;
                  {t('buttons:close')}
                  &nbsp;&nbsp;
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

Transactions.propTypes = {
  toggleModalTransactions: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  parameterInfo: PropTypes.instanceOf([]),
};

Transactions.defaultProps = {
  parameterInfo: [],
};

export const mapStateToProps = state => ({
  isOpen: state.transactionModal?.isOpen,
  parameterInfo: state.transactionModal?.parameterInfo,
});

export const mapDispatchToProps = dispatch => ({
  toggleModalTransactions: () => dispatch({ type: 'TOGGLE_MODAL_TRANSACTIONS' }),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(Transactions);
