import React from 'react';
import PropTypes from 'prop-types';
import GameBrowser from '../../Game/GameBrowser/index';

const LoggedInLandingPage = () => (
  <div className="container is-fluid">
    <div className="m-landing-page-section-loggedIn section content">
      <GameBrowser />
    </div>
  </div>
);

LoggedInLandingPage.propTypes = {
  data: PropTypes.shape({
    featured_promotion: PropTypes.instanceOf(Object).isRequired,
    carousel: PropTypes.instanceOf(Array).isRequired,
  }),
};

LoggedInLandingPage.defaultProps = {
  data: {},
};

export default LoggedInLandingPage;
