/* eslint-disable react-hooks/exhaustive-deps */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { withTranslation } from 'react-i18next';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Cookies } from 'react-cookie-banner/lib';
import { AddTrackingValue } from './SignupAddEgass';
import { apiCall } from '../../Helpers';
import { generateHashedUserName, userNameGenerator } from './signupApi';
import dialData from './country-dial-code.json';
import SignupHeader from './SignupHeader';
import SignupSection from './SignupSection';
import SignupFooter from './SignupFooter';
import SignupState from './SignupState';
import { SignupContext } from './context';
import store from '../../../containers/App/store';
import { openModal, Modals } from '../ModalManager';
import getCountry from '../../../containers/utitilies/countries';
import { toggleModalGeneralError } from '../../../redux/actions/generalErrorModalActions';

export const getDialCode = countryCode => (
  dialData.filter(countryInfo => countryInfo.code === countryCode)
);

class Signup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: SignupState.first,
      fields: {},
      consent: {
        consentTerms: {
          value: true,
          consentKey: 'TERMS_AND_CONDITIONS',
        },
        consentPrivacy: {
          value: true,
          consentKey: 'PRIVACY_POLICY',
        },
        consentPromotionsEMAIL: {
          value: true,
          consentKey: 'EMAIL',
        },
      },
      currencyItems: [],
      countriesItems: [],
      sessionKey: null,
      isValidRecaptcha: false,
      isLoading: false,
      verificationType: 'email',
    };
    this.cookies = new Cookies();
    this.signupApi = new Casino.models.Signup();
  }

  componentDidMount() {
    this.getExternalData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { props } = this;
    const { state } = this;

    if (!prevProps.isActive && props.isActive) {
      const restrictedCountries = process.env.REACT_APP_REG_RESTRICTED_COUNTRIES.split(',');

      if (restrictedCountries.includes(state.iso2Code)) {
        openModal(Modals.Signup);
        store.dispatch(toggleModalGeneralError(props.t('errors:no_players_from_your_country')));
        return;
      }

      const [language] = this.getLanguage();

      this.setSignupState({
        ...state,
        sessionKey: props.sessionKey,
        step: props.step,
        fields: {
          ...state.fields,
          email: props.email,
          password: props.password,
          language: language.name,
          birthDate: props.birthDate,
        },
      });
    }

    if (prevState.error !== state.error) {
      setTimeout(() => {
        this.setSignupState(oldState => ({ ...oldState, error: null }));
      }, 4000);
    }
  }

  setCurrencyItems = async (iso2Code) => {
    const { t } = this.props;
    const currencyItemsAll = await new Casino.GetCurrencies(iso2Code).perform();

    if (currencyItemsAll.length !== 0) {
      const currencyItems = currencyItemsAll.map(item => ({ name: t(`currencies:${item}`), value: item }));

      const currency = currencyItemsAll[0] || process.env.REACT_APP_REG_DEFAULT_CURRENCY;

      this.setState(prevState => ({
        ...prevState,
        currencyItems,
        fields: {
          ...prevState.fields,
          currency,
        },
      }));
    }
  }

  setCountryInfo = (countryApi) => {
    countryApi.getCountryInfo().then(async (result) => {
      if (result.success()) {
        const { countryInfo: { iso2Code } } = result.data;
        this.setCurrencyItems(iso2Code, result.data.currency.isoCode);
        let mobile = '';
        const countryInfo = getDialCode(result.data.countryInfo.iso2Code);
        // eslint-disable-next-line no-unused-expressions
        (countryInfo[0] !== undefined) ? mobile = countryInfo[0].dial_code : '';

        const country = result.data.countryInfo.iso2Code;
        this.setState(prevState => ({
          ...prevState,
          iso2Code,
          fields: {
            ...prevState.fields,
            mobile,
            country,
          },
        }));
      }
    });
  }

  setCountriesItems = (countryApi) => {
    countryApi.all().then((result) => {
      if (result.success()) {
        const restrictedCountries = process.env.REACT_APP_REG_RESTRICTED_COUNTRIES.split(',');

        const countriesItems = result.data.countries
          .filter(country => !restrictedCountries.includes(country))
          .map((country) => {
            const countryName = getCountry[country] || country;
            return { value: country, name: countryName };
          });

        this.setState(prevState => ({
          ...prevState,
          countriesItems,
        }));
      }
    });
  }

  getExternalData = async () => {
    const countryApi = new Casino.models.Country();
    this.setCountriesItems(countryApi);

    this.setCountryInfo(countryApi);
  };

  formatCurrencies = currencies => currencies
    .map(currency => ({ name: currency, value: currency }))

  setDialCodeAndCurrency = async (value) => {
    const [country] = getDialCode(value);
    const currencies = await new Casino.GetCurrencies(value).perform();
    const [currency] = currencies;

    if (!country || !currency) return this.setSignupStep(SignupState.error);

    this.setState(prevState => ({
      ...prevState,
      currencyItems: this.formatCurrencies(currencies),
      // eslint-disable-next-line quote-props
      fields: { ...prevState.fields, 'mobile': country.dial_code, currency },
    }));
  }

  setField = async (event) => {
    // eslint-disable-next-line prefer-const
    let { name, value } = event.target;

    if (name === 'mobile' || name === 'mobilePhone') {
      value = `+${value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1')}`;
    }

    if (name === 'country') await this.setDialCodeAndCurrency(value);

    this.setState(
      prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value } }),
    );
  };

  getLanguage = () => {
    const { t } = this.props;
    const { iso2Code } = this.state;

    const languages = [
      { value: 'en', name: t('languages:english') },
    ];

    // eslint-disable-next-line no-unused-vars
    const [defaultLanguage, ...rest] = languages;

    let userLanguage = languages.filter(lang => lang.value === iso2Code?.toLowerCase());

    if (!userLanguage.length) userLanguage = [defaultLanguage];

    return userLanguage;
  }

  onClickNextStep = () => {
    this.setState(prevState => ({ ...prevState, step: prevState.step + 1 }));
  };

  onClickPrevStep = () => {
    this.setState(prevState => ({ ...prevState, step: prevState.step - 1 }));
  };

  setSignupStep = (step) => {
    this.setState(prevState => ({ ...prevState, step }));
  };

  setSignupState = state => this.setState(state);

 resendVerificationCode = async (e, type) => {
   e.preventDefault();

   const { fields } = this.state;

   this.setSignupState({ isLoading: true });

   const userName = generateHashedUserName(fields.email);

   const result = await new Casino.models.ResendVerificationCode(userName, type).perform();

   if (result.success()) {
     this.setSignupState(prevState => ({
       ...prevState,
       showExtraMessage: true,
       verificationResendType: type,
       isLoading: false,
       step: 4,
     }));
   }

   if (result.errors()) {
     this.setSignupState(prevState => ({
       ...prevState,
       error: result.message(),
       isLoading: false,
     }));
   }
 };

  prepareDataForOmega = () => {
    const { i18n } = this.props;
    const { fields, consent } = this.state;
    const { consentPromotionsEMAIL, consentPrivacy, consentTerms } = consent;

    delete fields.confirmEmail;

    const playerDetails = {
      ...fields,
      username: userNameGenerator(fields.email),
      mobile: this.formatMobileForOmega(fields.mobile),
      reveiveEmail: consentPromotionsEMAIL.value,
      acceptPrivacy: consentPrivacy.value,
      consentTerms: consentTerms.value,
      langCode: i18n.languages[1],
      securityQuestion: 'What were the last 4 digits of your childhood telephone number?',
      securityAnswer: '1234',
      extraInfo: '{"SourceOfWealth": ""}',
    };

    const preparedData = AddTrackingValue(playerDetails);

    return preparedData;
  }

  formatMobileForOmega = (mobile) => {
    if (mobile.charAt(0) === '+') return mobile.slice(1);

    return mobile.slice(2);
  };

  signUp = async () => {
    const { t } = this.props;

    const dataForSignup = this.prepareDataForOmega();

    try {
      this.setState({ isLoading: true });

      const result = await apiCall(this.signupApi.signup(dataForSignup));

      if (result.success()) {
        this.setState(prevState => (
          { ...prevState, step: prevState.step + 1, isLoading: false }
        ));
      } else if (result.data.status === 'GENERIC_ERROR') {
        this.setState({ step: SignupState.error, isLoading: false });
      } else {
        this.setState(prevState => (
          {
            ...prevState,
            validationFail: this.formatErrorMessage(result.data, t),
            step: prevState.step + 1,
            isLoading: false,
          }
        ));
      }
    } catch (err) {
      this.setSignupStep(SignupState.error);
    }
  }

  login = async () => {
    const { state } = this;
    const userConsents = {
      tac: true,
      pp: true,
    };

    return new Casino.models.Signin(
      state.fields.email, state.fields.password, null, userConsents,
    ).perform();
  };

  onRecaptchaValidation = () => this.setState(
    prevState => ({ ...prevState, isValidRecaptcha: true }),
  );

  formatErrorMessage = (error, t) => ({
    title: t('common:registration_failed'),
    message: error.message.split(',').map(err => err.split(':').join(' ')).join(', '),
    contact: t('common:please_contact_support'),
  })

  render() {
    const { isActive } = this.props;
    const { isValidRecaptcha, isLoading } = this.state;

    return (
      <>
        <SignupContext.Provider
          value={{
            signupState: this.state,
            setSignupState: this.setSignupState,
            setField: this.setField,
            onClickNextStep: this.onClickNextStep,
            onClickPrevStep: this.onClickPrevStep,
            setSignupStep: this.setSignupStep,
            getLanguage: this.getLanguage,
            resendVerificationCode: this.resendVerificationCode,
            login: this.login,
            signUp: this.signUp,
            getDialCode,
            isValidRecaptcha,
            isLoading,
          }}
        >
          <div id="modal-signup-v2" className={`modal m-modal-base modal-fx-newsPaper m-modal-v2 ${isActive ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div id="signup-modal-content" className="modal-content">
              <SignupHeader />
              <SignupSection />
              <SignupFooter />
            </div>
          </div>
        </SignupContext.Provider>
        <GoogleReCaptcha onVerify={this.onRecaptchaValidation} />
      </>
    );
  }
}

Signup.defaultProps = {
  isActive: false,
  sessionKey: null,
  t: PropTypes.func,
  email: null,
  password: null,
  birthDate: null,
  step: 1,
};

Signup.propTypes = {
  i18n: PropTypes.instanceOf(Object).isRequired,
  isActive: PropTypes.bool,
  t: () => { },
  step: PropTypes.number,
  sessionKey: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  birthDate: PropTypes.string,
};

export default withTranslation()(Signup);
