import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../PageTitle';

const BodySection = ({ title, children, show }) => (
  <section className="section m-account-wrapper">
    <div className="container is-fluid">
      <div className="columns is-centered is-mobile">
        <div className="column is-12-mobile is-12-tablet is-9-widescreen">
          <div className="content typography">
            <PageTitle title={title} show={show} />
          </div>
          {children}
        </div>
      </div>
    </div>
  </section>
);

BodySection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  show: PropTypes.bool,
};

BodySection.defaultProps = {
  title: '',
  children: {},
  show: true,
};

export default BodySection;
