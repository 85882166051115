import React from 'react';
import PropTypes from 'prop-types';
/* istanbul ignore next */
const ArticleHero = ({
  title,
  bgimage,
  headervideo,
  titleimage,
  body,
  excerpt,
}) => (
  <div className={`carousel [ m-carousel m-promo-carousel ] js-carousel ${headervideo ? 'has-video' : ''} flickity-enabled`}>
    <div className="carousel-cell">
      <div className="carousel-bg-image">
        { // eslint-disable-next-line no-nested-ternary
        headervideo?.url
          ? (
            <video muted loop autoPlay playsInline>
              <source src={headervideo.url} type="video/mp4" />
            </video>
        ) : bgimage?.meta?.download_url ? <img src={bgimage.meta.download_url} alt={title} /> : null
        }
      </div>
      <div className="container is-fluid">
        <div className="hero [ m-promotion-hero ]">
          <div className="hero-body has-text-left-widescreen ">
            <div className="hero-title-wrapper content has-text-centered">
              { titleimage?.meta?.download_url ? (
                <div className="hero-title-wrapper content has-text-centered">
                  <img src={titleimage.meta.download_url} alt={title} />
                </div>
              ) : null}
              <div className="columns is-mobile is-centered">
                <div className="column is-narrow">
                  <div dangerouslySetInnerHTML={{ __html: body }} />
                </div>
                <div className="column is-narrow">
                  <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
);

ArticleHero.propTypes = {
  title: PropTypes.string,
  bgimage: PropTypes.instanceOf(Object),
  titleimage: PropTypes.instanceOf(Object),
  headervideo: PropTypes.instanceOf(Object),
  body: PropTypes.string,
  excerpt: PropTypes.string,
};

ArticleHero.defaultProps = {
  title: '',
  bgimage: {},
  titleimage: {},
  headervideo: {},
  body: '',
  excerpt: '',
};

export default ArticleHero;
