import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { goToAnchor, configureAnchors } from 'react-scrollable-anchor';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { action } = useHistory();

  // In case when opened the new page(action POP) with
  // anchor sometimes items are not rendered yet.
  // This is the reason why anchor, jumped to the wrong place.
  const goToAnchorTimeOut = () => (action === 'POP' ? setTimeout(() => goToAnchor('gamesAnchor', false), 1500) : goToAnchor('gamesAnchor', false));

  useEffect(() => {
    if (!pathname.includes('/provider/') && !pathname.includes('/category/')) {
      window.scrollTo(0, 0);
    } else if ((pathname.includes('/provider/') || pathname.includes('/category/')) && ((window.orientation === 90 && window.innerWidth < 846))) {
      configureAnchors({ offset: 1 });
      goToAnchorTimeOut();
    } else if ((pathname.includes('/provider/') || pathname.includes('/category/')) && ((window.orientation === 0 && window.innerWidth < 846))) {
      configureAnchors({ offset: -80 });
      goToAnchorTimeOut();
    } else if ((pathname.includes('/provider/') || pathname.includes('/category/')) && (window.innerWidth > 846)) {
      configureAnchors({ offset: 1 });
      goToAnchorTimeOut();
    }
  // eslint-disable-next-line
  }, [pathname]);

  return null;
}
