import React from 'react';
import PropTypes from 'prop-types';

const LandingPageVideo = ({ videoURL }) => (
  <div className="container is-fluid p-0">
    <div className="m-landing-page-video has-text-centered">
      <video src={videoURL} autoPlay loop muted playsInline />
    </div>
  </div>
);

LandingPageVideo.propTypes = {
  videoURL: PropTypes.string.isRequired,
};

export default LandingPageVideo;
