/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import BucketWidget from './BucketWidget';
import BasePage from '../Base';

const BucketPage = ({ data }) => {
  const { sections } = data;
  return (
    <BasePage data={data}>
      <section className="section m-page-wrapper m-support-bg" style={{ backgroundImage: `url(${data.page_background_image?.meta.download_url})` }}>
        <div className="container is-fluid">
          <div className="columns is-multiline is-centered">
            <div className="column is-12-mobile is-12-tablet is-9-widescreen">
              <div className="content typography">
                <h1 className="has-text-primary is-size-2">{ data.title }</h1>
                <p dangerouslySetInnerHTML={{ __html: data.description }} />
              </div>
            </div>
          </div>
          <div className="columns is-multiline is-centered">
            <div className="column is-12-mobile is-11-tablet is-9-widescreen">
              <div className="columns is-multiline m-bucket-page-items is-centered">
                {sections.map(item => <BucketWidget key={item} data={item} />)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </BasePage>
  );
};

BucketPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default BucketPage;
