import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { handleClickOutside } from '../../Helpers';

export let checkActiveItems = {};

const ArticleMenu = ({ handle, title }) => {
  const [isActive, setIsActive] = useState(false);
  const [items, setItems] = useState([]);
  const menuRef = useRef(null);
  const itemsRef = useRef(null);
  const [hasActiveItems, setHasActiveItems] = useState(false);

  checkActiveItems = () => {
    const navbarItems = itemsRef.current.childNodes;
    for (const item of navbarItems) {
      if (item.classList.contains('is-active')) {
        setHasActiveItems(true);
        break;
      }
    }
    return navbarItems;
  };

  useEffect(() => {
    handleClickOutside(menuRef, () => setIsActive(false));
    const fetchItems = async () => {
      const result = await new Casino.FetchMenu(handle).perform();
      setItems(result.items());
      checkActiveItems();
    };
    fetchItems();
  }, [menuRef, handle]);

  const menuItems = items.map(item => (<NavLink key={item.id} className="navbar-item" exact activeClassName="is-active" to={item.url}>{item.label}</NavLink>));

  return (
    <div className="m-promo-nav container is-fluid">
      <div className="level is-mobile">
        <div className="level-left">
          <nav className="navbar" role="navigation" aria-label="game navigation" ref={menuRef}>
            <div className="navbar-start">
              <button onClick={() => setIsActive(!isActive)} type="button" className={`m-game-nav-button is-flex-touch is-hidden-desktop ${isActive ? ' is-active' : ''}`} aria-label="game-menu-button" aria-expanded="false" data-menu-target="promotionsNav">
                <div className="is-size-6" aria-label="game-menu-label">{title}</div>
                <div className={`navbar-burger burger is-flex-touch is-static ${isActive ? ' is-active' : ''}`}>
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </div>
              </button>
              <div ref={itemsRef} className={`navbar-menu m-game-nav-menu is-size-5-widescreen ${hasActiveItems ? ' has-active-items' : ''} ${isActive ? ' is-active' : ''}`} id="promotionsNav">
                { menuItems }
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

ArticleMenu.propTypes = {
  handle: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ArticleMenu.defaultProps = {
  title: '',
};

export default ArticleMenu;
