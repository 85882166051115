import React, { lazy, PureComponent } from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import Casino, { context as casinoContext } from '@dialinvest/react-casino';
import { withTranslation } from 'react-i18next';
import BlogPage from '../../components/Page/Blog/BlogPage';
import AccountPage from '../../components/Page/Account/LandingPage';
import HomePage from '../../components/Page/HomePage';
import Page404 from '../../components/Page404';
import TextPage from '../../components/Page/TextPage';
import PageLoader from '../../components/PageLoader';
import EGASS from '../EGASS/index';
import PromotionPage from '../../components/Page/Promotion/PromotionPage';
import ArticlePage from '../../components/Page/Article/ArticlePage';
import BucketPage from '../../components/Page/Bucket/BucketPage';
import BankingPage from '../../components/Page/BankingPage';
import KnowledgePage from '../../components/Page/KnowledgePage';
import SupportSocialPage from '../../components/Page/SocialPage';
import VIPPage from '../../components/Page/VipPage';
import LoyaltyPage from '../../components/Page/LoyaltyPage';
import TournamentPage from '../../components/Page/TournamentsPage';
import SupportEmailPage from '../../components/Page/EmailPage';
import InternalPage from '../../components/Page/InternalPage';
import FilteredGamePage from '../../components/Page/Game/FilteredGamePage';
import GamesPage from '../../components/Page/GamesPage';
import GameProviderlist from '../../components/Page/GameProviderList';
import ModalPage from '../../components/Page/ModalPage';
import store from '../App/store';
import { toggleModalIn } from '../../redux/actions/signInModalActions';
import { getQueryParam } from '../../components/Helpers';

const AccountEditPage = lazy(() => import('../../components/Page/Account/EditPage'));
const AccountGameHistory = lazy(() => import('../../components/Page/Account/GameHistory'));
const AccountTransactionHistory = lazy(() => import('../../components/Page/Account/TransactionHistory'));
const AccountWithdraw = lazy(() => import('../../components/Page/Account/WithDrawal'));
const AccountVerificationPage = lazy(() => import('../../components/Page/Account/VerificationPage'));
const AccountBonuses = lazy(() => import('../../components/Page/Account/BonusPage'));
const AccountMyLimits = lazy(() => import('../../components/Page/Account/MyLimits'));

const Components = {
  BlogPage,
  BlogCMSPage: BlogPage,
  HomePage,
  AccountPage,
  AccountEditPage,
  AccountTransactionHistory,
  AccountGameHistory,
  AccountWithdraw,
  Page404,
  TextPage,
  AccountBonuses,
  ArticlePage,
  PromotionPage,
  PageLoader,
  BankingPage,
  BucketPage,
  KnowledgePage,
  AccountMyLimits,
  AccountVerificationPage,
  SupportSocialPage,
  SupportEmailPage,
  VIPPage,
  LoyaltyPage,
  TournamentPage,
  InternalPage,
  GameProviderPage: FilteredGamePage,
  GameCategoryPage: FilteredGamePage,
  GamePage: GamesPage,
  GameProviderlist,
  ModalPage,
};

const NotFound = ({ type }) => (
  <div className="content has-text-centered step-content is-active is-centered">
    <div className="notification">
      <progress className="progress is-small is-success is-primary" max="100" />
      <h1>{`The ${type} component has not been created yet.`}</h1>
    </div>
  </div>
);

NotFound.propTypes = {
  type: PropTypes.string,
};

NotFound.defaultProps = {
  type: '',
};

export const NotAvailable = ({ t }) => (
  <div className="content has-text-centered step-content is-active is-centered not-available-page">
    <div className="notification">
      <h1>{ t('common:you_must_be_logged_in') }</h1>
    </div>
  </div>
);

NotAvailable.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
};

export class PageRenderer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      loading: true,
    };
    this.cookies = new Cookies();
  }

  componentDidMount() {
    const { location, match } = this.props;
    const egas = new EGASS();
    egas.call(location);

    if (this.checkLocaleForRedirect(location.pathname)) return;

    const path = this.modifyPath(match, location.pathname);
    this.fetchContent(path);

    if (location.search
      && location.search.toLowerCase().split('=')[0] === '?search') {
      this.setState(prevState => ({ ...prevState, searchParams: location.search }));
    }
  }

  componentDidUpdate(prevProps) {
    const { location, match } = this.props;
    const { loading } = this.state;

    if (location.pathname === prevProps.location.pathname) return;
    if (this.checkLocaleForRedirect(location.pathname)) return;
    if (match.path !== '/' && loading === false) return;

    const path = this.modifyPath(match, location.pathname);
    this.fetchContent(path);
  }

  modifyPath = (match, pathname) => {
    let path = pathname;

    if (match.path !== '/') {
      path = match.params['0'] !== '' ? match.params['0'] : '/';
    }

    const userPath = this.checkKycStatusAndModifyPath(path);

    return userPath;
  };

  setDocumentTitle = (title) => {
    if (title.trim() === '') return;
    document.title = title;
  };

  adjustRedirection = (language, pathname, search) => {
    const { history } = this.props;

    history.replace({
      pathname: `/${language}${pathname}`,
      search,
    });
  }

  checkLocaleForRedirect = (pathname) => {
    const { i18n, history } = this.props;
    const { availableLocales } = store.getState().locales;

    if (!pathname.includes(`/${i18n.language}`)) {
      const locale = availableLocales.find(item => pathname.includes(`/${item.locale}`));
      if (locale && locale.locale !== i18n.language) {
        window.location.pathname = `/${locale.locale}`;
        return true;
      }

      this.adjustRedirection(i18n.language, pathname, history.location.search);
      return true;
    }

    return false;
  };

  fetchContent = async (path) => {
    const { location } = this.props;
    this.setState({ loading: true });

    let content;
    try {
      content = await new Casino.FetchPage(path).perform();
      if (content.data.message === 'not found') {
        await this.checkRedirect(location.pathname);
        return;
      }
    } catch (error) {
      await this.checkRedirect(location.pathname);
      return;
    }

    this.setState({ content, loading: false });
  };

  checkRedirect = async (path) => {
    const { history } = this.props;
    const content = await new Casino.FetchRedirect(path).perform();
    if (content.data.redirect_page !== undefined && content.data.redirect_page !== null) {
      const splittedUrl = content.data.redirect_page.meta.detail_url.split('v2/');
      const newPageContent = await new Casino.FetchContent(splittedUrl[1]).perform();
      history.push(newPageContent.data.meta.slug);
    } else if (content.data.redirect_link && content.data.redirect_link !== '') {
      window.open(content.data.redirect_link, '_self');
    } else {
      this.setState({ content, loading: false });
    }
  };

  checkPermission = (data) => {
    const { t } = this.props;
    if (!!getQueryParam('bonus', window.location.search) && window.location.pathname.includes((t('buttons:cashier')).toLowerCase())) return false;

    if (data.meta.show_when_logged_in) {
      if (this.cookies.get('Session') !== undefined && this.cookies.get('Session') !== '') {
        return false;
      }
      return true;
    }
    return false;
  };

  historyBack = (e) => {
    e.preventDefault();
    const { history } = this.props;
    if (history.action === 'PUSH') {
      history.push('/');
    } else {
      history.go(-1);
    }

    return false;
  };

  checkKycStatusAndModifyPath(path) {
    const {
      location, user, t,
    } = this.props;

    if (user?.kycStatus === 'REFER'
      && location.pathname !== t('common:verification_page_link')
    ) {
      return t('common:verification_page_link');
    }

    return path;
  }

  render() {
    const { context, props } = this;
    const { content, loading, searchParams } = this.state;
    if (loading) return <Components.PageLoader />;

    if (content === null || content.found === undefined || !content.found()) {
      this.setDocumentTitle('Spinnalot Frontend');
      context.setPageType('404');
      /* istanbul ignore next */
      return <Components.Page404 onClick={this.historyBack} />;
    }

    const type = content.type();
    context.setPageType(type);

    context.setLayoutItemVisibility({
      footer: content.data.meta.show_footer,
      topMenu: content.data.meta.show_top_menu,
    });

    if (this.checkPermission(content.data)) {
      store.dispatch(toggleModalIn(null, 'no_permission'));
      return <NotAvailable t={props.t} />;
    }

    if (typeof Components[type] === 'undefined') return <NotFound type={type} />;
    if (type === 'GameProviderPage' || type === 'GameCategoryPage') {
      return React.createElement(Components[type], { data: content.data, searchParams });
    }
    return React.createElement(Components[type], { data: content.data, searchParams });
  }
}

PageRenderer.contextType = casinoContext.PageContext;

PageRenderer.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  pathname: PropTypes.string,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

PageRenderer.defaultProps = {
  pathname: undefined,
};

export default withTranslation()(PageRenderer);
