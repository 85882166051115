import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getPathname } from '../Helpers';

const PromoBlock = ({ item }) => {
  const history = useHistory();
  const headervideo = item?.background_video?.url;
  const bgimage = item?.background_image?.meta?.download_url;
  const foregroundImage = item?.foreground_image?.meta?.download_url;
  const button = {
    label: item?.button_label,
    url: item?.button_url,
    color: item?.button_color,
    outline_color: item?.button_outline_color,
    link: item?.click_target?.html_url,
    externalLink: item?.click_external_link,
  };
  const body = item?.text;
  const Container = button?.link ? Link : 'section';
  return (
    <Container
      to={button.link ? getPathname(button.link) : ''}
      onClick={() => {
        if (button?.externalLink) {
          const url = new URL(button?.externalLink);
          history.push({ pathname: url.pathname, search: url.search });
        }
      }}
      className={`carousel [ m-carousel m-promo-carousel ${
        button.label || body || headervideo ? 'm-promo-block-carousel' : ''
      } ] js-carousel flickity-enabled`}
      style={{ cursor: button?.externalLink ? 'pointer' : undefined }}
    >
      <div className="carousel-cell">
        <div
          className="carousel-bg-image"
          style={{ backgroundImage: `url(${bgimage})` }}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            headervideo ? (
              <video muted loop autoPlay playsInline>
                <source src={headervideo} type="video/mp4" />
              </video>
            ) : bgimage ? (
              <img src={bgimage} alt="" />
            ) : null
          }
        </div>
        <div
          className={`${
            button.label && !body ? 'm-button-only' : ''
          } container is-fluid `}
        >
          <div className="hero [ m-promotion-hero ]">
            <div className="hero-body has-text-left-widescreen ">
              {foregroundImage || headervideo ? (
                <div className="hero-title-wrapper has-text-centered">
                  {foregroundImage ? (
                    <img
                      className="m-promo-block-foreground-image"
                      src={foregroundImage}
                      alt=""
                    />
                  ) : null}
                  <div className="has-text-centered">
                    {body ? (
                      <div
                        className="m-promo-block-content"
                        dangerouslySetInnerHTML={{ __html: body }}
                      />
                    ) : null}
                    {button.label ? (
                      <div
                        style={{
                          backgroundColor: button.color,
                          color: button.outline_color,
                        }}
                        className="button m-game-grid-button is-size-5-touch is-size-4-tablet is-outlined is-primary is-centered is-uppercase"
                      >
                        {button.label}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

PromoBlock.defaultProps = {
  item: PropTypes.instanceOf(Object),
};

PromoBlock.propTypes = {
  item: PropTypes.instanceOf(Object),
};

export default PromoBlock;
