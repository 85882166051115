import React from 'react';
import PropTypes from 'prop-types';
import ArticleThumbnail, { PlaceholderThumbnail } from './ArticleThumbnail';

export const PlaceholderThumbnailList = () => (
  <div className="columns is-multiline is-mobile m-promo-columns">
    <PlaceholderThumbnail />
  </div>
);

const ArticleThumbnailList = ({
  items, title, needDay, type,
}) => (
  <>
    {title
    && (
      <div className="m-grid-row-seperator">
        <h2 className="is-size-4 has-text-primary has-text-weight-semibold">{title}</h2>
      </div>
    )}
    <div className={`columns is-multiline is-mobile  ${type === 'vip' ? 'm-vip-calendar' : 'm-promo-columns'}`}>
      {items.map(item => (
        <ArticleThumbnail
          key={item.id}
          data={item}
          needDay={needDay}
          type={type}
        />
      ))}
    </div>
  </>
);

ArticleThumbnailList.propTypes = {
  items: PropTypes.instanceOf(Array),
  needDay: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
};

ArticleThumbnailList.defaultProps = {
  items: [],
  needDay: false,
  title: null,
  type: 'promo',
};

export default ArticleThumbnailList;
