import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import BlogWidgetItem from './BlogWidgetItem';
import WidgetLink from '../WidgetLink';
import Skeleton from '../../Layout/Skeleton';

const BlogWidget = ({ id, limit, linkTo }) => {
  const { t } = useTranslation('common');
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const today = moment().format('YYYY-MM-DD');
      const result = await new Casino.FetchContent(`pages/articles/?child_of=${id}&fields=body,excerpt,valid_from,valid_to,thumbnail,title,first_published_at&limit=${limit}&order=-first_published_at&valid_to__gte=${today}&valid_from__lte=${today}`).perform();
      setBlogPosts(result.items());
    };

    fetchArticles();
  }, [id, limit]);

  const widgetItems = blogPosts ? blogPosts.map(
    item => <BlogWidgetItem key={item.id} data={item} />,
  ) : [];

  let widgetBody;

  if (widgetItems.length > 0) {
    widgetBody = (
      <>
        {widgetItems}
        <div className="m-promo-widget-more-link card-content column is-12">
          <WidgetLink href={linkTo} text={`${t('all')} ${t('blog_items')} `} />
        </div>
      </>
    );
  } else {
    widgetBody = (
      <Skeleton style={{
        height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-50px',
      }}
      />
    );
  }

  return (
    <div className="column m-promo-columns-column-aside is-hidden-touch">
      <aside className="m-promo-columns-aside">
        <div className="m-promo-calendar-widget m-blog-list has-background-primary columns is-multiline is-mobile is-centered">
          <div className="card-content column is-12">
            <h5 className="m-title-font has-text-dark">{`${t('blog_items')}:`}</h5>
          </div>
          {widgetBody}
        </div>
      </aside>
    </div>
  );
};

BlogWidget.propTypes = {
  id: PropTypes.number,
  limit: PropTypes.number,
  linkTo: PropTypes.string,
};

BlogWidget.defaultProps = {
  id: null,
  limit: 5,
  linkTo: '/#',
};

export default BlogWidget;
