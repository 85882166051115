import React from 'react';
import PropTypes from 'prop-types';

const ArticleHeader = ({ title, excerpt, titleimage }) => (
  <header className="m-promo-blog-article-title">
    { titleimage && true
    && (
      <div className="hero-title-wrapper content has-text-centered">
        <img src={titleimage} alt={title} />
      </div>
    )}
    { title !== ''
    && (
      <h1 className="title is-size-4-mobile is-size-2-tablet is-uppercase tier-padding" dangerouslySetInnerHTML={{ __html: title }} />
    )}
    <h3 className="m-promo-blog-article-subtitle is-size-4-mobile is-size-3-tablet tier-padding" dangerouslySetInnerHTML={{ __html: excerpt }} />
  </header>
);

ArticleHeader.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  titleimage: PropTypes.string,
};

ArticleHeader.defaultProps = {
  title: '',
  excerpt: '',
  titleimage: '',
};

export default ArticleHeader;
