import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { clipHTMLBody } from '../../Helpers';

class TextArea extends PureComponent {
  handleSignup(event) {
    const { onToggleModal } = this.props;
    event.preventDefault();
    onToggleModal();
    clipHTMLBody();
  }

  render() {
    const {
      title, contentTitle, body, t,
    } = this.props;

    return (
      <div className="m-landing-page-section section content has-text-centered">
        <h1 className="m-landing-page-section__title has-text-primary is-uppercase" dangerouslySetInnerHTML={{ __html: title }} />
        <h2 className="is-size-5 is-size-3-tablet is-size-2-desktop is-uppercase mb-4 has-text-white mt-0" dangerouslySetInnerHTML={{ __html: contentTitle }} />
        <p className="has-text-centered is-size-6 is-size-4-tablet is-size-3-desktop has-text-weight-semibold" dangerouslySetInnerHTML={{ __html: body }} />
        <button
          type="button"
          data-modal-target="modal-signup"
          id="modal-signup-button"
          onClick={e => this.handleSignup(e)}
          className="button has-text-white is-outlined is-rounded"
        >
          { t('buttons:start') }
        </button>
      </div>
    );
  }
}

TextArea.propTypes = {
  title: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  onToggleModal: () => dispatch({ type: 'TOGGLE_MODAL' }),
});

export default compose(
  connect(null, mapDispatchToProps),
)(TextArea);
