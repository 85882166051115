import Casino from '@dialinvest/react-casino';
import store from './store';
import { clearUserData } from '../../redux/actions/userActions';
import { soliticsPing } from '../Solitics';

export default class TimeOut {
  constructor(cookies) {
    this.cookies = cookies;
    this.casino = new Casino.models.Logout('FRONTEND');
  }

  timeOut() {
    this.casino.perform();
    this.openModal();

    return true;
  }

  openModal = () => {
    if (document.cookie.indexOf('Session') >= 0) {
      setTimeout(() => this.openModal(), 100);
    } else {
      store.dispatch(clearUserData());
      window.location.href = '/';
    }
  }

  extendCookie = () => {
    const date = new Date();
    const time = Number(process.env.REACT_APP_TIMEOUT_MINUTES) * 60000;

    date.setTime(date.getTime() + time);

    const { userLoggedIn, ...session } = store.getState().userState;

    soliticsPing();
    if (session?.sessionKey) this.cookies.set('Session', JSON.stringify(session), { path: '/', expires: date });
  };
}
