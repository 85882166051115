import Axios from 'axios';

class OmegaApi {
  constructor() {
    this.client = Axios.create({
      baseURL: process.env.REACT_APP_API_OMEGA_URL,
      timeout: 7000,
    });
  }
}

const omegaApi = new OmegaApi();

export default omegaApi;
