const validatorRules = {
  password: {
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
    minLength: 8,
    maxLength: 16,
  },
  email: {
    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    minLength: 6,
    maxLength: 254,
  },
};

export default validatorRules;
