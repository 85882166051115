import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ title }) => (
  <div className="is-hidden-mobile section content">
    <h2 className="is-size-6 is-size-2-tablet is-primary is-uppercase has-text-centered" dangerouslySetInnerHTML={{ __html: title }} />
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
