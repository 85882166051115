import React from 'react';
import PropTypes from 'prop-types';
import { CreateSelectItems } from './TournamentsPageHelper';

const TournamentsPageHeader = ({
  data,
  handleMenuClick,
  showMenu,
  t,
  i18n,
}) => (
  <>
    {data.banner && (
    <section className="hero m-tournament-hero">
      <p
        className="m-tournaments-hero-title has-background-primary is-size-1 is-size-4-mobile is-capitalized has-text-weight-bold"
      >
        {data.title}
      </p>
      <img
        className="m-tournament-hero-img"
        height="940"
        src={data.banner.meta.download_url}
        alt=""
      />
    </section>
    )}

    <div className="m-tournament-nav container is-fluid">
      <div className="level is-mobile">
        <div className="level-left">
          <nav className="navbar" role="navigation" aria-label="tournament navigation" onClick={showMenu}>
            <div className="navbar-start">
              <button
                className="m-tournament-nav-button is-flex-touch is-hidden-desktop"
                aria-label="tournament-menu-button"
                aria-expanded="false"
                data-menu-target="tournamentsNav"
                type="button"
              >
                <div className="is-size-6" aria-label="tournament-menu-label">{data.title}</div>
                <div className="navbar-burger burger is-flex-touch is-static">
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </div>
              </button>
              <div className="navbar-menu m-tournament-nav-menu is-size-6 mt-4" id="tournamentsNav">
                <CreateSelectItems t={t} i18n={i18n} handleMenuClick={handleMenuClick} />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </>
);

TournamentsPageHeader.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    description: PropTypes.string.isRequired,
    meta: PropTypes.instanceOf(Object),
  }).isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
  i18n: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]).isRequired,
  t: PropTypes.func.isRequired,
};

export default TournamentsPageHeader;
