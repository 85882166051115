import i18next from 'i18next';
import Casino from '@dialinvest/react-casino';
import moment from 'moment';
import validatorRules from './validator_rules';
import { apiCall } from '../../components/Helpers';

const maxDateForCalendar = moment().subtract(process.env.REACT_APP_AGE_RESTRICTION, 'years').format('YYYY-MM-DD');
const calendarPattern = /^\d{4}-\d{2}-\d{2}$/;

const validate = (values) => {
  const errors = {};
  const { password, email } = validatorRules;

  if (values.password) {
    if (!password.pattern.test(values.password)) {
      errors.password = i18next.t('errors:password');
    } else if (
      values.password.length < password.minLength || values.password.length > password.maxLength
    ) {
      errors.password = i18next.t('errors:INVALID_LENGTH');
    }
  } else {
    errors.password = i18next.t('errors:required');
  }

  if (!values.confirmEmail) {
    errors.confirmEmail = i18next.t('errors:required');
  }

  if (values.confirmEmail !== values.email || (values.email && !email.pattern.test(values.email))) {
    errors.confirmEmail = i18next.t('errors:confirm_email_address');
  }

  if (values.confirmEmail === values.email && (values.email && !email.pattern.test(values.email))) {
    errors.confirmEmail = i18next.t('errors:can_not_confirm_email_address');
  }

  if (values.birthDate) {
    const date = values.birthDate.target;
    if (!calendarPattern.test(date?.value)) {
      errors.birthDate = i18next.t('errors:required_must_be_older_than_18');
    } else if (!moment(date?.value).isBefore(maxDateForCalendar)) {
      errors.birthDate = i18next.t('errors:required_must_be_older_than_18');
    }
  } else {
    errors.birthDate = i18next.t('errors:required');
  }

  return errors;
};

export const asyncValidate = async (values) => {
  if (values.email) {
    const { email } = validatorRules;
    if (!email.pattern.test(values.email)) {
      // eslint-disable-next-line no-throw-literal
      throw { email: i18next.t('errors:email') };
    } else if (values.email.length <= email.minLength || values.email.length >= email.maxLength) {
      // eslint-disable-next-line no-throw-literal
      throw { email: i18next.t('errors:INVALID_LENGTH') };
    } else {
      const signup = new Casino.models.Signup();
      await apiCall(signup.signup({
        email: values.email,
      }))
        .then((response) => {
          if (response.data.errors && response.data.errors.find(item => item.field === 'email')) {
            // eslint-disable-next-line no-throw-literal
            throw { email: i18next.t('errors:DUPLICATE') };
          }
        });
    }
  } else {
    // eslint-disable-next-line no-throw-literal
    throw { email: i18next.t('errors:required') };
  }
};

export default validate;
