import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import CarouselItem from './CarouselItem';

gsap.config({
  autoSleep: 60,
  force3D: false,
  nullTargetWarn: false,
  units: { left: '%', top: '%', rotation: 'rad' },
});

class Carousel extends PureComponent {
  constructor(props) {
    super(props);

    this.logoCarousel = React.createRef();
  }

  componentDidMount() {
    this.logoCarouselsInit();
  }

  logoCarouselsInit = () => {
    if (!this.logoCarousel.current) return;
    this.logoCarouselsAnimation();
  }

  /* istanbul ignore next */
  logoCarouselsAnimation = () => {
    const logocarousels = this.logoCarousel.current.childNodes;

    if (!logocarousels) return;

    gsap.set('.m-logo-carousel__logos', { xPercent: -50, yPercent: -50, opacity: 1 });

    const boxWidth = 240;
    const totalWidth = boxWidth * logocarousels.length; // * n of boxes + diff textBox
    const time = 0.5;
    const box = document.querySelectorAll('.m-logo-carousel__logo');
    const dirFromLeft = `+=${boxWidth}`;

    const mod = gsap.utils.wrap(0, totalWidth);

    gsap.set(box, {
      x(i) {
        return i * boxWidth;
      },
    });

    gsap.timeline()
      .to(box, {
        x: dirFromLeft,
        modifiers: {
          x: x => `${mod(parseFloat(x))}px`,
        },
        duration: time,
        ease: 'back',
        repeat: -1,
        repeatRefresh: true,
        repeatDelay: 3,
      });
  }

  render() {
    const { logos } = this.props;

    const carouselLogos = logos?.map(
      ({ logo }) => logo && <CarouselItem key={logo.id} url={logo?.meta?.download_url} />,
    );

    return (
      <div className="m-logo-carousel is-visible-tablet">
        <div id="logocarousel" className="m-logo-carousel__logos" ref={this.logoCarousel}>
          {carouselLogos}
        </div>
      </div>
    );
  }
}

Carousel.propTypes = {
  logos: PropTypes.instanceOf(Array).isRequired,
};

export default Carousel;
