import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../Layout/Image';
import { isUserLoggedIn } from '../../Helpers';

const CarouselCell = ({
  imageSource,
  onClick,
  buttonLabel,
  buttonColor,
  buttonBackgroundColor,
  titleColor,
  title,
  description,
  showContent,
}) => {
  const media = (
    <Image src={imageSource} />
  );

  return (
    <div className="carousel-cell">
      <div className="carousel-bg-image">
        {media}
      </div>
      {showContent
        && (
        <div className="container is-fluid">
          <div className="hero">
            <div className="hero-body has-text-centered has-text-left-widescreen content">
              <h1
                className="hero-title is-size-1 is-size-2-mobile m-vip-carousel-title m-title-font"
                ref={
                  (el) => {
                    if (el) {
                      el.style.setProperty('color', titleColor, 'important');
                    }
                  }
                }
              >
                {title}
              </h1>
              <br />
              <h2 className="hero-sub-title has-text-dark has-text-shadow-white is-size-4 is-size-5-mobile m-vip-carousel-sub-title" dangerouslySetInnerHTML={{ __html: description }} />
              {/* OMG-2120 - Apply Vip button is removed temporary. */}
              {!isUserLoggedIn()
              && (
              <>
                <br />
                <a
                  href="/#"
                  data-modal-target="modal-signup"
                  id="carousel-signup-button"
                  className="button is-dark-green is-uppercase is-size-4 is-size-5-mobile"
                  ref={
                    (el) => {
                      if (el) {
                        el.style.setProperty('color', buttonColor, 'important');
                        el.style.setProperty('background-color', buttonBackgroundColor, 'important');
                        el.style.setProperty('border-color', buttonBackgroundColor, 'important');
                      }
                    }
                  }
                  onClick={e => onClick(e)}
                >
                  {buttonLabel}
                </a>
              </>
              )}
            </div>
          </div>
        </div>
        )}
    </div>
  );
};

CarouselCell.propTypes = {
  imageSource: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonBackgroundColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
};

export default CarouselCell;
