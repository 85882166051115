import Casino from '@dialinvest/react-casino';

class EGASS {
  call = (location) => {
    const params = new URLSearchParams(location.search);
    const clickidField = 'click_id';
    const affidField = 'affiliate_id';
    let cookieContent;
    if (params.has(clickidField)
      && document.cookie.indexOf(process.env.REACT_APP_EGASS_COOKIE_NAME) <= 0) {
      (params.has(affidField))
        ? cookieContent = {
          ClickId: new URLSearchParams(location.search).get(clickidField),
          affId: new URLSearchParams(location.search).get(affidField),
        }
        : cookieContent = {
          ClickId: new URLSearchParams(location.search).get(clickidField),
        };

      // 👇️              day  hour  min  sec  ms
      const expiration = 30 * 24 * 60 * 60 * 1000;

      new Casino.Cookie().set(process.env.REACT_APP_EGASS_COOKIE_NAME, cookieContent, expiration);
    }
  };
}
export default EGASS;
