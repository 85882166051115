import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import useCategoriesAndProviders from '../../hooks/games/useCategoriesAndProviders';
import useGamesController from '../../hooks/games/useGamesController';
import useGamesUpdater from '../../hooks/games/useGamesUpdater';
import useFavoriteGamesController from '../../hooks/games/useFavoriteGamesController';
import useGamesAnchor from '../../hooks/games/useGamesAnchor';

function GamesInitializer(props) {
  const { location } = props;
  useCategoriesAndProviders(location.search);
  useGamesController(location);
  useFavoriteGamesController();
  useGamesUpdater();
  useGamesAnchor();

  return null;
}

GamesInitializer.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(GamesInitializer);
