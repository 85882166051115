/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, initialize, change,
} from 'redux-form';
import { useSignupContext } from './context';
import {
  InputComponent,
  InputPasswordComponent,
  DateSelectsComponent,
  LanguageSelectorComponent,
} from './inputs';
import validate, { asyncValidate } from '../../../containers/utitilies/validator_signup_first_step';
import store from '../../../containers/App/store';

const SignupFirstStep = (props) => {
  const { invalid, asyncValidating } = props;
  const {
    setField, signupState, onClickNextStep, isValidRecaptcha,
  } = useSignupContext();

  const { language } = signupState.fields;

  const { t } = useTranslation();

  useEffect(() => {
    store.dispatch(initialize('SignupFirstStepForm', {
      email: signupState.fields.email,
      password: signupState.fields.password,
      confirmEmail: signupState.fields.confirmEmail,
      birthDate: { target: { value: signupState.fields.birthDate } },
      language: signupState.fields.language,
    }));
  }, []);

  useEffect(() => {
    if (language) {
      setField({
        target: {
          name: 'language',
          value: language,
        },
      });
    }

    store.dispatch(change('SignupFirstStepForm', 'language', language));
  }, [language]);

  const setBirthDate = (event) => {
    const { value } = event.target;
    setField({
      target: {
        name: 'birthDate',
        value,
      },
    });
  };

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-6">
          <div className="field">
            <label className="label is-size-5">{t('signup:email_address')}</label>
            <div className="control has-icons-right">
              <Field
                className="input is-medium"
                type="search"
                id="email"
                name="email"
                onChange={setField}
                component={InputComponent}
              />
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="field">
            <label className="label is-size-5">{t('signup:confirm_email_address')}</label>
            <div className="control has-icons-right">
              <Field
                className="input is-medium"
                type="search"
                id="confirmEmail"
                name="confirmEmail"
                onChange={setField}
                component={InputComponent}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-6">
          <div className="field">
            <label className="label is-size-5">{t('common:choose_a_password')}</label>
            <div className="control has-icons-right custom-password-field">
              <Field
                className=""
                type="text"
                name="password"
                onChange={setField}
                component={InputPasswordComponent}
              />
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="field">
            <label className="label is-size-5">{t('common:language')}</label>
            <div className="control has-icons-right">
              <Field
                className="input is-medium"
                id="language"
                name="language"
                onChange={setField}
                component={LanguageSelectorComponent}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-8">
          <Field
            className="input is-medium"
            type="text"
            id="setBirthDateButton"
            name="birthDate"
            onChange={setBirthDate}
            component={DateSelectsComponent}
          />
        </div>
      </div>
      <div className="buttons">
        <button
          type="submit"
          id="onContinue"
          disabled={invalid || asyncValidating || !isValidRecaptcha}
          onClick={onClickNextStep}
          className="button is-centered has-text-primary is-size-5"
        >
          {t('signup:continue')}
        </button>
      </div>
    </>
  );
};

SignupFirstStep.propTypes = {
  invalid: PropTypes.bool.isRequired,
  asyncValidating: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),

};

SignupFirstStep.defaultProps = {
  asyncValidating: false || '',
};

export default reduxForm({
  form: 'SignupFirstStepForm',
  validate,
  asyncValidate,
  asyncChangeFields: ['email'],
})(SignupFirstStep);
