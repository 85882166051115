import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { toggleModalProviderSearch } from '../../../redux/actions/providerSearchModalActions';
import GameProviderModal, { resetModal } from './GameProviderModal';
import useGames from '../../../hooks/games/useGames';

const ProviderSearch = (props) => {
  const { isOpen } = props;
  const { providers } = useGames();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27 && isOpen) {
        resetModal();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen]);

  return (
    <div id="modal-provider-search" className={`modal m-modal-base modal-fx-superScaled ${isOpen ? 'is-active' : 'is-inactive'}`}>
      <div role="presentation" className="modal-background" onClick={resetModal} />
      <div className="modal-card modal-content">
        <GameProviderModal menuItems={providers.items} isOpen={isOpen} />
      </div>
    </div>
  );
};

ProviderSearch.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  isOpen: state.providerModal?.isOpen,
});

export const mapDispatchToProps = dispatch => ({
  toggleProviderSearch: () => dispatch(toggleModalProviderSearch()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(ProviderSearch);
