import accountEn from './en/account.json';
import buttonsEn from './en/buttons.json';
import commonEn from './en/common.json';
import errorsEn from './en/errors.json';
import fieldsEn from './en/fields.json';
import currenciesEn from './en/currencies.json';
import languagesEn from './en/languages.json';
import securityQuestionsEn from './en/securityQuestions.json';
import signup from './en/signup.json';

import accountCa from './ca/account.json';
import buttonsCa from './ca/buttons.json';
import commonCa from './ca/common.json';
import errorsCa from './ca/errors.json';
import fieldsCa from './ca/fields.json';
import currenciesCa from './ca/currencies.json';
import languagesCa from './ca/languages.json';
import securityQuestionsCa from './ca/securityQuestions.json';

import accountDe from './de/account.json';
import buttonsDe from './de/buttons.json';
import commonDe from './de/common.json';
import errorsDe from './de/errors.json';
import fieldsDe from './de/fields.json';
import currenciesDe from './de/currencies.json';
import languagesDe from './de/languages.json';
import securityQuestionsDe from './de/securityQuestions.json';

export default {
  en: {
    account: accountEn,
    buttons: buttonsEn,
    common: commonEn,
    errors: errorsEn,
    fields: fieldsEn,
    currencies: currenciesEn,
    languages: languagesEn,
    security_questions: securityQuestionsEn,
    signup,
  },
  'en-CA': {
    account: accountCa,
    buttons: buttonsCa,
    common: commonCa,
    errors: errorsCa,
    fields: fieldsCa,
    currencies: currenciesCa,
    languages: languagesCa,
    security_questions: securityQuestionsCa,
  },
  de: {
    account: accountDe,
    buttons: buttonsDe,
    common: commonDe,
    errors: errorsDe,
    fields: fieldsDe,
    currencies: currenciesDe,
    languages: languagesDe,
    security_questions: securityQuestionsDe,
  },
};
