import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PromotionWrapper from '../Promotion/PromotionWrapper';
import ArticlePageDetails from './ArticlePageDetails';
import BasePage from '../Base';

class ArticlePage extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <BasePage data={data}>
        <PromotionWrapper>
          <ArticlePageDetails data={data} />
        </PromotionWrapper>
      </BasePage>
    );
  }
}

ArticlePage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default withTranslation()(ArticlePage);
