import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import BasePage from '../Base';
import store from '../../../containers/App/store';
import { clipHTMLBody } from '../../Helpers';
import { toggleModalCashier } from '../../../redux/actions/cashierModalActions';
import Image from '../../Layout/Image';
import BackgroundImage from '../../Layout/BackgroundImage';
import PageTitle from '../PageTitle';

class BankingPage extends PureComponent {
  callModal = (e, providerType) => {
    e.preventDefault();
    clipHTMLBody();
    store.dispatch(toggleModalCashier('deposit', providerType));
  };

  render() {
    const { t, data } = this.props;

    return (
      <BasePage data={data}>
        {
          data.banner
          && (
            <BackgroundImage src={data.banner.meta.download_url} elementClass="carousel m-carousel m-carousel-accounts">
              <div className="container is-fluid" />
            </BackgroundImage>
          )
        }
        <section className="section m-page-wrapper">
          <div className="container is-fluid">
            <div className="columns is-multiline is-centered">
              <div className="column is-12-mobile is-12-tablet is-9-widescreen">
                <div className="content typography">
                  <PageTitle title={data.title} show={data.meta.show_title} />
                  {/* eslint-disable-next-line react/no-danger */}
                  <p dangerouslySetInnerHTML={{ __html: data.description }} />
                  <br />
                </div>
                <div className="accordions m-accordions">
                  <div className="accordion is-disabled mb-0">
                    <h2 className="accordion-header">
                      { t('common:banking_methods') }
                    </h2>
                  </div>
                </div>
                <table className="content m-banking m-banking-page table is-fullwidth is-striped-mobile is-responsive">
                  <tbody>
                    <tr className="m-banking-table-head is-12-mobile is-6-tablet is-6-desktop is-4-widescreen">
                      <th align="center" className="m-banking-mobile-head">{ t('fields:name') }</th>
                      <th align="center">{ t('fields:min_max_deposit') }</th>
                      <th align="center">{ t('fields:processing_time') }</th>
                      <th align="center">{ t('fields:withdraw_option') }</th>
                      <th align="center"> </th>
                    </tr>
                    { data.methods.map(item => (
                      <tr key={item.name} id={item.name}>
                        <td className="card-content">
                          <div className="content has-text-centered">
                            <Image src={item.image.url} />
                          </div>
                        </td>
                        <td className="has-text-centered">
                          {item.minimum_deposit}
                          /
                          {item.maximum_deposit}
                        </td>
                        <td className="has-text-centered">
                          {item.processing_time}
                        </td>
                        <td className="has-text-centered">
                          {item.withdraw_option === true
                            ? t('account:no')
                            : t('account:yes')}
                        </td>
                        <td className="has-text-centered">
                          <a
                            href="/#"
                            id="deposit"
                            data-modal-target="modal-bambora-cashier-version-two"
                            className="button is-success is-dark-blue"
                            onClick={e => this.callModal(e, item.type)}
                          >
                            { t('buttons:deposit') }
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </BasePage>
    );
  }
}

BankingPage.propTypes = {
  data: PropTypes.shape({
    methods: PropTypes.instanceOf(Object),
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    meta: PropTypes.instanceOf(Object),
  }),
  t: PropTypes.func.isRequired,
};

BankingPage.defaultProps = {
  data: {
    meta: {
      show_title: true,
    },
  },
};

export default compose(
  withTranslation(),
)(BankingPage);
