import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { Link } from 'react-router-dom';
import BasePage from '../Base';
import PageTitle from '../PageTitle';
import { getPathname } from '../../Helpers/index';

export const fetchContent = async (url) => {
  const response = await new Casino.FetchContent(url).perform();
  return response;
};

export const fetchProviders = async () => {
  const response = await fetchContent('games/providers?status=enabled');
  return response.data.items;
};

export const fetchProviderLogos = async () => {
  const providers = await fetchProviders();
  return providers;
};

const GameProviderList = ({ data }) => {
  const [logos, setLogos] = useState([]);
  useEffect(() => {
    fetchProviderLogos().then((result) => {
      setLogos(result);
    });
  }, []);

  return (
    <div className="is-gameprovider-list">
      <BasePage data={data}>
        <div className=" main-content-parent is-game-list">
          <section className="section m-page-wrapper has-background-black">
            <div className="container is-fluid">
              <div className="columns is-centered is-mobile">
                <div className="column is-12-mobile is-12-tablet is-9-widescreen">
                  <div className="content typography is-uppercase">
                    <PageTitle title="Game Providers" show={data.meta.show_title} />
                    <div className="content typography has-link-underline" dangerouslySetInnerHTML={{ __html: data.body }} />
                    <div className="columns is-multiline is-mobile">
                      {logos.map(item => (
                        <DisplayProviderLogos provider={item} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </BasePage>
    </div>
  );
};

const DisplayProviderLogos = ({ provider }) => {
  const logoUrl = provider.logo ? provider.logo.meta.download_url : null;
  const logo = (
    <>
      <Link to={getPathname(provider.url)} className="[ m-logo-holder ] column is-half-mobile is-one-fifth-tablet">
        <div>
          <figure>
            <img src={logoUrl} alt={provider.name} />
          </figure>
        </div>
      </Link>
    </>
  );
  return (logoUrl ? logo : null);
};

GameProviderList.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      show_title: PropTypes.bool,
      show_header: PropTypes.bool,
    }),
  }),
};

GameProviderList.defaultProps = {
  data: {
    meta: {
      show_title: true,
      show_header: true,
    },
  },
};

export default GameProviderList;
