import React from 'react';
import { useTranslation } from 'react-i18next';

const RestrictedCountry = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section has-background-dark is-viewport-height is-flex restricted-country-box">
        <div className="container is-fluid">
          <div className="columns is-centered">
            <div className="column is-6-widescreen is-12 has-text-centered">
              <div className="card has-background-primary">
                <div className="card-content has-text-centered">
                  <img width="200" src="/images/logo-spinnalot.png" alt="Spinnalot" />
                  <br />
                  <br />
                  <div className="box has-text-primary">
                    <div className="media">
                      <div className="media-content has-text-centered">
                        <i className="fas fa-exclamation-circle fa-3x" />
                        <p>
                          <strong className="has-text-primary is-size-4">
                            {t('common:restricted_title')}
                          </strong>
                        </p>
                        <div className="content has-text-primary">
                          <p className="is-size-5">
                            <strong className="has-text-primary">{t('common:restricted_body_1')}</strong>
                          </p>
                          <p>
                            {t('common:restricted_body_2')}
                          </p>
                          <p>
                            {t('common:restricted_body_3')}
                            <br />
                            <a className="has-text-primary" href="mailto:support@spinnalot.com"><strong>support@spinnalot.com</strong></a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RestrictedCountry;
